'use client';

import { useEffect, useMemo, useState } from 'react';
import { DropdownItem } from '../../components/modal-country/modal-dropdown-country';
import { useModalCountryQuery } from '../../graphql/generated/graphql';
import { setCookie } from 'cookies-next';
import { getItemFromCookies } from '../../helpers/cookies-helper';

export const SiteSelector = ({
  sites,
  host,
  isOpen,
}: {
  sites: Array<DropdownItem>;
  host: string;
  isOpen?: boolean;
}) => {
  const [selectedSite, setSelectedSite] = useState<DropdownItem>();
  const [selectedCurrency, setSelectedCurrency] = useState<DropdownItem>();
  const [selectedLanguage, setSelectedLanguage] = useState<DropdownItem>();

  // Set default site on first render
  useEffect(() => {
    if (!selectedSite && sites.length > 0) {
      const defaultSite = sites.find(site => site.isDefault) || sites[0];
      setSelectedSite(defaultSite);
    }
  }, [sites, selectedSite]);

  // change this to the main domain (ParkMundo)
  const isLanguageCurrencyAllowed =
    selectedSite?.tag?.includes('vliegenenparkeren') || host === 'localhost:3000';

  const [{ data: siteData }, reexecuteQuery] = useModalCountryQuery({
    variables: { where: { domain: selectedSite?.tag ?? '' } },
    pause: true,
  });

  // We delay the query execution slightly to avoid a focus recursion error
  // that occurs in Firefox when a Radix Dialog triggers a re-render during focus.
  // See: https://github.com/radix-ui/primitives/issues/1652
  useEffect(() => {
    if (isOpen && selectedSite?.tag) {
      const timeout = setTimeout(() => {
        reexecuteQuery({ requestPolicy: 'network-only' });
      }, 50);
      return () => clearTimeout(timeout);
    }
  }, [isOpen, selectedSite?.tag, reexecuteQuery]);

  const filteredCurrencies = useMemo(() => {
    return (
      siteData?.getSiteBy?.siteCurrencies?.map(currency => ({
        label: currency.currency?.name || '',
        img: <span>{currency.currency?.symbol}</span>,
        tag: currency.currency?.iso || '',
        isDefault: currency.isDefault || false,
      })) ?? []
    );
  }, [siteData]);

  const filteredLanguages = useMemo(() => {
    return (
      siteData?.getSiteBy?.siteLanguages?.map(language => ({
        label: language.language?.name || '',
        img: <span>{language.language?.iso}</span>,
        tag: language.language?.tag || '',
        isDefault: language.isDefault || false,
      })) ?? []
    );
  }, [siteData]);

  // Auto-select default currency/language
  useEffect(() => {
    const defaultCurrency =
      filteredCurrencies.find(c => c.isDefault) || filteredCurrencies[0];

    const getLanguageCookie = getItemFromCookies('lang');
    const cookieLanguage =
      filteredLanguages.find(l => l.tag === getLanguageCookie) ?? undefined;

    const defaultLanguage =
      cookieLanguage ||
      filteredLanguages.find(l => l.isDefault) ||
      filteredLanguages[0];

    setSelectedCurrency(defaultCurrency);
    setSelectedLanguage(defaultLanguage);
  }, [selectedSite?.tag, filteredCurrencies, filteredLanguages]);

  const handleContinue = () => {
    const currencyToUse =
      selectedCurrency ??
      filteredCurrencies.find(c => c.isDefault) ??
      filteredCurrencies[0];

    const languageToUse =
      selectedLanguage ??
      filteredLanguages.find(l => l.isDefault) ??
      filteredLanguages[0];

    if (currencyToUse) {
      setCookie('currency', currencyToUse.tag, {
        path: '/',
        sameSite: 'lax',
      });
    }

    if (languageToUse) {
      setCookie('lang', languageToUse.tag, {
        path: '/',
        sameSite: 'lax',
      });
    }

    if (selectedSite?.tag && selectedSite.tag !== globalThis.location.hostname) {
      globalThis.location.href = `//${selectedSite.tag}`;
    } else {
      globalThis.location.reload();
    }
  };
  return {
    selectedSite,
    setSelectedSite,
    selectedCurrency,
    setSelectedCurrency,
    selectedLanguage,
    setSelectedLanguage,
    filteredCurrencies,
    filteredLanguages,
    isLanguageCurrencyAllowed,
    handleContinue,
  };
};
