'use client';

import React from 'react';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@ui/components';
import { Icon } from '@icons/icons';
import Image from 'next/image';

export type DropdownItem = {
  label: string;
  img: string | React.ReactNode;
  tag: string;
  isDefault: boolean;
};

type CustomDropdownProps = {
  id: string;
  title: string;
  selectedItem?: DropdownItem;
  items: Array<DropdownItem>;
  onItemSelect: (item: DropdownItem) => void;
  isDisabled?: boolean;
  openDropdown: string;
  setOpenDropdown: (id: string) => void;
};

export const ModalDropdownCountry: React.FC<CustomDropdownProps> = ({
  id,
  title,
  selectedItem,
  items,
  onItemSelect,
  isDisabled = false,
  openDropdown,
  setOpenDropdown,
}) => {
  const isOpen = openDropdown === id;

  const renderImg = (img: string | React.ReactNode, label: string) => {
    if (typeof img === 'string') {
      return <Image src={img} alt={label} width={24} height={16} />;
    }
    return img;
  };

  return (
    <DropdownMenu
      open={isOpen}
      onOpenChange={open => setOpenDropdown(open ? id : '')}
    >
      <DropdownMenuTrigger asChild disabled={isDisabled}>
        <div
          className={`relative flex items-center justify-between p-2 pr-5 rounded-full cursor-pointer w-full
            ${isDisabled ? 'bg-inputFieldDefaultBackground text-disabledText cursor-not-allowed' : 'bg-inputFieldDefaultBackground'}`}
        >
          <div className="flex items-center px-3 gap-2">
            {selectedItem?.img && (
              <span className="min-w-[32px]">
                {renderImg(selectedItem.img, selectedItem.label)}
              </span>
            )}
            <div>
              <div
                className={`text-base-desktop font-normal ${isDisabled ? 'text-disabledText' : 'text-eyebrowText'}`}
              >
                {title}
              </div>
              <div
                className={`text-lg-desktop font-normal ${isDisabled ? 'text-disabledText' : 'text-primaryText'}`}
              >
                {selectedItem?.label || 'Select an option'}
              </div>
            </div>
          </div>
          <div className="w-6">
            <Icon
              name={
                isDisabled
                  ? 'KeyboardArrowDownRoundedIcon'
                  : isOpen
                    ? 'KeyboardArrowUpRoundedIcon'
                    : 'KeyboardArrowDownRoundedIcon'
              }
            />
          </div>
        </div>
      </DropdownMenuTrigger>

      {!isDisabled && (
        <DropdownMenuContent className="w-screen lg:w-auto lg:min-w-[var(--radix-dropdown-menu-trigger-width)] bg-inputFieldDefaultBackground rounded-radius400 shadow-shadowM3Two p-5 mt-1">
          <div className="grid grid-cols-2 gap-1">
            {items.map(item => (
              <div key={item.label}>
                <DropdownMenuItem
                  className="flex items-center gap-2 hover:bg-gray-200 rounded-md cursor-pointer w-full"
                  onSelect={() => onItemSelect(item)}
                >
                  <span className="min-w-[32px]">
                    {renderImg(item.img, item.label)}
                  </span>
                  <div className="text-base-desktop font-regular">{item.label}</div>
                </DropdownMenuItem>
              </div>
            ))}
          </div>
        </DropdownMenuContent>
      )}
    </DropdownMenu>
  );
};
