'use client';
import React from 'react';
import {
  Menubar,
  MenubarMenu,
  MenubarTrigger,
  MenubarContent,
} from '@ui/components';
import { Icon, IconName } from '@icons/icons';
import { useTranslations } from 'next-intl';
import { AirportFieldsFragment } from '../../../graphql/generated/graphql';
import Image from 'next/image';
import { GroupAirportsByCountry } from '../../../helpers/group-airport-by-country/group-airport-by-country';

export type ParkingItem = {
  label: string;
  icon: IconName;
  link: string;
};

export type HelpItem = {
  label: string;
  link: string;
};

export type NavigationItem =
  | { label: 'Airports'; items: Array<AirportFieldsFragment> }
  | { label: 'Type of Parking'; items: Array<ParkingItem> }
  | { label: string; link?: string }
  | { label: 'Help'; items: Array<HelpItem> };

interface NavigationProps {
  navigationLinks: Array<NavigationItem>;
}

export const NavigationHeader: React.FC<NavigationProps> = ({ navigationLinks }) => {
  const t = useTranslations('header');
  return (
    <Menubar role="menubar" aria-label="Main Navigation">
      {navigationLinks.map(nav => (
        <MenubarMenu key={nav.label}>
          {'items' in nav ? (
            <MenubarTrigger
              role="menuitem"
              aria-haspopup="true"
              aria-expanded="false" // Dynamically update based on state
            >
              {nav.label}
            </MenubarTrigger>
          ) : (
            <a
              className="text-base text-white mx-6 hover:underline underline-offset-8"
              href={nav.link}
              role="menuitem"
            >
              {nav.label}
            </a>
          )}
          {'items' in nav && nav.items && (
            <MenubarContent role="menu" aria-label={nav.label} align="center">
              {/* Airports Section */}
              {nav.label === t('airports') && (
                <div className="flex flex-wrap gap-6 p-4 max-w-4xl" role="group">
                  {Object.entries(
                    GroupAirportsByCountry(nav.items as Array<AirportFieldsFragment>)
                  ).map(([countryId, { countryName, countryFlag, airports }]) => (
                    <div key={countryId} className="flex-1">
                      <h3
                        className="font-semibold text-md lg:text-lg flex items-center gap-2 mb-4"
                        role="presentation"
                      >
                        {countryFlag && (
                          <Image
                            src={countryFlag}
                            alt={`${countryName}`}
                            width={24}
                            height={16}
                          />
                        )}
                        {countryName}
                      </h3>
                      <ul className="list-none space-y-2" role="menu">
                        {airports.map(airport => (
                          <li
                            key={airport.id}
                            className="text-sm lg:text-base hover:bg-blue-50 px-3 py-2 rounded-xl text-black whitespace-nowrap overflow-hidden text-ellipsis"
                            role="menuitem"
                          >
                            <a
                              href={`/airport/${airport.airport?.name.replaceAll(/\s+/g, '-').toLowerCase()}`}
                              className="block truncate"
                              title={airport.airport?.name}
                            >
                              {airport.airport?.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              )}

              {/* Type of Parking Section */}
              {nav.label === t('type_of_parking') && (
                <div
                  className="flex flex-col gap-2 p-2 min-w-[20rem] max-w-full"
                  role="group"
                  aria-label="Parking Types"
                >
                  {(nav.items as Array<ParkingItem>).map(type => (
                    <div
                      key={type.label}
                      className="flex items-center w-full"
                      role="menuitem"
                    >
                      <div className="flex items-center justify-center">
                        <Icon name={type.icon} size={8} iconColor="default" />
                      </div>
                      <a
                        href={type.link}
                        className="hover:bg-blue-50 flex-grow p-3 rounded-xl text-sm lg:text-base text-black ml-2"
                      >
                        {type.label}
                      </a>
                    </div>
                  ))}
                </div>
              )}

              {/* Help Section */}
              {nav.label === t('help') && (
                <div
                  className="flex flex-col gap-2 min-w-[15rem]"
                  role="group"
                  aria-label="Help"
                >
                  {(nav.items as Array<HelpItem>).map(help => (
                    <a
                      key={help.label}
                      href={help.link}
                      className="hover:bg-blue-50 w-full p-3 rounded-xl text-sm lg:text-base text-black"
                      role="menuitem"
                    >
                      {help.label}
                    </a>
                  ))}
                </div>
              )}
            </MenubarContent>
          )}
        </MenubarMenu>
      ))}
    </Menubar>
  );
};
