import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  Decimal: { input: any; output: any };
  JSON: { input: any; output: any };
};

export type AcceptConsentPayload = {
  __typename?: 'AcceptConsentPayload';
  redirect_to: Scalars['String']['output'];
};

export type AffiliateParking = {
  __typename?: 'AffiliateParking';
  additionalInformation: Scalars['String']['output'];
  addressInstructions: Array<Scalars['String']['output']>;
  affiliateUrl: Scalars['String']['output'];
  airport?: Maybe<Airport>;
  airportId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  enabled: Scalars['Boolean']['output'];
  extraPriceInformation?: Maybe<Scalars['String']['output']>;
  iconUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  mainLocationId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parkingOptions?: Maybe<Array<AffiliateParkingAffiliateParkingOption>>;
  parkingType?: Maybe<ParkingType>;
  parkingTypeId: Scalars['String']['output'];
  photos: Array<Scalars['String']['output']>;
  pricePer8Days?: Maybe<Scalars['Decimal']['output']>;
  pricePer14Days?: Maybe<Scalars['Decimal']['output']>;
  pricePerDay?: Maybe<Scalars['Decimal']['output']>;
  procedure: Array<Scalars['String']['output']>;
  remarks: Array<Scalars['String']['output']>;
  travelDistanceToAirport: Scalars['Int']['output'];
  travelTimeToAirport: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type AffiliateParkingAffiliateParkingOption = {
  __typename?: 'AffiliateParkingAffiliateParkingOption';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  option?: Maybe<Option>;
  optionId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type AffiliateParkingAffiliateParkingOptionCreateInput = {
  optionId: Scalars['String']['input'];
};

export type AffiliateParkingAffiliateParkingOptionDeleteInput = {
  optionId: Scalars['String']['input'];
};

export type AffiliateParkingCreateInput = {
  additionalInformation: Scalars['String']['input'];
  addressInstructions: Array<Scalars['String']['input']>;
  affiliateUrl: Scalars['String']['input'];
  airportId: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  extraPriceInformation?: InputMaybe<Scalars['String']['input']>;
  iconUrl: Scalars['String']['input'];
  mainLocationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parkingOptionsCreate?: InputMaybe<
    Array<AffiliateParkingAffiliateParkingOptionCreateInput>
  >;
  parkingTypeId: Scalars['String']['input'];
  photos: Array<Scalars['String']['input']>;
  pricePer8Days?: InputMaybe<Scalars['Decimal']['input']>;
  pricePer14Days?: InputMaybe<Scalars['Decimal']['input']>;
  pricePerDay?: InputMaybe<Scalars['Decimal']['input']>;
  procedure: Array<Scalars['String']['input']>;
  remarks: Array<Scalars['String']['input']>;
  travelDistanceToAirport: Scalars['Int']['input'];
  travelTimeToAirport: Scalars['Int']['input'];
};

export type AffiliateParkingListRelationFilter = {
  every?: InputMaybe<AffiliateParkingWhereInput>;
  none?: InputMaybe<AffiliateParkingWhereInput>;
  some?: InputMaybe<AffiliateParkingWhereInput>;
};

export type AffiliateParkingOption = {
  __typename?: 'AffiliateParkingOption';
  affiliateParking?: Maybe<AffiliateParking>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  option?: Maybe<Option>;
  optionId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type AffiliateParkingOptionListRelationFilter = {
  every?: InputMaybe<AffiliateParkingOptionWhereInput>;
  none?: InputMaybe<AffiliateParkingOptionWhereInput>;
  some?: InputMaybe<AffiliateParkingOptionWhereInput>;
};

export type AffiliateParkingOptionWhereInput = {
  AND?: InputMaybe<Array<AffiliateParkingOptionWhereInput>>;
  NOT?: InputMaybe<Array<AffiliateParkingOptionWhereInput>>;
  OR?: InputMaybe<Array<AffiliateParkingOptionWhereInput>>;
  affiliateParking?: InputMaybe<AffiliateParkingWhereInput>;
  affiliateParkingId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  optionId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type AffiliateParkingOptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type AffiliateParkingOrderByInput = {
  additionalInformation?: InputMaybe<SortOrder>;
  addressInstructions?: InputMaybe<SortOrder>;
  affiliateUrl?: InputMaybe<SortOrder>;
  airportId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  enabled?: InputMaybe<SortOrder>;
  extraPriceInformation?: InputMaybe<SortOrder>;
  iconUrl?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainLocationId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parkingTypeId?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  pricePer8Days?: InputMaybe<SortOrder>;
  pricePer14Days?: InputMaybe<SortOrder>;
  pricePerDay?: InputMaybe<SortOrder>;
  procedure?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
  travelDistanceToAirport?: InputMaybe<SortOrder>;
  travelTimeToAirport?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AffiliateParkingPage = {
  __typename?: 'AffiliateParkingPage';
  active: Scalars['Boolean']['output'];
  affiliateParking?: Maybe<AffiliateParking>;
  affiliateParkingId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description1?: Maybe<Scalars['String']['output']>;
  description2?: Maybe<Scalars['String']['output']>;
  description3?: Maybe<Scalars['String']['output']>;
  description4?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  seoMetaDescription: Scalars['String']['output'];
  seoMetaTitle: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type AffiliateParkingPageCreateInput = {
  active: Scalars['Boolean']['input'];
  affiliateParkingId: Scalars['String']['input'];
  description1?: InputMaybe<Scalars['String']['input']>;
  description2?: InputMaybe<Scalars['String']['input']>;
  description3?: InputMaybe<Scalars['String']['input']>;
  description4?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  seoMetaDescription: Scalars['String']['input'];
  seoMetaTitle: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type AffiliateParkingPageListRelationFilter = {
  every?: InputMaybe<AffiliateParkingPageWhereInput>;
  none?: InputMaybe<AffiliateParkingPageWhereInput>;
  some?: InputMaybe<AffiliateParkingPageWhereInput>;
};

export type AffiliateParkingPageOrderByInput = {
  active?: InputMaybe<SortOrder>;
  affiliateParkingId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description1?: InputMaybe<SortOrder>;
  description2?: InputMaybe<SortOrder>;
  description3?: InputMaybe<SortOrder>;
  description4?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  seoMetaDescription?: InputMaybe<SortOrder>;
  seoMetaTitle?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AffiliateParkingPagePaginatedResponse = {
  __typename?: 'AffiliateParkingPagePaginatedResponse';
  items: Array<AffiliateParkingPage>;
  pageInfo: PageInfo;
};

export type AffiliateParkingPageUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  affiliateParkingId?: InputMaybe<Scalars['String']['input']>;
  description1?: InputMaybe<Scalars['String']['input']>;
  description2?: InputMaybe<Scalars['String']['input']>;
  description3?: InputMaybe<Scalars['String']['input']>;
  description4?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  seoMetaDescription?: InputMaybe<Scalars['String']['input']>;
  seoMetaTitle?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type AffiliateParkingPageWhereInput = {
  AND?: InputMaybe<Array<AffiliateParkingPageWhereInput>>;
  NOT?: InputMaybe<Array<AffiliateParkingPageWhereInput>>;
  OR?: InputMaybe<Array<AffiliateParkingPageWhereInput>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  affiliateParkingId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  description1?: InputMaybe<StringFilterInput>;
  description2?: InputMaybe<StringFilterInput>;
  description3?: InputMaybe<StringFilterInput>;
  description4?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  seoMetaDescription?: InputMaybe<StringFilterInput>;
  seoMetaTitle?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type AffiliateParkingPageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type AffiliateParkingPaginatedResponse = {
  __typename?: 'AffiliateParkingPaginatedResponse';
  items: Array<AffiliateParking>;
  pageInfo: PageInfo;
};

export type AffiliateParkingUpdateInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  addressInstructions?: InputMaybe<Array<Scalars['String']['input']>>;
  affiliateUrl?: InputMaybe<Scalars['String']['input']>;
  airportId?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  extraPriceInformation?: InputMaybe<Scalars['String']['input']>;
  iconUrl?: InputMaybe<Scalars['String']['input']>;
  mainLocationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parkingOptionsDelete?: InputMaybe<
    Array<AffiliateParkingAffiliateParkingOptionDeleteInput>
  >;
  parkingOptionsReplace?: InputMaybe<
    Array<AffiliateParkingAffiliateParkingOptionCreateInput>
  >;
  parkingOptionsUpsert?: InputMaybe<
    Array<AffiliateParkingAffiliateParkingOptionCreateInput>
  >;
  parkingTypeId?: InputMaybe<Scalars['String']['input']>;
  photos?: InputMaybe<Array<Scalars['String']['input']>>;
  pricePer8Days?: InputMaybe<Scalars['Decimal']['input']>;
  pricePer14Days?: InputMaybe<Scalars['Decimal']['input']>;
  pricePerDay?: InputMaybe<Scalars['Decimal']['input']>;
  procedure?: InputMaybe<Array<Scalars['String']['input']>>;
  remarks?: InputMaybe<Array<Scalars['String']['input']>>;
  travelDistanceToAirport?: InputMaybe<Scalars['Int']['input']>;
  travelTimeToAirport?: InputMaybe<Scalars['Int']['input']>;
};

export type AffiliateParkingWhereInput = {
  AND?: InputMaybe<Array<AffiliateParkingWhereInput>>;
  NOT?: InputMaybe<Array<AffiliateParkingWhereInput>>;
  OR?: InputMaybe<Array<AffiliateParkingWhereInput>>;
  additionalInformation?: InputMaybe<StringFilterInput>;
  addressInstructions?: InputMaybe<StringListFilterInput>;
  affiliateUrl?: InputMaybe<StringFilterInput>;
  airportId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  extraPriceInformation?: InputMaybe<StringFilterInput>;
  iconUrl?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  mainLocationId?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  parkingOptions?: InputMaybe<AffiliateParkingOptionListRelationFilter>;
  parkingTypeId?: InputMaybe<StringFilterInput>;
  photos?: InputMaybe<StringListFilterInput>;
  pricePer8Days?: InputMaybe<DecimalFilterInput>;
  pricePer14Days?: InputMaybe<DecimalFilterInput>;
  pricePerDay?: InputMaybe<DecimalFilterInput>;
  procedure?: InputMaybe<StringListFilterInput>;
  remarks?: InputMaybe<StringListFilterInput>;
  travelDistanceToAirport?: InputMaybe<IntFilterInput>;
  travelTimeToAirport?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type AffiliateParkingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Airport = {
  __typename?: 'Airport';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  iata: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  location?: Maybe<ConfigurationLocation>;
  name: Scalars['String']['output'];
  siteAirports?: Maybe<Array<AirportSiteAirport>>;
  terminals?: Maybe<Array<Terminal>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type AirportCreateInput = {
  iata: Scalars['String']['input'];
  locationIdConnect?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  siteAirportsCreate?: InputMaybe<Array<AirportSiteAirportCreateInput>>;
  terminalIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AirportListRelationFilter = {
  every?: InputMaybe<AirportWhereInput>;
  none?: InputMaybe<AirportWhereInput>;
  some?: InputMaybe<AirportWhereInput>;
};

export type AirportOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  iata?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<ConfigurationLocationOrderByInput>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AirportPage = {
  __typename?: 'AirportPage';
  active: Scalars['Boolean']['output'];
  airport?: Maybe<Airport>;
  airportId: Scalars['String']['output'];
  coverImageUrl: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description1?: Maybe<Scalars['String']['output']>;
  description2?: Maybe<Scalars['String']['output']>;
  description3?: Maybe<Scalars['String']['output']>;
  description4?: Maybe<Scalars['String']['output']>;
  description5?: Maybe<Scalars['String']['output']>;
  description6?: Maybe<Scalars['String']['output']>;
  hideFromNavigation: Scalars['Boolean']['output'];
  hideFromSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parkingType?: Maybe<ParkingType>;
  parkingTypeId?: Maybe<Scalars['String']['output']>;
  seoMetaDescription: Scalars['String']['output'];
  seoMetaTitle: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  structuredDataFaq?: Maybe<Scalars['JSON']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  terminal?: Maybe<Terminal>;
  terminalId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: AirportPageType;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type AirportPageCreateInput = {
  active: Scalars['Boolean']['input'];
  airportId: Scalars['String']['input'];
  coverImageUrl: Scalars['String']['input'];
  description1?: InputMaybe<Scalars['String']['input']>;
  description2?: InputMaybe<Scalars['String']['input']>;
  description3?: InputMaybe<Scalars['String']['input']>;
  description4?: InputMaybe<Scalars['String']['input']>;
  description5?: InputMaybe<Scalars['String']['input']>;
  description6?: InputMaybe<Scalars['String']['input']>;
  hideFromNavigation: Scalars['Boolean']['input'];
  hideFromSearch: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  parkingTypeId?: InputMaybe<Scalars['String']['input']>;
  seoMetaDescription: Scalars['String']['input'];
  seoMetaTitle: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  structuredDataFaq?: InputMaybe<Scalars['JSON']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  terminalId?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: AirportPageType;
};

export type AirportPageListRelationFilter = {
  every?: InputMaybe<AirportPageWhereInput>;
  none?: InputMaybe<AirportPageWhereInput>;
  some?: InputMaybe<AirportPageWhereInput>;
};

export type AirportPageOrderByInput = {
  active?: InputMaybe<SortOrder>;
  airportId?: InputMaybe<SortOrder>;
  coverImageUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description1?: InputMaybe<SortOrder>;
  description2?: InputMaybe<SortOrder>;
  description3?: InputMaybe<SortOrder>;
  description4?: InputMaybe<SortOrder>;
  description5?: InputMaybe<SortOrder>;
  description6?: InputMaybe<SortOrder>;
  hideFromNavigation?: InputMaybe<SortOrder>;
  hideFromSearch?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parkingTypeId?: InputMaybe<SortOrder>;
  seoMetaDescription?: InputMaybe<SortOrder>;
  seoMetaTitle?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  structuredDataFaq?: InputMaybe<SortOrder>;
  subtitle?: InputMaybe<SortOrder>;
  terminalId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AirportPagePaginatedResponse = {
  __typename?: 'AirportPagePaginatedResponse';
  items: Array<AirportPage>;
  pageInfo: PageInfo;
};

export enum AirportPageType {
  Airport = 'AIRPORT',
  ParkingType = 'PARKING_TYPE',
  Terminal = 'TERMINAL',
}

export type AirportPageUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  airportId?: InputMaybe<Scalars['String']['input']>;
  coverImageUrl?: InputMaybe<Scalars['String']['input']>;
  description1?: InputMaybe<Scalars['String']['input']>;
  description2?: InputMaybe<Scalars['String']['input']>;
  description3?: InputMaybe<Scalars['String']['input']>;
  description4?: InputMaybe<Scalars['String']['input']>;
  description5?: InputMaybe<Scalars['String']['input']>;
  description6?: InputMaybe<Scalars['String']['input']>;
  hideFromNavigation?: InputMaybe<Scalars['Boolean']['input']>;
  hideFromSearch?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parkingTypeId?: InputMaybe<Scalars['String']['input']>;
  seoMetaDescription?: InputMaybe<Scalars['String']['input']>;
  seoMetaTitle?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  structuredDataFaq?: InputMaybe<Scalars['JSON']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  terminalId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AirportPageType>;
};

export type AirportPageWhereInput = {
  AND?: InputMaybe<Array<AirportPageWhereInput>>;
  NOT?: InputMaybe<Array<AirportPageWhereInput>>;
  OR?: InputMaybe<Array<AirportPageWhereInput>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  airportId?: InputMaybe<StringFilterInput>;
  coverImageUrl?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  description1?: InputMaybe<StringFilterInput>;
  description2?: InputMaybe<StringFilterInput>;
  description3?: InputMaybe<StringFilterInput>;
  description4?: InputMaybe<StringFilterInput>;
  description5?: InputMaybe<StringFilterInput>;
  description6?: InputMaybe<StringFilterInput>;
  hideFromNavigation?: InputMaybe<Scalars['Boolean']['input']>;
  hideFromSearch?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  parkingTypeId?: InputMaybe<StringFilterInput>;
  seoMetaDescription?: InputMaybe<StringFilterInput>;
  seoMetaTitle?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  structuredDataFaq?: InputMaybe<JsonFilterInput>;
  subtitle?: InputMaybe<StringFilterInput>;
  terminalId?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<AirportPageType>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type AirportPageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type AirportPaginatedResponse = {
  __typename?: 'AirportPaginatedResponse';
  items: Array<Airport>;
  pageInfo: PageInfo;
};

export type AirportSiteAirport = {
  __typename?: 'AirportSiteAirport';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  rank?: Maybe<Scalars['Int']['output']>;
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type AirportSiteAirportCreateInput = {
  rank?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['ID']['input'];
};

export type AirportSiteAirportDeleteInput = {
  siteId: Scalars['ID']['input'];
};

export type AirportUpdateInput = {
  iata?: InputMaybe<Scalars['String']['input']>;
  locationIdConnect?: InputMaybe<Scalars['ID']['input']>;
  locationIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  siteAirportsDelete?: InputMaybe<Array<AirportSiteAirportDeleteInput>>;
  siteAirportsReplace?: InputMaybe<Array<AirportSiteAirportCreateInput>>;
  siteAirportsUpsert?: InputMaybe<Array<AirportSiteAirportCreateInput>>;
  terminalIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  terminalIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  terminalIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AirportWhereInput = {
  AND?: InputMaybe<Array<AirportWhereInput>>;
  NOT?: InputMaybe<Array<AirportWhereInput>>;
  OR?: InputMaybe<Array<AirportWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  iata?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  location?: InputMaybe<ConfigurationLocationWhereInput>;
  name?: InputMaybe<StringFilterInput>;
  siteAirports?: InputMaybe<SiteAirportListRelationFilter>;
  terminals?: InputMaybe<TerminalListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type AirportWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum AttachmentType {
  Invoice = 'INVOICE',
  Voucher = 'VOUCHER',
}

export type BlogPage = {
  __typename?: 'BlogPage';
  active: Scalars['Boolean']['output'];
  airport?: Maybe<Airport>;
  airportId: Scalars['String']['output'];
  coverImageUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description1?: Maybe<Scalars['String']['output']>;
  hideImageOnDetail: Scalars['Boolean']['output'];
  hideSearchOnDetail: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  seoMetaDescription: Scalars['String']['output'];
  seoMetaTitle: Scalars['String']['output'];
  site?: Maybe<Site>;
  siteId: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type BlogPageCreateInput = {
  active: Scalars['Boolean']['input'];
  airportId: Scalars['String']['input'];
  coverImageUrl?: InputMaybe<Scalars['String']['input']>;
  description1?: InputMaybe<Scalars['String']['input']>;
  hideImageOnDetail: Scalars['Boolean']['input'];
  hideSearchOnDetail: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  seoMetaDescription: Scalars['String']['input'];
  seoMetaTitle: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type BlogPageListRelationFilter = {
  every?: InputMaybe<BlogPageWhereInput>;
  none?: InputMaybe<BlogPageWhereInput>;
  some?: InputMaybe<BlogPageWhereInput>;
};

export type BlogPageOrderByInput = {
  active?: InputMaybe<SortOrder>;
  airportId?: InputMaybe<SortOrder>;
  coverImageUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description1?: InputMaybe<SortOrder>;
  hideImageOnDetail?: InputMaybe<SortOrder>;
  hideSearchOnDetail?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  seoMetaDescription?: InputMaybe<SortOrder>;
  seoMetaTitle?: InputMaybe<SortOrder>;
  siteId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BlogPagePaginatedResponse = {
  __typename?: 'BlogPagePaginatedResponse';
  items: Array<BlogPage>;
  pageInfo: PageInfo;
};

export type BlogPageUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  airportId?: InputMaybe<Scalars['String']['input']>;
  coverImageUrl?: InputMaybe<Scalars['String']['input']>;
  description1?: InputMaybe<Scalars['String']['input']>;
  hideImageOnDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hideSearchOnDetail?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  seoMetaDescription?: InputMaybe<Scalars['String']['input']>;
  seoMetaTitle?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type BlogPageWhereInput = {
  AND?: InputMaybe<Array<BlogPageWhereInput>>;
  NOT?: InputMaybe<Array<BlogPageWhereInput>>;
  OR?: InputMaybe<Array<BlogPageWhereInput>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  airportId?: InputMaybe<StringFilterInput>;
  coverImageUrl?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  description1?: InputMaybe<StringFilterInput>;
  hideImageOnDetail?: InputMaybe<Scalars['Boolean']['input']>;
  hideSearchOnDetail?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  seoMetaDescription?: InputMaybe<StringFilterInput>;
  seoMetaTitle?: InputMaybe<StringFilterInput>;
  siteId?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type BlogPageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Booking = {
  __typename?: 'Booking';
  company?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  customer: Scalars['JSON']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  endDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  journey: Scalars['JSON']['output'];
  languageTag?: Maybe<Scalars['String']['output']>;
  mobianId?: Maybe<Scalars['String']['output']>;
  parkingId?: Maybe<Scalars['String']['output']>;
  payedOut: Scalars['Boolean']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  siteId: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
  status: BookingStatus;
  timezone: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  voucherFileId?: Maybe<Scalars['String']['output']>;
};

export type BookingCreateInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  customer: Scalars['JSON']['input'];
  endDate: Scalars['Date']['input'];
  journey: Scalars['JSON']['input'];
  languageTag?: InputMaybe<Scalars['String']['input']>;
  mobianId?: InputMaybe<Scalars['String']['input']>;
  parkingId?: InputMaybe<Scalars['String']['input']>;
  payedOut: Scalars['Boolean']['input'];
  price: Scalars['Decimal']['input'];
  productId: Scalars['String']['input'];
  reference: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
  status: BookingStatus;
  timezone: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
  voucherFileId?: InputMaybe<Scalars['String']['input']>;
};

export type BookingListRelationFilter = {
  every?: InputMaybe<BookingWhereInput>;
  none?: InputMaybe<BookingWhereInput>;
  some?: InputMaybe<BookingWhereInput>;
};

export type BookingOrderByInput = {
  company?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  journey?: InputMaybe<SortOrder>;
  languageTag?: InputMaybe<SortOrder>;
  mobianId?: InputMaybe<SortOrder>;
  parkingId?: InputMaybe<SortOrder>;
  payedOut?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  reference?: InputMaybe<SortOrder>;
  siteId?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  voucherFileId?: InputMaybe<SortOrder>;
};

export type BookingPaginatedResponse = {
  __typename?: 'BookingPaginatedResponse';
  items: Array<Booking>;
  pageInfo: PageInfo;
};

export enum BookingStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
}

export type BookingUpdateInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<Scalars['JSON']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  journey?: InputMaybe<Scalars['JSON']['input']>;
  languageTag?: InputMaybe<Scalars['String']['input']>;
  mobianId?: InputMaybe<Scalars['String']['input']>;
  parkingId?: InputMaybe<Scalars['String']['input']>;
  payedOut?: InputMaybe<Scalars['Boolean']['input']>;
  price?: InputMaybe<Scalars['Decimal']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<BookingStatus>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  voucherFileId?: InputMaybe<Scalars['String']['input']>;
};

export type BookingWhereInput = {
  AND?: InputMaybe<Array<BookingWhereInput>>;
  NOT?: InputMaybe<Array<BookingWhereInput>>;
  OR?: InputMaybe<Array<BookingWhereInput>>;
  company?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  customer?: InputMaybe<JsonFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  endDate?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  journey?: InputMaybe<JsonFilterInput>;
  languageTag?: InputMaybe<StringFilterInput>;
  mobianId?: InputMaybe<StringFilterInput>;
  parkingId?: InputMaybe<StringFilterInput>;
  payedOut?: InputMaybe<Scalars['Boolean']['input']>;
  price?: InputMaybe<DecimalFilterInput>;
  productId?: InputMaybe<StringFilterInput>;
  reference?: InputMaybe<StringFilterInput>;
  siteId?: InputMaybe<StringFilterInput>;
  startDate?: InputMaybe<DateFilterInput>;
  status?: InputMaybe<BookingStatus>;
  timezone?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
  userId?: InputMaybe<StringFilterInput>;
  voucherFileId?: InputMaybe<StringFilterInput>;
};

export type BookingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type BooleanListFilterInput = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  has?: InputMaybe<Scalars['Boolean']['input']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BusinessEntity = {
  __typename?: 'BusinessEntity';
  admin?: Maybe<User>;
  bankAccount: Scalars['String']['output'];
  bankCountry: Scalars['String']['output'];
  businessStructure: Scalars['String']['output'];
  businessType: Scalars['String']['output'];
  cocNumber: Scalars['String']['output'];
  commission: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  currency?: Maybe<Currency>;
  currencyId: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  documents?: Maybe<Array<Document>>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  insuranceAvailable: Scalars['Boolean']['output'];
  language?: Maybe<Language>;
  languageId: Scalars['String']['output'];
  legalEntitySolo: Scalars['Boolean']['output'];
  legalEntityType: LegalEntityType;
  legalName: Scalars['String']['output'];
  location?: Maybe<Location>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  providers?: Maybe<Array<Provider>>;
  rooshAdminId?: Maybe<Scalars['String']['output']>;
  swiftBic: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  vatNumber: Scalars['String']['output'];
};

export type BusinessEntityCreateInput = {
  bankAccount: Scalars['String']['input'];
  bankCountry: Scalars['String']['input'];
  businessStructure: Scalars['String']['input'];
  businessType: Scalars['String']['input'];
  cocNumber: Scalars['String']['input'];
  commission: Scalars['Int']['input'];
  currencyId: Scalars['String']['input'];
  documentIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  email: Scalars['String']['input'];
  insuranceAvailable: Scalars['Boolean']['input'];
  languageId: Scalars['String']['input'];
  legalEntitySolo: Scalars['Boolean']['input'];
  legalEntityType: LegalEntityType;
  legalName: Scalars['String']['input'];
  locationIdConnect?: InputMaybe<Scalars['ID']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  providerIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  rooshAdminId?: InputMaybe<Scalars['String']['input']>;
  swiftBic: Scalars['String']['input'];
  vatNumber: Scalars['String']['input'];
};

export type BusinessEntityListRelationFilter = {
  every?: InputMaybe<BusinessEntityWhereInput>;
  none?: InputMaybe<BusinessEntityWhereInput>;
  some?: InputMaybe<BusinessEntityWhereInput>;
};

export type BusinessEntityOrderByInput = {
  bankAccount?: InputMaybe<SortOrder>;
  bankCountry?: InputMaybe<SortOrder>;
  businessStructure?: InputMaybe<SortOrder>;
  businessType?: InputMaybe<SortOrder>;
  cocNumber?: InputMaybe<SortOrder>;
  commission?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyId?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  insuranceAvailable?: InputMaybe<SortOrder>;
  languageId?: InputMaybe<SortOrder>;
  legalEntitySolo?: InputMaybe<SortOrder>;
  legalName?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationOrderByInput>;
  logoUrl?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  rooshAdminId?: InputMaybe<SortOrder>;
  swiftBic?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  vatNumber?: InputMaybe<SortOrder>;
};

export type BusinessEntityPaginatedResponse = {
  __typename?: 'BusinessEntityPaginatedResponse';
  items: Array<BusinessEntity>;
  pageInfo: PageInfo;
};

export type BusinessEntityUpdateInput = {
  bankAccount?: InputMaybe<Scalars['String']['input']>;
  bankCountry?: InputMaybe<Scalars['String']['input']>;
  businessStructure?: InputMaybe<Scalars['String']['input']>;
  businessType?: InputMaybe<Scalars['String']['input']>;
  cocNumber?: InputMaybe<Scalars['String']['input']>;
  commission?: InputMaybe<Scalars['Int']['input']>;
  currencyId?: InputMaybe<Scalars['String']['input']>;
  documentIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  documentIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  documentIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  insuranceAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  languageId?: InputMaybe<Scalars['String']['input']>;
  legalEntitySolo?: InputMaybe<Scalars['Boolean']['input']>;
  legalEntityType?: InputMaybe<LegalEntityType>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  locationIdConnect?: InputMaybe<Scalars['ID']['input']>;
  locationIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  providerIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  providerIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  providerIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  rooshAdminId?: InputMaybe<Scalars['String']['input']>;
  swiftBic?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};

export type BusinessEntityUser = {
  __typename?: 'BusinessEntityUser';
  businessEntity?: Maybe<BusinessEntity>;
  businessEntityId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<User>;
};

export type BusinessEntityUserListRelationFilter = {
  every?: InputMaybe<BusinessEntityUserWhereInput>;
  none?: InputMaybe<BusinessEntityUserWhereInput>;
  some?: InputMaybe<BusinessEntityUserWhereInput>;
};

export type BusinessEntityUserWhereInput = {
  AND?: InputMaybe<Array<BusinessEntityUserWhereInput>>;
  NOT?: InputMaybe<Array<BusinessEntityUserWhereInput>>;
  OR?: InputMaybe<Array<BusinessEntityUserWhereInput>>;
  businessEntityId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringFilterInput>;
};

export type BusinessEntityUserWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type BusinessEntityWhereInput = {
  AND?: InputMaybe<Array<BusinessEntityWhereInput>>;
  NOT?: InputMaybe<Array<BusinessEntityWhereInput>>;
  OR?: InputMaybe<Array<BusinessEntityWhereInput>>;
  bankAccount?: InputMaybe<StringFilterInput>;
  bankCountry?: InputMaybe<StringFilterInput>;
  businessStructure?: InputMaybe<StringFilterInput>;
  businessType?: InputMaybe<StringFilterInput>;
  cocNumber?: InputMaybe<StringFilterInput>;
  commission?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  currencyId?: InputMaybe<StringFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  documents?: InputMaybe<DocumentListRelationFilter>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  insuranceAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  languageId?: InputMaybe<StringFilterInput>;
  legalEntitySolo?: InputMaybe<Scalars['Boolean']['input']>;
  legalEntityType?: InputMaybe<LegalEntityType>;
  legalName?: InputMaybe<StringFilterInput>;
  location?: InputMaybe<LocationWhereInput>;
  logoUrl?: InputMaybe<StringFilterInput>;
  ownerId?: InputMaybe<StringFilterInput>;
  phone?: InputMaybe<StringFilterInput>;
  providers?: InputMaybe<ProviderListRelationFilter>;
  rooshAdminId?: InputMaybe<StringFilterInput>;
  swiftBic?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
  vatNumber?: InputMaybe<StringFilterInput>;
};

export type BusinessEntityWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ConfigurationLocation = {
  __typename?: 'ConfigurationLocation';
  airport?: Maybe<Airport>;
  altitude?: Maybe<Scalars['Float']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  lat: Scalars['Float']['output'];
  long: Scalars['Float']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  stateOrProvince?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  terminal?: Maybe<Terminal>;
  timeZone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ConfigurationLocationCreateInput = {
  airportIdConnect?: InputMaybe<Scalars['ID']['input']>;
  altitude?: InputMaybe<Scalars['Float']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryIdConnect?: InputMaybe<Scalars['ID']['input']>;
  lat: Scalars['Float']['input'];
  long: Scalars['Float']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  stateOrProvince?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  terminalIdConnect?: InputMaybe<Scalars['ID']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type ConfigurationLocationListRelationFilter = {
  every?: InputMaybe<ConfigurationLocationWhereInput>;
  none?: InputMaybe<ConfigurationLocationWhereInput>;
  some?: InputMaybe<ConfigurationLocationWhereInput>;
};

export type ConfigurationLocationOrderByInput = {
  airport?: InputMaybe<AirportOrderByInput>;
  airportId?: InputMaybe<SortOrder>;
  altitude?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<CountryOrderByInput>;
  countryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lat?: InputMaybe<SortOrder>;
  long?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  stateOrProvince?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  streetNumber?: InputMaybe<SortOrder>;
  terminal?: InputMaybe<TerminalOrderByInput>;
  terminalId?: InputMaybe<SortOrder>;
  timeZone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ConfigurationLocationPaginatedResponse = {
  __typename?: 'ConfigurationLocationPaginatedResponse';
  items: Array<ConfigurationLocation>;
  pageInfo: PageInfo;
};

export type ConfigurationLocationUpdateInput = {
  airportIdConnect?: InputMaybe<Scalars['ID']['input']>;
  airportIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  altitude?: InputMaybe<Scalars['Float']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryIdConnect?: InputMaybe<Scalars['ID']['input']>;
  countryIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  long?: InputMaybe<Scalars['Float']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  stateOrProvince?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  terminalIdConnect?: InputMaybe<Scalars['ID']['input']>;
  terminalIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type ConfigurationLocationWhereInput = {
  AND?: InputMaybe<Array<ConfigurationLocationWhereInput>>;
  NOT?: InputMaybe<Array<ConfigurationLocationWhereInput>>;
  OR?: InputMaybe<Array<ConfigurationLocationWhereInput>>;
  airport?: InputMaybe<AirportWhereInput>;
  airportId?: InputMaybe<StringFilterInput>;
  altitude?: InputMaybe<FloatFilterInput>;
  city?: InputMaybe<StringFilterInput>;
  country?: InputMaybe<CountryWhereInput>;
  countryId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  lat?: InputMaybe<FloatFilterInput>;
  long?: InputMaybe<FloatFilterInput>;
  postalCode?: InputMaybe<StringFilterInput>;
  stateOrProvince?: InputMaybe<StringFilterInput>;
  street?: InputMaybe<StringFilterInput>;
  streetNumber?: InputMaybe<StringFilterInput>;
  terminal?: InputMaybe<TerminalWhereInput>;
  terminalId?: InputMaybe<StringFilterInput>;
  timeZone?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ConfigurationLocationWhereUniqueInput = {
  airportId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  terminalId?: InputMaybe<Scalars['ID']['input']>;
};

export type ConsentInput = {
  consentChallenge: Scalars['String']['input'];
};

export type ContactPoint = {
  __typename?: 'ContactPoint';
  areaServed: Scalars['String']['output'];
  availableLanguage?: Maybe<Scalars['String']['output']>;
  contactType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  email: Scalars['String']['output'];
  faxNumber?: Maybe<Scalars['String']['output']>;
  hoursAvailable?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  site?: Maybe<Site>;
  telephone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ContactPointCreateInput = {
  areaServed: Scalars['String']['input'];
  availableLanguage?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  hoursAvailable?: InputMaybe<Scalars['String']['input']>;
  siteIdConnect: Scalars['ID']['input'];
  telephone?: InputMaybe<Scalars['String']['input']>;
};

export type ContactPointListRelationFilter = {
  every?: InputMaybe<ContactPointWhereInput>;
  none?: InputMaybe<ContactPointWhereInput>;
  some?: InputMaybe<ContactPointWhereInput>;
};

export type ContactPointOrderByInput = {
  areaServed?: InputMaybe<SortOrder>;
  availableLanguage?: InputMaybe<SortOrder>;
  contactType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  faxNumber?: InputMaybe<SortOrder>;
  hoursAvailable?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  site?: InputMaybe<SiteOrderByInput>;
  siteId?: InputMaybe<SortOrder>;
  telephone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ContactPointPaginatedResponse = {
  __typename?: 'ContactPointPaginatedResponse';
  items: Array<ContactPoint>;
  pageInfo: PageInfo;
};

export type ContactPointUpdateInput = {
  areaServed?: InputMaybe<Scalars['String']['input']>;
  availableLanguage?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  hoursAvailable?: InputMaybe<Scalars['String']['input']>;
  siteIdConnect?: InputMaybe<Scalars['ID']['input']>;
  telephone?: InputMaybe<Scalars['String']['input']>;
};

export type ContactPointWhereInput = {
  AND?: InputMaybe<Array<ContactPointWhereInput>>;
  NOT?: InputMaybe<Array<ContactPointWhereInput>>;
  OR?: InputMaybe<Array<ContactPointWhereInput>>;
  areaServed?: InputMaybe<StringFilterInput>;
  availableLanguage?: InputMaybe<StringFilterInput>;
  contactType?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  faxNumber?: InputMaybe<StringFilterInput>;
  hoursAvailable?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  site?: InputMaybe<SiteWhereInput>;
  siteId?: InputMaybe<StringFilterInput>;
  telephone?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ContactPointWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type Country = {
  __typename?: 'Country';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  flagIconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isInsideEu: Scalars['Boolean']['output'];
  iso: Scalars['String']['output'];
  locations?: Maybe<Array<ConfigurationLocation>>;
  name: Scalars['String']['output'];
  siteCountries?: Maybe<Array<CountrySiteCountry>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type CountryCreateInput = {
  flagIconUrl?: InputMaybe<Scalars['String']['input']>;
  isInsideEu: Scalars['Boolean']['input'];
  iso: Scalars['String']['input'];
  locationIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  name: Scalars['String']['input'];
  siteCountriesCreate?: InputMaybe<Array<CountrySiteCountryCreateInput>>;
};

export type CountryListRelationFilter = {
  every?: InputMaybe<CountryWhereInput>;
  none?: InputMaybe<CountryWhereInput>;
  some?: InputMaybe<CountryWhereInput>;
};

export type CountryOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  flagIconUrl?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isInsideEu?: InputMaybe<SortOrder>;
  iso?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CountryPaginatedResponse = {
  __typename?: 'CountryPaginatedResponse';
  items: Array<Country>;
  pageInfo: PageInfo;
};

export type CountrySiteCountry = {
  __typename?: 'CountrySiteCountry';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type CountrySiteCountryCreateInput = {
  isDefault: Scalars['Boolean']['input'];
  siteId: Scalars['ID']['input'];
};

export type CountrySiteCountryDeleteInput = {
  siteId: Scalars['ID']['input'];
};

export type CountryUpdateInput = {
  flagIconUrl?: InputMaybe<Scalars['String']['input']>;
  isInsideEu?: InputMaybe<Scalars['Boolean']['input']>;
  iso?: InputMaybe<Scalars['String']['input']>;
  locationIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  siteCountriesDelete?: InputMaybe<Array<CountrySiteCountryDeleteInput>>;
  siteCountriesReplace?: InputMaybe<Array<CountrySiteCountryCreateInput>>;
  siteCountriesUpsert?: InputMaybe<Array<CountrySiteCountryCreateInput>>;
};

export type CountryWhereInput = {
  AND?: InputMaybe<Array<CountryWhereInput>>;
  NOT?: InputMaybe<Array<CountryWhereInput>>;
  OR?: InputMaybe<Array<CountryWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  flagIconUrl?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  isInsideEu?: InputMaybe<Scalars['Boolean']['input']>;
  iso?: InputMaybe<StringFilterInput>;
  locations?: InputMaybe<ConfigurationLocationListRelationFilter>;
  name?: InputMaybe<StringFilterInput>;
  siteCountries?: InputMaybe<SiteCountryListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type CountryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  iso?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Currency = {
  __typename?: 'Currency';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  iso: Scalars['String']['output'];
  name: Scalars['String']['output'];
  siteCurrencies?: Maybe<Array<CurrencySiteCurrency>>;
  symbol: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type CurrencyCreateInput = {
  iso: Scalars['String']['input'];
  name: Scalars['String']['input'];
  siteCurrenciesCreate?: InputMaybe<Array<CurrencySiteCurrencyCreateInput>>;
  symbol: Scalars['String']['input'];
};

export type CurrencyListRelationFilter = {
  every?: InputMaybe<CurrencyWhereInput>;
  none?: InputMaybe<CurrencyWhereInput>;
  some?: InputMaybe<CurrencyWhereInput>;
};

export type CurrencyOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iso?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  symbol?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CurrencyPaginatedResponse = {
  __typename?: 'CurrencyPaginatedResponse';
  items: Array<Currency>;
  pageInfo: PageInfo;
};

export type CurrencySiteCurrency = {
  __typename?: 'CurrencySiteCurrency';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type CurrencySiteCurrencyCreateInput = {
  isDefault: Scalars['Boolean']['input'];
  siteId: Scalars['ID']['input'];
};

export type CurrencySiteCurrencyDeleteInput = {
  siteId: Scalars['ID']['input'];
};

export type CurrencyUpdateInput = {
  iso?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  siteCurrenciesDelete?: InputMaybe<Array<CurrencySiteCurrencyDeleteInput>>;
  siteCurrenciesReplace?: InputMaybe<Array<CurrencySiteCurrencyCreateInput>>;
  siteCurrenciesUpsert?: InputMaybe<Array<CurrencySiteCurrencyCreateInput>>;
  symbol?: InputMaybe<Scalars['String']['input']>;
};

export type CurrencyWhereInput = {
  AND?: InputMaybe<Array<CurrencyWhereInput>>;
  NOT?: InputMaybe<Array<CurrencyWhereInput>>;
  OR?: InputMaybe<Array<CurrencyWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  iso?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  siteCurrencies?: InputMaybe<SiteCurrencyListRelationFilter>;
  symbol?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type CurrencyWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  iso?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type DateFilterInput = {
  equals?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  not?: InputMaybe<DateFilterInput>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
};

export type DateListFilterInput = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  has?: InputMaybe<Scalars['Date']['input']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DecimalFilterInput = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<DecimalFilterInput>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
};

export type DecimalListFilterInput = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  has?: InputMaybe<Scalars['Decimal']['input']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum DirectiveRole {
  CountryManager = 'CountryManager',
  Customer = 'Customer',
  CustomerSupport = 'CustomerSupport',
  Development = 'Development',
  ExternalVendor = 'ExternalVendor',
  Hr = 'HR',
  Management = 'Management',
  Marketing = 'Marketing',
  Pms = 'PMS',
  Provider = 'Provider',
  Resellers = 'Resellers',
  Sale = 'Sale',
  Subdomains = 'Subdomains',
}

export type Document = {
  __typename?: 'Document';
  businessEntity?: Maybe<BusinessEntity>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description: Scalars['String']['output'];
  fileId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  provider?: Maybe<Provider>;
  type: DocumentType;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type DocumentCreateInput = {
  businessEntityIdConnect?: InputMaybe<Scalars['ID']['input']>;
  description: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  providerIdConnect?: InputMaybe<Scalars['ID']['input']>;
  type: DocumentType;
};

export type DocumentListRelationFilter = {
  every?: InputMaybe<DocumentWhereInput>;
  none?: InputMaybe<DocumentWhereInput>;
  some?: InputMaybe<DocumentWhereInput>;
};

export type DocumentOrderByInput = {
  businessEntity?: InputMaybe<BusinessEntityOrderByInput>;
  businessEntityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  fileId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  provider?: InputMaybe<ProviderOrderByInput>;
  providerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DocumentPaginatedResponse = {
  __typename?: 'DocumentPaginatedResponse';
  items: Array<Document>;
  pageInfo: PageInfo;
};

export enum DocumentType {
  ArticlesOfAssociation = 'ARTICLES_OF_ASSOCIATION',
  ChamberOfCommerce = 'CHAMBER_OF_COMMERCE',
  TermsAndConditions = 'TERMS_AND_CONDITIONS',
}

export type DocumentUpdateInput = {
  businessEntityIdConnect?: InputMaybe<Scalars['ID']['input']>;
  businessEntityIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileId?: InputMaybe<Scalars['String']['input']>;
  providerIdConnect?: InputMaybe<Scalars['ID']['input']>;
  providerIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<DocumentType>;
};

export type DocumentWhereInput = {
  AND?: InputMaybe<Array<DocumentWhereInput>>;
  NOT?: InputMaybe<Array<DocumentWhereInput>>;
  OR?: InputMaybe<Array<DocumentWhereInput>>;
  businessEntity?: InputMaybe<BusinessEntityWhereInput>;
  businessEntityId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  fileId?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<ProviderWhereInput>;
  providerId?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<DocumentType>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type DocumentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  allowedAttachments?: Maybe<Array<EmailTemplateAttachment>>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  parameters?: Maybe<Array<EmailTemplateParameter>>;
  reference: Scalars['String']['output'];
  senderEmail: Scalars['String']['output'];
  sentEmails?: Maybe<Array<SentEmail>>;
  subject: Scalars['String']['output'];
  type: EmailTemplateType;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type EmailTemplateAttachment = {
  __typename?: 'EmailTemplateAttachment';
  createdAt: Scalars['Date']['output'];
  cssUrl: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  htmlUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  template?: Maybe<EmailTemplate>;
  type: AttachmentType;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type EmailTemplateAttachmentCreateInput = {
  cssUrl: Scalars['String']['input'];
  htmlUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
  templateIdConnect: Scalars['ID']['input'];
  type: AttachmentType;
};

export type EmailTemplateAttachmentListRelationFilter = {
  every?: InputMaybe<EmailTemplateAttachmentWhereInput>;
  none?: InputMaybe<EmailTemplateAttachmentWhereInput>;
  some?: InputMaybe<EmailTemplateAttachmentWhereInput>;
};

export type EmailTemplateAttachmentOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  cssUrl?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  htmlUrl?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  template?: InputMaybe<EmailTemplateOrderByInput>;
  templateId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EmailTemplateAttachmentPaginatedResponse = {
  __typename?: 'EmailTemplateAttachmentPaginatedResponse';
  items: Array<EmailTemplateAttachment>;
  pageInfo: PageInfo;
};

export type EmailTemplateAttachmentUpdateInput = {
  cssUrl?: InputMaybe<Scalars['String']['input']>;
  htmlUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  templateIdConnect?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<AttachmentType>;
};

export type EmailTemplateAttachmentWhereInput = {
  AND?: InputMaybe<Array<EmailTemplateAttachmentWhereInput>>;
  NOT?: InputMaybe<Array<EmailTemplateAttachmentWhereInput>>;
  OR?: InputMaybe<Array<EmailTemplateAttachmentWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  cssUrl?: InputMaybe<StringFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  htmlUrl?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  template?: InputMaybe<EmailTemplateWhereInput>;
  templateId?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<AttachmentType>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type EmailTemplateAttachmentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type EmailTemplateCreateInput = {
  allowedAttachmentIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  parameterIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  reference: Scalars['String']['input'];
  senderEmail: Scalars['String']['input'];
  sentEmailIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  subject: Scalars['String']['input'];
  type: EmailTemplateType;
};

export type EmailTemplateListRelationFilter = {
  every?: InputMaybe<EmailTemplateWhereInput>;
  none?: InputMaybe<EmailTemplateWhereInput>;
  some?: InputMaybe<EmailTemplateWhereInput>;
};

export type EmailTemplateOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reference?: InputMaybe<SortOrder>;
  senderEmail?: InputMaybe<SortOrder>;
  subject?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EmailTemplatePaginatedResponse = {
  __typename?: 'EmailTemplatePaginatedResponse';
  items: Array<EmailTemplate>;
  pageInfo: PageInfo;
};

export type EmailTemplateParameter = {
  __typename?: 'EmailTemplateParameter';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  isStatic: Scalars['Boolean']['output'];
  parameter?: Maybe<Parameter>;
  template?: Maybe<EmailTemplate>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type EmailTemplateParameterCreateInput = {
  isRequired: Scalars['Boolean']['input'];
  isStatic: Scalars['Boolean']['input'];
  parameterIdConnect: Scalars['ID']['input'];
  templateIdConnect: Scalars['ID']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type EmailTemplateParameterListRelationFilter = {
  every?: InputMaybe<EmailTemplateParameterWhereInput>;
  none?: InputMaybe<EmailTemplateParameterWhereInput>;
  some?: InputMaybe<EmailTemplateParameterWhereInput>;
};

export type EmailTemplateParameterOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  isStatic?: InputMaybe<SortOrder>;
  parameter?: InputMaybe<ParameterOrderByInput>;
  parameterId?: InputMaybe<SortOrder>;
  template?: InputMaybe<EmailTemplateOrderByInput>;
  templateId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type EmailTemplateParameterPaginatedResponse = {
  __typename?: 'EmailTemplateParameterPaginatedResponse';
  items: Array<EmailTemplateParameter>;
  pageInfo: PageInfo;
};

export type EmailTemplateParameterUpdateInput = {
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  isStatic?: InputMaybe<Scalars['Boolean']['input']>;
  parameterIdConnect?: InputMaybe<Scalars['ID']['input']>;
  templateIdConnect?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type EmailTemplateParameterWhereInput = {
  AND?: InputMaybe<Array<EmailTemplateParameterWhereInput>>;
  NOT?: InputMaybe<Array<EmailTemplateParameterWhereInput>>;
  OR?: InputMaybe<Array<EmailTemplateParameterWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  isStatic?: InputMaybe<Scalars['Boolean']['input']>;
  parameter?: InputMaybe<ParameterWhereInput>;
  parameterId?: InputMaybe<StringFilterInput>;
  template?: InputMaybe<EmailTemplateWhereInput>;
  templateId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
  value?: InputMaybe<StringFilterInput>;
};

export type EmailTemplateParameterWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum EmailTemplateType {
  AmendmentPayment = 'AMENDMENT_PAYMENT',
  Cancellation = 'CANCELLATION',
  Confirmation = 'CONFIRMATION',
  GenerAl = 'GENERAl',
  MobianOrderCancellation = 'MOBIAN_ORDER_CANCELLATION',
  ProviderInvoice = 'PROVIDER_INVOICE',
  ReviewPlatformInvite = 'REVIEW_PLATFORM_INVITE',
  ReviewProviderInvite = 'REVIEW_PROVIDER_INVITE',
  ReviewRooshInvite = 'REVIEW_ROOSH_INVITE',
  SetPassword = 'SET_PASSWORD',
  WebhookNotification = 'WEBHOOK_NOTIFICATION',
}

export type EmailTemplateUpdateInput = {
  allowedAttachmentIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  allowedAttachmentIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  allowedAttachmentIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  parameterIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  parameterIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  parameterIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  reference?: InputMaybe<Scalars['String']['input']>;
  senderEmail?: InputMaybe<Scalars['String']['input']>;
  sentEmailIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  sentEmailIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  sentEmailIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  subject?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EmailTemplateType>;
};

export type EmailTemplateWhereInput = {
  AND?: InputMaybe<Array<EmailTemplateWhereInput>>;
  NOT?: InputMaybe<Array<EmailTemplateWhereInput>>;
  OR?: InputMaybe<Array<EmailTemplateWhereInput>>;
  allowedAttachments?: InputMaybe<EmailTemplateAttachmentListRelationFilter>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  parameters?: InputMaybe<EmailTemplateParameterListRelationFilter>;
  reference?: InputMaybe<StringFilterInput>;
  senderEmail?: InputMaybe<StringFilterInput>;
  sentEmails?: InputMaybe<SentEmailListRelationFilter>;
  subject?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<EmailTemplateType>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type EmailTemplateWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ExchangeTokenInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type ExchangeTokenPayload = {
  __typename?: 'ExchangeTokenPayload';
  accessToken?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  user: SafeUser;
};

export type FaqPage = {
  __typename?: 'FaqPage';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description1: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  questionGroups?: Maybe<Array<FaqQuestionGroup>>;
  seoMetaDescription: Scalars['String']['output'];
  seoMetaTitle: Scalars['String']['output'];
  site?: Maybe<Site>;
  siteId: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type FaqPageCreateInput = {
  description1: Scalars['String']['input'];
  name: Scalars['String']['input'];
  questionGroupIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  seoMetaDescription: Scalars['String']['input'];
  seoMetaTitle: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type FaqPageListRelationFilter = {
  every?: InputMaybe<FaqPageWhereInput>;
  none?: InputMaybe<FaqPageWhereInput>;
  some?: InputMaybe<FaqPageWhereInput>;
};

export type FaqPageOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description1?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  seoMetaDescription?: InputMaybe<SortOrder>;
  seoMetaTitle?: InputMaybe<SortOrder>;
  siteId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FaqPagePaginatedResponse = {
  __typename?: 'FaqPagePaginatedResponse';
  items: Array<FaqPage>;
  pageInfo: PageInfo;
};

export type FaqPageUpdateInput = {
  description1?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  questionGroupIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionGroupIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionGroupIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  seoMetaDescription?: InputMaybe<Scalars['String']['input']>;
  seoMetaTitle?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type FaqPageWhereInput = {
  AND?: InputMaybe<Array<FaqPageWhereInput>>;
  NOT?: InputMaybe<Array<FaqPageWhereInput>>;
  OR?: InputMaybe<Array<FaqPageWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  description1?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  questionGroups?: InputMaybe<FaqQuestionGroupListRelationFilter>;
  seoMetaDescription?: InputMaybe<StringFilterInput>;
  seoMetaTitle?: InputMaybe<StringFilterInput>;
  siteId?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type FaqPageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
};

export type FaqQuestion = {
  __typename?: 'FaqQuestion';
  answer: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  group?: Maybe<FaqQuestionGroup>;
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type FaqQuestionCreateInput = {
  answer: Scalars['String']['input'];
  groupIdConnect?: InputMaybe<Scalars['ID']['input']>;
  question: Scalars['String']['input'];
};

export type FaqQuestionGroup = {
  __typename?: 'FaqQuestionGroup';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  faqPages?: Maybe<Array<FaqPage>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  questions?: Maybe<Array<FaqQuestion>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type FaqQuestionGroupCreateInput = {
  faqPageIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  name: Scalars['String']['input'];
  questionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type FaqQuestionGroupListRelationFilter = {
  every?: InputMaybe<FaqQuestionGroupWhereInput>;
  none?: InputMaybe<FaqQuestionGroupWhereInput>;
  some?: InputMaybe<FaqQuestionGroupWhereInput>;
};

export type FaqQuestionGroupOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FaqQuestionGroupPaginatedResponse = {
  __typename?: 'FaqQuestionGroupPaginatedResponse';
  items: Array<FaqQuestionGroup>;
  pageInfo: PageInfo;
};

export type FaqQuestionGroupUpdateInput = {
  faqPageIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  faqPageIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  faqPageIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  questionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type FaqQuestionGroupWhereInput = {
  AND?: InputMaybe<Array<FaqQuestionGroupWhereInput>>;
  NOT?: InputMaybe<Array<FaqQuestionGroupWhereInput>>;
  OR?: InputMaybe<Array<FaqQuestionGroupWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  faqPages?: InputMaybe<FaqPageListRelationFilter>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  questions?: InputMaybe<FaqQuestionListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type FaqQuestionGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type FaqQuestionListRelationFilter = {
  every?: InputMaybe<FaqQuestionWhereInput>;
  none?: InputMaybe<FaqQuestionWhereInput>;
  some?: InputMaybe<FaqQuestionWhereInput>;
};

export type FaqQuestionOrderByInput = {
  answer?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  group?: InputMaybe<FaqQuestionGroupOrderByInput>;
  groupId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FaqQuestionPaginatedResponse = {
  __typename?: 'FaqQuestionPaginatedResponse';
  items: Array<FaqQuestion>;
  pageInfo: PageInfo;
};

export type FaqQuestionUpdateInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  groupIdConnect?: InputMaybe<Scalars['ID']['input']>;
  groupIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
};

export type FaqQuestionWhereInput = {
  AND?: InputMaybe<Array<FaqQuestionWhereInput>>;
  NOT?: InputMaybe<Array<FaqQuestionWhereInput>>;
  OR?: InputMaybe<Array<FaqQuestionWhereInput>>;
  answer?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  group?: InputMaybe<FaqQuestionGroupWhereInput>;
  groupId?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  question?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type FaqQuestionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type FetchConsentPayload = {
  __typename?: 'FetchConsentPayload';
  requestedScopes: Array<Scalars['String']['output']>;
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPrivate: Scalars['Boolean']['output'];
  mimeType: Scalars['String']['output'];
  objectType?: Maybe<FileObjectType>;
  sentEmail?: Maybe<SentEmail>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  url: Scalars['String']['output'];
};

export type FileCreateInput = {
  fileName: Scalars['String']['input'];
  isPrivate: Scalars['Boolean']['input'];
  mimeType: Scalars['String']['input'];
  objectTypeIdConnect: Scalars['ID']['input'];
  sentEmailIdConnect?: InputMaybe<Scalars['ID']['input']>;
  url: Scalars['String']['input'];
};

export type FileListRelationFilter = {
  every?: InputMaybe<FileWhereInput>;
  none?: InputMaybe<FileWhereInput>;
  some?: InputMaybe<FileWhereInput>;
};

export type FileObjectType = {
  __typename?: 'FileObjectType';
  allowedMimes: Array<Scalars['String']['output']>;
  basePath: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  files?: Maybe<Array<File>>;
  id: Scalars['ID']['output'];
  isPrivate: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type FileObjectTypeCreateInput = {
  allowedMimes: Array<Scalars['String']['input']>;
  basePath: Scalars['String']['input'];
  fileIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  isPrivate: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type FileObjectTypeListRelationFilter = {
  every?: InputMaybe<FileObjectTypeWhereInput>;
  none?: InputMaybe<FileObjectTypeWhereInput>;
  some?: InputMaybe<FileObjectTypeWhereInput>;
};

export type FileObjectTypeOrderByInput = {
  allowedMimes?: InputMaybe<SortOrder>;
  basePath?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPrivate?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FileObjectTypePaginatedResponse = {
  __typename?: 'FileObjectTypePaginatedResponse';
  items: Array<FileObjectType>;
  pageInfo: PageInfo;
};

export type FileObjectTypeUpdateInput = {
  allowedMimes?: InputMaybe<Array<Scalars['String']['input']>>;
  basePath?: InputMaybe<Scalars['String']['input']>;
  fileIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  fileIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  fileIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FileObjectTypeWhereInput = {
  AND?: InputMaybe<Array<FileObjectTypeWhereInput>>;
  NOT?: InputMaybe<Array<FileObjectTypeWhereInput>>;
  OR?: InputMaybe<Array<FileObjectTypeWhereInput>>;
  allowedMimes?: InputMaybe<StringListFilterInput>;
  basePath?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  files?: InputMaybe<FileListRelationFilter>;
  id?: InputMaybe<StringFilterInput>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type FileObjectTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FileOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  fileName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPrivate?: InputMaybe<SortOrder>;
  mimeType?: InputMaybe<SortOrder>;
  objectType?: InputMaybe<FileObjectTypeOrderByInput>;
  objectTypeId?: InputMaybe<SortOrder>;
  sentEmail?: InputMaybe<SentEmailOrderByInput>;
  sentEmailId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type FilePaginatedResponse = {
  __typename?: 'FilePaginatedResponse';
  items: Array<File>;
  pageInfo: PageInfo;
};

export type FileUpdateInput = {
  fileName?: InputMaybe<Scalars['String']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  objectTypeIdConnect?: InputMaybe<Scalars['ID']['input']>;
  sentEmailIdConnect?: InputMaybe<Scalars['ID']['input']>;
  sentEmailIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileWhereInput = {
  AND?: InputMaybe<Array<FileWhereInput>>;
  NOT?: InputMaybe<Array<FileWhereInput>>;
  OR?: InputMaybe<Array<FileWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  fileName?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType?: InputMaybe<StringFilterInput>;
  objectType?: InputMaybe<FileObjectTypeWhereInput>;
  objectTypeId?: InputMaybe<StringFilterInput>;
  sentEmail?: InputMaybe<SentEmailWhereInput>;
  sentEmailId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
  url?: InputMaybe<StringFilterInput>;
};

export type FileWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type FloatFilterInput = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilterInput>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type FloatListFilterInput = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  has?: InputMaybe<Scalars['Float']['input']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GeneralPage = {
  __typename?: 'GeneralPage';
  active: Scalars['Boolean']['output'];
  codeName: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description1?: Maybe<Scalars['String']['output']>;
  description2?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  seoMetaDescription: Scalars['String']['output'];
  seoMetaTitle: Scalars['String']['output'];
  site?: Maybe<Site>;
  siteId: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type GeneralPageCreateInput = {
  active: Scalars['Boolean']['input'];
  codeName: Scalars['String']['input'];
  description1?: InputMaybe<Scalars['String']['input']>;
  description2?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  seoMetaDescription: Scalars['String']['input'];
  seoMetaTitle: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type GeneralPageListRelationFilter = {
  every?: InputMaybe<GeneralPageWhereInput>;
  none?: InputMaybe<GeneralPageWhereInput>;
  some?: InputMaybe<GeneralPageWhereInput>;
};

export type GeneralPageOrderByInput = {
  active?: InputMaybe<SortOrder>;
  codeName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description1?: InputMaybe<SortOrder>;
  description2?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  seoMetaDescription?: InputMaybe<SortOrder>;
  seoMetaTitle?: InputMaybe<SortOrder>;
  siteId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GeneralPagePaginatedResponse = {
  __typename?: 'GeneralPagePaginatedResponse';
  items: Array<GeneralPage>;
  pageInfo: PageInfo;
};

export type GeneralPageUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  codeName?: InputMaybe<Scalars['String']['input']>;
  description1?: InputMaybe<Scalars['String']['input']>;
  description2?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  seoMetaDescription?: InputMaybe<Scalars['String']['input']>;
  seoMetaTitle?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type GeneralPageWhereInput = {
  AND?: InputMaybe<Array<GeneralPageWhereInput>>;
  NOT?: InputMaybe<Array<GeneralPageWhereInput>>;
  OR?: InputMaybe<Array<GeneralPageWhereInput>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  codeName?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  description1?: InputMaybe<StringFilterInput>;
  description2?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  seoMetaDescription?: InputMaybe<StringFilterInput>;
  seoMetaTitle?: InputMaybe<StringFilterInput>;
  siteId?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type GeneralPageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type HipayOnboardingNotification = {
  __typename?: 'HipayOnboardingNotification';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  headers: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  notification: Scalars['String']['output'];
  provider?: Maybe<Provider>;
  receivedAt: Scalars['Date']['output'];
  rowId: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type HipayOnboardingNotificationCreateInput = {
  headers: Scalars['String']['input'];
  notification: Scalars['String']['input'];
  providerIdConnect: Scalars['ID']['input'];
  receivedAt: Scalars['Date']['input'];
  rowId: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type HipayOnboardingNotificationListRelationFilter = {
  every?: InputMaybe<HipayOnboardingNotificationWhereInput>;
  none?: InputMaybe<HipayOnboardingNotificationWhereInput>;
  some?: InputMaybe<HipayOnboardingNotificationWhereInput>;
};

export type HipayOnboardingNotificationOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  headers?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  notification?: InputMaybe<SortOrder>;
  provider?: InputMaybe<ProviderOrderByInput>;
  providerId?: InputMaybe<SortOrder>;
  receivedAt?: InputMaybe<SortOrder>;
  rowId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type HipayOnboardingNotificationPaginatedResponse = {
  __typename?: 'HipayOnboardingNotificationPaginatedResponse';
  items: Array<HipayOnboardingNotification>;
  pageInfo: PageInfo;
};

export type HipayOnboardingNotificationUpdateInput = {
  headers?: InputMaybe<Scalars['String']['input']>;
  notification?: InputMaybe<Scalars['String']['input']>;
  providerIdConnect?: InputMaybe<Scalars['ID']['input']>;
  receivedAt?: InputMaybe<Scalars['Date']['input']>;
  rowId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type HipayOnboardingNotificationWhereInput = {
  AND?: InputMaybe<Array<HipayOnboardingNotificationWhereInput>>;
  NOT?: InputMaybe<Array<HipayOnboardingNotificationWhereInput>>;
  OR?: InputMaybe<Array<HipayOnboardingNotificationWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  headers?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  notification?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<ProviderWhereInput>;
  providerId?: InputMaybe<StringFilterInput>;
  receivedAt?: InputMaybe<DateFilterInput>;
  rowId?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type HipayOnboardingNotificationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type IntFilterInput = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilterInput>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type IntListFilterInput = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  has?: InputMaybe<Scalars['Int']['input']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  commissionAmount: Scalars['Decimal']['output'];
  commissionPercentage: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  fileId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoiceNumber: Scalars['String']['output'];
  mode: Scalars['String']['output'];
  provider?: Maybe<Provider>;
  receiverEmail: Scalars['String']['output'];
  totalAmount: Scalars['Decimal']['output'];
  transferAmount: Scalars['Decimal']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type InvoiceCreateInput = {
  commissionAmount: Scalars['Decimal']['input'];
  commissionPercentage: Scalars['Int']['input'];
  fileId: Scalars['String']['input'];
  invoiceNumber: Scalars['String']['input'];
  mode: Scalars['String']['input'];
  providerIdConnect?: InputMaybe<Scalars['ID']['input']>;
  receiverEmail: Scalars['String']['input'];
  totalAmount: Scalars['Decimal']['input'];
  transferAmount: Scalars['Decimal']['input'];
};

export type InvoiceListRelationFilter = {
  every?: InputMaybe<InvoiceWhereInput>;
  none?: InputMaybe<InvoiceWhereInput>;
  some?: InputMaybe<InvoiceWhereInput>;
};

export type InvoiceOrderByInput = {
  commissionAmount?: InputMaybe<SortOrder>;
  commissionPercentage?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  fileId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoiceNumber?: InputMaybe<SortOrder>;
  mode?: InputMaybe<SortOrder>;
  provider?: InputMaybe<ProviderOrderByInput>;
  providerId?: InputMaybe<SortOrder>;
  receiverEmail?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
  transferAmount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type InvoicePaginatedResponse = {
  __typename?: 'InvoicePaginatedResponse';
  items: Array<Invoice>;
  pageInfo: PageInfo;
};

export type InvoiceUpdateInput = {
  commissionAmount?: InputMaybe<Scalars['Decimal']['input']>;
  commissionPercentage?: InputMaybe<Scalars['Int']['input']>;
  fileId?: InputMaybe<Scalars['String']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  providerIdConnect?: InputMaybe<Scalars['ID']['input']>;
  providerIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  receiverEmail?: InputMaybe<Scalars['String']['input']>;
  totalAmount?: InputMaybe<Scalars['Decimal']['input']>;
  transferAmount?: InputMaybe<Scalars['Decimal']['input']>;
};

export type InvoiceWhereInput = {
  AND?: InputMaybe<Array<InvoiceWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceWhereInput>>;
  OR?: InputMaybe<Array<InvoiceWhereInput>>;
  commissionAmount?: InputMaybe<DecimalFilterInput>;
  commissionPercentage?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  fileId?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  invoiceNumber?: InputMaybe<StringFilterInput>;
  mode?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<ProviderWhereInput>;
  providerId?: InputMaybe<StringFilterInput>;
  receiverEmail?: InputMaybe<StringFilterInput>;
  totalAmount?: InputMaybe<DecimalFilterInput>;
  transferAmount?: InputMaybe<DecimalFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type InvoiceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type JsonFilterInput = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type Language = {
  __typename?: 'Language';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  flagIconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  iso: Scalars['String']['output'];
  name: Scalars['String']['output'];
  siteLanguages?: Maybe<Array<LanguageSiteLanguage>>;
  tag: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type LanguageCreateInput = {
  flagIconUrl?: InputMaybe<Scalars['String']['input']>;
  iso: Scalars['String']['input'];
  name: Scalars['String']['input'];
  siteLanguagesCreate?: InputMaybe<Array<LanguageSiteLanguageCreateInput>>;
  tag: Scalars['String']['input'];
};

export type LanguageListRelationFilter = {
  every?: InputMaybe<LanguageWhereInput>;
  none?: InputMaybe<LanguageWhereInput>;
  some?: InputMaybe<LanguageWhereInput>;
};

export type LanguageOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  flagIconUrl?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iso?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  tag?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LanguagePaginatedResponse = {
  __typename?: 'LanguagePaginatedResponse';
  items: Array<Language>;
  pageInfo: PageInfo;
};

export type LanguageSiteLanguage = {
  __typename?: 'LanguageSiteLanguage';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type LanguageSiteLanguageCreateInput = {
  isDefault: Scalars['Boolean']['input'];
  siteId: Scalars['ID']['input'];
};

export type LanguageSiteLanguageDeleteInput = {
  siteId: Scalars['ID']['input'];
};

export type LanguageUpdateInput = {
  flagIconUrl?: InputMaybe<Scalars['String']['input']>;
  iso?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  siteLanguagesDelete?: InputMaybe<Array<LanguageSiteLanguageDeleteInput>>;
  siteLanguagesReplace?: InputMaybe<Array<LanguageSiteLanguageCreateInput>>;
  siteLanguagesUpsert?: InputMaybe<Array<LanguageSiteLanguageCreateInput>>;
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type LanguageWhereInput = {
  AND?: InputMaybe<Array<LanguageWhereInput>>;
  NOT?: InputMaybe<Array<LanguageWhereInput>>;
  OR?: InputMaybe<Array<LanguageWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  flagIconUrl?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  iso?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  siteLanguages?: InputMaybe<SiteLanguageListRelationFilter>;
  tag?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type LanguageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
};

export enum LegalEntityType {
  CorporationsPublicLimited = 'CORPORATIONS_PUBLIC_LIMITED',
  LimitedLiability = 'LIMITED_LIABILITY',
  Partnerships = 'PARTNERSHIPS',
  SoleProprietorshipsSelfEmployed = 'SOLE_PROPRIETORSHIPS_SELF_EMPLOYED',
}

export type Location = {
  __typename?: 'Location';
  altitude?: Maybe<Scalars['Float']['output']>;
  businessEntity?: Maybe<BusinessEntity>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  lat: Scalars['Float']['output'];
  long: Scalars['Float']['output'];
  parking?: Maybe<Parking>;
  postalCode?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Provider>;
  stateOrProvince?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type LocationCreateInput = {
  altitude?: InputMaybe<Scalars['Float']['input']>;
  businessEntityIdConnect?: InputMaybe<Scalars['ID']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  lat: Scalars['Float']['input'];
  long: Scalars['Float']['input'];
  parkingIdConnect?: InputMaybe<Scalars['ID']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  providerIdConnect?: InputMaybe<Scalars['ID']['input']>;
  stateOrProvince?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type LocationListRelationFilter = {
  every?: InputMaybe<LocationWhereInput>;
  none?: InputMaybe<LocationWhereInput>;
  some?: InputMaybe<LocationWhereInput>;
};

export type LocationOrderByInput = {
  altitude?: InputMaybe<SortOrder>;
  businessEntity?: InputMaybe<BusinessEntityOrderByInput>;
  businessEntityId?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  countryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lat?: InputMaybe<SortOrder>;
  long?: InputMaybe<SortOrder>;
  parking?: InputMaybe<ParkingOrderByInput>;
  parkingId?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  provider?: InputMaybe<ProviderOrderByInput>;
  providerId?: InputMaybe<SortOrder>;
  stateOrProvince?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  streetNumber?: InputMaybe<SortOrder>;
  timeZone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LocationPaginatedResponse = {
  __typename?: 'LocationPaginatedResponse';
  items: Array<Location>;
  pageInfo: PageInfo;
};

export type LocationUpdateInput = {
  altitude?: InputMaybe<Scalars['Float']['input']>;
  businessEntityIdConnect?: InputMaybe<Scalars['ID']['input']>;
  businessEntityIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  long?: InputMaybe<Scalars['Float']['input']>;
  parkingIdConnect?: InputMaybe<Scalars['ID']['input']>;
  parkingIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  providerIdConnect?: InputMaybe<Scalars['ID']['input']>;
  providerIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  stateOrProvince?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type LocationWhereInput = {
  AND?: InputMaybe<Array<LocationWhereInput>>;
  NOT?: InputMaybe<Array<LocationWhereInput>>;
  OR?: InputMaybe<Array<LocationWhereInput>>;
  altitude?: InputMaybe<FloatFilterInput>;
  businessEntity?: InputMaybe<BusinessEntityWhereInput>;
  businessEntityId?: InputMaybe<StringFilterInput>;
  city?: InputMaybe<StringFilterInput>;
  countryId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  lat?: InputMaybe<FloatFilterInput>;
  long?: InputMaybe<FloatFilterInput>;
  parking?: InputMaybe<ParkingWhereInput>;
  parkingId?: InputMaybe<StringFilterInput>;
  postalCode?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<ProviderWhereInput>;
  providerId?: InputMaybe<StringFilterInput>;
  stateOrProvince?: InputMaybe<StringFilterInput>;
  street?: InputMaybe<StringFilterInput>;
  streetNumber?: InputMaybe<StringFilterInput>;
  timeZone?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type LocationWhereUniqueInput = {
  businessEntityId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  parkingId?: InputMaybe<Scalars['ID']['input']>;
  providerId?: InputMaybe<Scalars['ID']['input']>;
};

export type MobianPayment = {
  __typename?: 'MobianPayment';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  transactionId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type MobianPaymentCreateInput = {
  transactionId: Scalars['String']['input'];
};

export type MobianPaymentListRelationFilter = {
  every?: InputMaybe<MobianPaymentWhereInput>;
  none?: InputMaybe<MobianPaymentWhereInput>;
  some?: InputMaybe<MobianPaymentWhereInput>;
};

export type MobianPaymentOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MobianPaymentPaginatedResponse = {
  __typename?: 'MobianPaymentPaginatedResponse';
  items: Array<MobianPayment>;
  pageInfo: PageInfo;
};

export type MobianPaymentUpdateInput = {
  transactionId?: InputMaybe<Scalars['String']['input']>;
};

export type MobianPaymentWhereInput = {
  AND?: InputMaybe<Array<MobianPaymentWhereInput>>;
  NOT?: InputMaybe<Array<MobianPaymentWhereInput>>;
  OR?: InputMaybe<Array<MobianPaymentWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  transactionId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type MobianPaymentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptConsent: AcceptConsentPayload;
  createAffiliateParking: AffiliateParking;
  createAffiliateParkingPage: AffiliateParkingPage;
  createAirport: Airport;
  createAirportPage: AirportPage;
  createBlogPage: BlogPage;
  createBooking: Booking;
  createBusinessEntity: BusinessEntity;
  createConfigurationLocation: ConfigurationLocation;
  createContactPoint: ContactPoint;
  createCountry: Country;
  createCurrency: Currency;
  createDocument: Document;
  createEmailTemplate: EmailTemplate;
  createEmailTemplateAttachment: EmailTemplateAttachment;
  createEmailTemplateParameter: EmailTemplateParameter;
  createFaqPage: FaqPage;
  createFaqQuestion: FaqQuestion;
  createFaqQuestionGroup: FaqQuestionGroup;
  createFile: File;
  createFileObjectType: FileObjectType;
  createGeneralPage: GeneralPage;
  createHipayOnboardingNotification: HipayOnboardingNotification;
  createInvoice: Invoice;
  createLanguage: Language;
  createLocation: Location;
  createMobianPayment: MobianPayment;
  createOption: Option;
  createOptionCategory: OptionCategory;
  createParameter: Parameter;
  createParking: Parking;
  createParkingPage: ParkingPage;
  createParkingType: ParkingType;
  createParkingTypePage: ParkingTypePage;
  createPaymentMethod: PaymentMethod;
  createPmsProvider: PmsProvider;
  createPreUploadUrl: PreUploadUrlResponse;
  createPreUploadUrls: Array<PreUploadUrlResponse>;
  createPriceList: PriceList;
  createPriceStrategy: PriceStrategy;
  createProduct: Product;
  createProvider: Provider;
  createRedirect: Redirect;
  createRole: Role;
  createSafeAndSecureLogo: SafeAndSecureLogo;
  createSection: Section;
  createSectionType: SectionType;
  createSentEmail: SentEmail;
  createService: Service;
  createSite: Site;
  createSocialMedia: SocialMedia;
  createSubdomain: Subdomain;
  createTerminal: Terminal;
  createTestLog: TestLog;
  createTranslation: Translation;
  createTrustMarkLogo: TrustMarkLogo;
  createUser: User;
  exchangeToken: ExchangeTokenPayload;
  fetchConsent: FetchConsentPayload;
  hardDeleteAffiliateParking: AffiliateParking;
  hardDeleteAffiliateParkingPage: AffiliateParkingPage;
  hardDeleteAirport: Airport;
  hardDeleteAirportPage: AirportPage;
  hardDeleteBlogPage: BlogPage;
  hardDeleteBooking: Booking;
  hardDeleteBusinessEntity: BusinessEntity;
  hardDeleteConfigurationLocation: ConfigurationLocation;
  hardDeleteContactPoint: ContactPoint;
  hardDeleteCountry: Country;
  hardDeleteCurrency: Currency;
  hardDeleteDocument: Document;
  hardDeleteEmailTemplate: EmailTemplate;
  hardDeleteEmailTemplateAttachment: EmailTemplateAttachment;
  hardDeleteEmailTemplateParameter: EmailTemplateParameter;
  hardDeleteFaqPage: FaqPage;
  hardDeleteFaqQuestion: FaqQuestion;
  hardDeleteFaqQuestionGroup: FaqQuestionGroup;
  hardDeleteFile: File;
  hardDeleteFileObjectType: FileObjectType;
  hardDeleteGeneralPage: GeneralPage;
  hardDeleteHipayOnboardingNotification: HipayOnboardingNotification;
  hardDeleteInvoice: Invoice;
  hardDeleteLanguage: Language;
  hardDeleteLocation: Location;
  hardDeleteMobianPayment: MobianPayment;
  hardDeleteOption: Option;
  hardDeleteOptionCategory: OptionCategory;
  hardDeleteParameter: Parameter;
  hardDeleteParking: Parking;
  hardDeleteParkingPage: ParkingPage;
  hardDeleteParkingType: ParkingType;
  hardDeleteParkingTypePage: ParkingTypePage;
  hardDeletePaymentMethod: PaymentMethod;
  hardDeletePmsProvider: PmsProvider;
  hardDeletePriceList: PriceList;
  hardDeletePriceStrategy: PriceStrategy;
  hardDeleteProduct: Product;
  hardDeleteProvider: Provider;
  hardDeleteRedirect: Redirect;
  hardDeleteRole: Role;
  hardDeleteSafeAndSecureLogo: SafeAndSecureLogo;
  hardDeleteSection: Section;
  hardDeleteSectionType: SectionType;
  hardDeleteSentEmail: SentEmail;
  hardDeleteService: Service;
  hardDeleteSite: Site;
  hardDeleteSocialMedia: SocialMedia;
  hardDeleteSubdomain: Subdomain;
  hardDeleteTerminal: Terminal;
  hardDeleteTestLog: TestLog;
  hardDeleteTranslation: Translation;
  hardDeleteTrustMarkLogo: TrustMarkLogo;
  hardDeleteUser: User;
  refreshToken: RefreshTokenPayload;
  rejectConsent: RejectConsentPayload;
  restoreAffiliateParking: AffiliateParking;
  restoreAffiliateParkingPage: AffiliateParkingPage;
  restoreAirport: Airport;
  restoreAirportPage: AirportPage;
  restoreBlogPage: BlogPage;
  restoreBooking: Booking;
  restoreBusinessEntity: BusinessEntity;
  restoreConfigurationLocation: ConfigurationLocation;
  restoreContactPoint: ContactPoint;
  restoreCountry: Country;
  restoreCurrency: Currency;
  restoreDocument: Document;
  restoreEmailTemplate: EmailTemplate;
  restoreEmailTemplateAttachment: EmailTemplateAttachment;
  restoreEmailTemplateParameter: EmailTemplateParameter;
  restoreFaqPage: FaqPage;
  restoreFaqQuestion: FaqQuestion;
  restoreFaqQuestionGroup: FaqQuestionGroup;
  restoreFile: File;
  restoreFileObjectType: FileObjectType;
  restoreGeneralPage: GeneralPage;
  restoreHipayOnboardingNotification: HipayOnboardingNotification;
  restoreInvoice: Invoice;
  restoreLanguage: Language;
  restoreLocation: Location;
  restoreMobianPayment: MobianPayment;
  restoreOption: Option;
  restoreOptionCategory: OptionCategory;
  restoreParameter: Parameter;
  restoreParking: Parking;
  restoreParkingPage: ParkingPage;
  restoreParkingType: ParkingType;
  restoreParkingTypePage: ParkingTypePage;
  restorePaymentMethod: PaymentMethod;
  restorePmsProvider: PmsProvider;
  restorePriceList: PriceList;
  restorePriceStrategy: PriceStrategy;
  restoreProduct: Product;
  restoreProvider: Provider;
  restoreRedirect: Redirect;
  restoreRole: Role;
  restoreSafeAndSecureLogo: SafeAndSecureLogo;
  restoreSection: Section;
  restoreSectionType: SectionType;
  restoreSentEmail: SentEmail;
  restoreService: Service;
  restoreSite: Site;
  restoreSocialMedia: SocialMedia;
  restoreSubdomain: Subdomain;
  restoreTerminal: Terminal;
  restoreTestLog: TestLog;
  restoreTranslation: Translation;
  restoreTrustMarkLogo: TrustMarkLogo;
  restoreUser: User;
  runTest: TestLog;
  sendEmail: SendEmailResponse;
  sendEmails: Array<Maybe<SendEmailResponse>>;
  signIn: SignInPayload;
  signOut: SignOutPayload;
  softDeleteAffiliateParking: AffiliateParking;
  softDeleteAffiliateParkingPage: AffiliateParkingPage;
  softDeleteAirport: Airport;
  softDeleteAirportPage: AirportPage;
  softDeleteBlogPage: BlogPage;
  softDeleteBooking: Booking;
  softDeleteBusinessEntity: BusinessEntity;
  softDeleteConfigurationLocation: ConfigurationLocation;
  softDeleteContactPoint: ContactPoint;
  softDeleteCountry: Country;
  softDeleteCurrency: Currency;
  softDeleteDocument: Document;
  softDeleteEmailTemplate: EmailTemplate;
  softDeleteEmailTemplateAttachment: EmailTemplateAttachment;
  softDeleteEmailTemplateParameter: EmailTemplateParameter;
  softDeleteFaqPage: FaqPage;
  softDeleteFaqQuestion: FaqQuestion;
  softDeleteFaqQuestionGroup: FaqQuestionGroup;
  softDeleteFile: File;
  softDeleteFileObjectType: FileObjectType;
  softDeleteGeneralPage: GeneralPage;
  softDeleteHipayOnboardingNotification: HipayOnboardingNotification;
  softDeleteInvoice: Invoice;
  softDeleteLanguage: Language;
  softDeleteLocation: Location;
  softDeleteMobianPayment: MobianPayment;
  softDeleteOption: Option;
  softDeleteOptionCategory: OptionCategory;
  softDeleteParameter: Parameter;
  softDeleteParking: Parking;
  softDeleteParkingPage: ParkingPage;
  softDeleteParkingType: ParkingType;
  softDeleteParkingTypePage: ParkingTypePage;
  softDeletePaymentMethod: PaymentMethod;
  softDeletePmsProvider: PmsProvider;
  softDeletePriceList: PriceList;
  softDeletePriceStrategy: PriceStrategy;
  softDeleteProduct: Product;
  softDeleteProvider: Provider;
  softDeleteRedirect: Redirect;
  softDeleteRole: Role;
  softDeleteSafeAndSecureLogo: SafeAndSecureLogo;
  softDeleteSection: Section;
  softDeleteSectionType: SectionType;
  softDeleteSentEmail: SentEmail;
  softDeleteService: Service;
  softDeleteSite: Site;
  softDeleteSocialMedia: SocialMedia;
  softDeleteSubdomain: Subdomain;
  softDeleteTerminal: Terminal;
  softDeleteTestLog: TestLog;
  softDeleteTranslation: Translation;
  softDeleteTrustMarkLogo: TrustMarkLogo;
  softDeleteUser: User;
  updateAffiliateParking: AffiliateParking;
  updateAffiliateParkingPage: AffiliateParkingPage;
  updateAirport: Airport;
  updateAirportPage: AirportPage;
  updateBlogPage: BlogPage;
  updateBooking: Booking;
  updateBusinessEntity: BusinessEntity;
  updateConfigurationLocation: ConfigurationLocation;
  updateContactPoint: ContactPoint;
  updateCountry: Country;
  updateCurrency: Currency;
  updateDocument: Document;
  updateEmailTemplate: EmailTemplate;
  updateEmailTemplateAttachment: EmailTemplateAttachment;
  updateEmailTemplateParameter: EmailTemplateParameter;
  updateFaqPage: FaqPage;
  updateFaqQuestion: FaqQuestion;
  updateFaqQuestionGroup: FaqQuestionGroup;
  updateFile: File;
  updateFileObjectType: FileObjectType;
  updateGeneralPage: GeneralPage;
  updateHipayOnboardingNotification: HipayOnboardingNotification;
  updateInvoice: Invoice;
  updateLanguage: Language;
  updateLocation: Location;
  updateMobianPayment: MobianPayment;
  updateOption: Option;
  updateOptionCategory: OptionCategory;
  updateParameter: Parameter;
  updateParking: Parking;
  updateParkingPage: ParkingPage;
  updateParkingType: ParkingType;
  updateParkingTypePage: ParkingTypePage;
  updatePaymentMethod: PaymentMethod;
  updatePmsProvider: PmsProvider;
  updatePriceList: PriceList;
  updatePriceStrategy: PriceStrategy;
  updateProduct: Product;
  updateProvider: Provider;
  updateRedirect: Redirect;
  updateRole: Role;
  updateSafeAndSecureLogo: SafeAndSecureLogo;
  updateSection: Section;
  updateSectionType: SectionType;
  updateSentEmail: SentEmail;
  updateService: Service;
  updateSite: Site;
  updateSocialMedia: SocialMedia;
  updateSubdomain: Subdomain;
  updateTerminal: Terminal;
  updateTestLog: TestLog;
  updateTranslation: Translation;
  updateTrustMarkLogo: TrustMarkLogo;
  updateUser: User;
};

export type MutationAcceptConsentArgs = {
  consentToAccept: ConsentInput;
};

export type MutationCreateAffiliateParkingArgs = {
  input: AffiliateParkingCreateInput;
};

export type MutationCreateAffiliateParkingPageArgs = {
  input: AffiliateParkingPageCreateInput;
};

export type MutationCreateAirportArgs = {
  input: AirportCreateInput;
};

export type MutationCreateAirportPageArgs = {
  input: AirportPageCreateInput;
};

export type MutationCreateBlogPageArgs = {
  input: BlogPageCreateInput;
};

export type MutationCreateBookingArgs = {
  input: BookingCreateInput;
};

export type MutationCreateBusinessEntityArgs = {
  input: BusinessEntityCreateInput;
};

export type MutationCreateConfigurationLocationArgs = {
  input: ConfigurationLocationCreateInput;
};

export type MutationCreateContactPointArgs = {
  input: ContactPointCreateInput;
};

export type MutationCreateCountryArgs = {
  input: CountryCreateInput;
};

export type MutationCreateCurrencyArgs = {
  input: CurrencyCreateInput;
};

export type MutationCreateDocumentArgs = {
  input: DocumentCreateInput;
};

export type MutationCreateEmailTemplateArgs = {
  input: EmailTemplateCreateInput;
};

export type MutationCreateEmailTemplateAttachmentArgs = {
  input: EmailTemplateAttachmentCreateInput;
};

export type MutationCreateEmailTemplateParameterArgs = {
  input: EmailTemplateParameterCreateInput;
};

export type MutationCreateFaqPageArgs = {
  input: FaqPageCreateInput;
};

export type MutationCreateFaqQuestionArgs = {
  input: FaqQuestionCreateInput;
};

export type MutationCreateFaqQuestionGroupArgs = {
  input: FaqQuestionGroupCreateInput;
};

export type MutationCreateFileArgs = {
  input: FileCreateInput;
};

export type MutationCreateFileObjectTypeArgs = {
  input: FileObjectTypeCreateInput;
};

export type MutationCreateGeneralPageArgs = {
  input: GeneralPageCreateInput;
};

export type MutationCreateHipayOnboardingNotificationArgs = {
  input: HipayOnboardingNotificationCreateInput;
};

export type MutationCreateInvoiceArgs = {
  input: InvoiceCreateInput;
};

export type MutationCreateLanguageArgs = {
  input: LanguageCreateInput;
};

export type MutationCreateLocationArgs = {
  input: LocationCreateInput;
};

export type MutationCreateMobianPaymentArgs = {
  input: MobianPaymentCreateInput;
};

export type MutationCreateOptionArgs = {
  input: OptionCreateInput;
};

export type MutationCreateOptionCategoryArgs = {
  input: OptionCategoryCreateInput;
};

export type MutationCreateParameterArgs = {
  input: ParameterCreateInput;
};

export type MutationCreateParkingArgs = {
  input: ParkingCreateInput;
};

export type MutationCreateParkingPageArgs = {
  input: ParkingPageCreateInput;
};

export type MutationCreateParkingTypeArgs = {
  input: ParkingTypeCreateInput;
};

export type MutationCreateParkingTypePageArgs = {
  input: ParkingTypePageCreateInput;
};

export type MutationCreatePaymentMethodArgs = {
  input: PaymentMethodCreateInput;
};

export type MutationCreatePmsProviderArgs = {
  input: PmsProviderCreateInput;
};

export type MutationCreatePreUploadUrlArgs = {
  input: PreUploadUrlInput;
};

export type MutationCreatePreUploadUrlsArgs = {
  input: Array<PreUploadUrlInput>;
};

export type MutationCreatePriceListArgs = {
  input: PriceListCreateInput;
};

export type MutationCreatePriceStrategyArgs = {
  input: PriceStrategyCreateInput;
};

export type MutationCreateProductArgs = {
  input: ProductCreateInput;
};

export type MutationCreateProviderArgs = {
  input: ProviderCreateInput;
};

export type MutationCreateRedirectArgs = {
  input: RedirectCreateInput;
};

export type MutationCreateRoleArgs = {
  input: RoleCreateInput;
};

export type MutationCreateSafeAndSecureLogoArgs = {
  input: SafeAndSecureLogoCreateInput;
};

export type MutationCreateSectionArgs = {
  input: SectionCreateInput;
};

export type MutationCreateSectionTypeArgs = {
  input: SectionTypeCreateInput;
};

export type MutationCreateSentEmailArgs = {
  input: SentEmailCreateInput;
};

export type MutationCreateServiceArgs = {
  input: ServiceCreateInput;
};

export type MutationCreateSiteArgs = {
  input: SiteCreateInput;
};

export type MutationCreateSocialMediaArgs = {
  input: SocialMediaCreateInput;
};

export type MutationCreateSubdomainArgs = {
  input: SubdomainCreateInput;
};

export type MutationCreateTerminalArgs = {
  input: TerminalCreateInput;
};

export type MutationCreateTestLogArgs = {
  input: TestLogCreateInput;
};

export type MutationCreateTranslationArgs = {
  input: TranslationCreateInput;
};

export type MutationCreateTrustMarkLogoArgs = {
  input: TrustMarkLogoCreateInput;
};

export type MutationCreateUserArgs = {
  input: UserCreateInput;
};

export type MutationExchangeTokenArgs = {
  tokenToExchange: ExchangeTokenInput;
};

export type MutationFetchConsentArgs = {
  consentToFetch: ConsentInput;
};

export type MutationHardDeleteAffiliateParkingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteAffiliateParkingPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteAirportArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteAirportPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteBlogPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteBookingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteBusinessEntityArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteConfigurationLocationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteContactPointArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteCountryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteCurrencyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteEmailTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteEmailTemplateAttachmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteEmailTemplateParameterArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteFaqPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteFaqQuestionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteFaqQuestionGroupArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteFileObjectTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteGeneralPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteHipayOnboardingNotificationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteInvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteLanguageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteLocationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteMobianPaymentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteOptionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteOptionCategoryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteParameterArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteParkingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteParkingPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteParkingTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteParkingTypePageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeletePaymentMethodArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeletePmsProviderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeletePriceListArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeletePriceStrategyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteProductArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteProviderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteRedirectArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteRoleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteSafeAndSecureLogoArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteSectionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteSectionTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteSentEmailArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteServiceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteSiteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteSocialMediaArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteSubdomainArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteTerminalArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteTestLogArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteTranslationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteTrustMarkLogoArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHardDeleteUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRefreshTokenArgs = {
  currentRefreshToken: Scalars['String']['input'];
};

export type MutationRejectConsentArgs = {
  consentToReject: ConsentInput;
};

export type MutationRestoreAffiliateParkingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreAffiliateParkingPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreAirportArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreAirportPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreBlogPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreBookingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreBusinessEntityArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreConfigurationLocationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreContactPointArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreCountryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreCurrencyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreEmailTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreEmailTemplateAttachmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreEmailTemplateParameterArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreFaqPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreFaqQuestionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreFaqQuestionGroupArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreFileObjectTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreGeneralPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreHipayOnboardingNotificationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreInvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreLanguageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreLocationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreMobianPaymentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreOptionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreOptionCategoryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreParameterArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreParkingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreParkingPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreParkingTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreParkingTypePageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestorePaymentMethodArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestorePmsProviderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestorePriceListArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestorePriceStrategyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreProductArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreProviderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreRedirectArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreRoleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreSafeAndSecureLogoArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreSectionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreSectionTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreSentEmailArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreServiceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreSiteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreSocialMediaArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreSubdomainArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreTerminalArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreTestLogArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreTranslationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreTrustMarkLogoArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRunTestArgs = {
  testCaseName: Scalars['String']['input'];
};

export type MutationSendEmailArgs = {
  input: SendEmailInput;
};

export type MutationSendEmailsArgs = {
  input: Array<SendEmailInput>;
};

export type MutationSignInArgs = {
  userToSign: SignInInput;
};

export type MutationSignOutArgs = {
  signOutParams: SignOutInput;
};

export type MutationSoftDeleteAffiliateParkingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteAffiliateParkingPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteAirportArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteAirportPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteBlogPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteBookingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteBusinessEntityArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteConfigurationLocationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteContactPointArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteCountryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteCurrencyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteEmailTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteEmailTemplateAttachmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteEmailTemplateParameterArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteFaqPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteFaqQuestionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteFaqQuestionGroupArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteFileObjectTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteGeneralPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteHipayOnboardingNotificationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteInvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteLanguageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteLocationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteMobianPaymentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteOptionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteOptionCategoryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteParameterArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteParkingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteParkingPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteParkingTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteParkingTypePageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeletePaymentMethodArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeletePmsProviderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeletePriceListArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeletePriceStrategyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteProductArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteProviderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteRedirectArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteRoleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteSafeAndSecureLogoArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteSectionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteSectionTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteSentEmailArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteServiceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteSiteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteSocialMediaArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteSubdomainArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteTerminalArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteTestLogArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteTranslationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteTrustMarkLogoArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSoftDeleteUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUpdateAffiliateParkingArgs = {
  id: Scalars['ID']['input'];
  input: AffiliateParkingUpdateInput;
};

export type MutationUpdateAffiliateParkingPageArgs = {
  id: Scalars['ID']['input'];
  input: AffiliateParkingPageUpdateInput;
};

export type MutationUpdateAirportArgs = {
  id: Scalars['ID']['input'];
  input: AirportUpdateInput;
};

export type MutationUpdateAirportPageArgs = {
  id: Scalars['ID']['input'];
  input: AirportPageUpdateInput;
};

export type MutationUpdateBlogPageArgs = {
  id: Scalars['ID']['input'];
  input: BlogPageUpdateInput;
};

export type MutationUpdateBookingArgs = {
  id: Scalars['ID']['input'];
  input: BookingUpdateInput;
};

export type MutationUpdateBusinessEntityArgs = {
  id: Scalars['ID']['input'];
  input: BusinessEntityUpdateInput;
};

export type MutationUpdateConfigurationLocationArgs = {
  id: Scalars['ID']['input'];
  input: ConfigurationLocationUpdateInput;
};

export type MutationUpdateContactPointArgs = {
  id: Scalars['ID']['input'];
  input: ContactPointUpdateInput;
};

export type MutationUpdateCountryArgs = {
  id: Scalars['ID']['input'];
  input: CountryUpdateInput;
};

export type MutationUpdateCurrencyArgs = {
  id: Scalars['ID']['input'];
  input: CurrencyUpdateInput;
};

export type MutationUpdateDocumentArgs = {
  id: Scalars['ID']['input'];
  input: DocumentUpdateInput;
};

export type MutationUpdateEmailTemplateArgs = {
  id: Scalars['ID']['input'];
  input: EmailTemplateUpdateInput;
};

export type MutationUpdateEmailTemplateAttachmentArgs = {
  id: Scalars['ID']['input'];
  input: EmailTemplateAttachmentUpdateInput;
};

export type MutationUpdateEmailTemplateParameterArgs = {
  id: Scalars['ID']['input'];
  input: EmailTemplateParameterUpdateInput;
};

export type MutationUpdateFaqPageArgs = {
  id: Scalars['ID']['input'];
  input: FaqPageUpdateInput;
};

export type MutationUpdateFaqQuestionArgs = {
  id: Scalars['ID']['input'];
  input: FaqQuestionUpdateInput;
};

export type MutationUpdateFaqQuestionGroupArgs = {
  id: Scalars['ID']['input'];
  input: FaqQuestionGroupUpdateInput;
};

export type MutationUpdateFileArgs = {
  id: Scalars['ID']['input'];
  input: FileUpdateInput;
};

export type MutationUpdateFileObjectTypeArgs = {
  id: Scalars['ID']['input'];
  input: FileObjectTypeUpdateInput;
};

export type MutationUpdateGeneralPageArgs = {
  id: Scalars['ID']['input'];
  input: GeneralPageUpdateInput;
};

export type MutationUpdateHipayOnboardingNotificationArgs = {
  id: Scalars['ID']['input'];
  input: HipayOnboardingNotificationUpdateInput;
};

export type MutationUpdateInvoiceArgs = {
  id: Scalars['ID']['input'];
  input: InvoiceUpdateInput;
};

export type MutationUpdateLanguageArgs = {
  id: Scalars['ID']['input'];
  input: LanguageUpdateInput;
};

export type MutationUpdateLocationArgs = {
  id: Scalars['ID']['input'];
  input: LocationUpdateInput;
};

export type MutationUpdateMobianPaymentArgs = {
  id: Scalars['ID']['input'];
  input: MobianPaymentUpdateInput;
};

export type MutationUpdateOptionArgs = {
  id: Scalars['ID']['input'];
  input: OptionUpdateInput;
};

export type MutationUpdateOptionCategoryArgs = {
  id: Scalars['ID']['input'];
  input: OptionCategoryUpdateInput;
};

export type MutationUpdateParameterArgs = {
  id: Scalars['ID']['input'];
  input: ParameterUpdateInput;
};

export type MutationUpdateParkingArgs = {
  id: Scalars['ID']['input'];
  input: ParkingUpdateInput;
};

export type MutationUpdateParkingPageArgs = {
  id: Scalars['ID']['input'];
  input: ParkingPageUpdateInput;
};

export type MutationUpdateParkingTypeArgs = {
  id: Scalars['ID']['input'];
  input: ParkingTypeUpdateInput;
};

export type MutationUpdateParkingTypePageArgs = {
  id: Scalars['ID']['input'];
  input: ParkingTypePageUpdateInput;
};

export type MutationUpdatePaymentMethodArgs = {
  id: Scalars['ID']['input'];
  input: PaymentMethodUpdateInput;
};

export type MutationUpdatePmsProviderArgs = {
  id: Scalars['ID']['input'];
  input: PmsProviderUpdateInput;
};

export type MutationUpdatePriceListArgs = {
  id: Scalars['ID']['input'];
  input: PriceListUpdateInput;
};

export type MutationUpdatePriceStrategyArgs = {
  id: Scalars['ID']['input'];
  input: PriceStrategyUpdateInput;
};

export type MutationUpdateProductArgs = {
  id: Scalars['ID']['input'];
  input: ProductUpdateInput;
};

export type MutationUpdateProviderArgs = {
  id: Scalars['ID']['input'];
  input: ProviderUpdateInput;
};

export type MutationUpdateRedirectArgs = {
  id: Scalars['ID']['input'];
  input: RedirectUpdateInput;
};

export type MutationUpdateRoleArgs = {
  id: Scalars['ID']['input'];
  input: RoleUpdateInput;
};

export type MutationUpdateSafeAndSecureLogoArgs = {
  id: Scalars['ID']['input'];
  input: SafeAndSecureLogoUpdateInput;
};

export type MutationUpdateSectionArgs = {
  id: Scalars['ID']['input'];
  input: SectionUpdateInput;
};

export type MutationUpdateSectionTypeArgs = {
  id: Scalars['ID']['input'];
  input: SectionTypeUpdateInput;
};

export type MutationUpdateSentEmailArgs = {
  id: Scalars['ID']['input'];
  input: SentEmailUpdateInput;
};

export type MutationUpdateServiceArgs = {
  id: Scalars['ID']['input'];
  input: ServiceUpdateInput;
};

export type MutationUpdateSiteArgs = {
  id: Scalars['ID']['input'];
  input: SiteUpdateInput;
};

export type MutationUpdateSocialMediaArgs = {
  id: Scalars['ID']['input'];
  input: SocialMediaUpdateInput;
};

export type MutationUpdateSubdomainArgs = {
  id: Scalars['ID']['input'];
  input: SubdomainUpdateInput;
};

export type MutationUpdateTerminalArgs = {
  id: Scalars['ID']['input'];
  input: TerminalUpdateInput;
};

export type MutationUpdateTestLogArgs = {
  id: Scalars['ID']['input'];
  input: TestLogUpdateInput;
};

export type MutationUpdateTranslationArgs = {
  id: Scalars['ID']['input'];
  input: TranslationUpdateInput;
};

export type MutationUpdateTrustMarkLogoArgs = {
  id: Scalars['ID']['input'];
  input: TrustMarkLogoUpdateInput;
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  input: UserUpdateInput;
};

export type Option = {
  __typename?: 'Option';
  cancelable: Scalars['Boolean']['output'];
  category?: Maybe<OptionCategory>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  remark: Scalars['String']['output'];
  serviceOptions?: Maybe<Array<OptionServiceOption>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type OptionCategory = {
  __typename?: 'OptionCategory';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  iconUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  options?: Maybe<Array<Option>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type OptionCategoryCreateInput = {
  iconUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
  optionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OptionCategoryListRelationFilter = {
  every?: InputMaybe<OptionCategoryWhereInput>;
  none?: InputMaybe<OptionCategoryWhereInput>;
  some?: InputMaybe<OptionCategoryWhereInput>;
};

export type OptionCategoryOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  iconUrl?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OptionCategoryPaginatedResponse = {
  __typename?: 'OptionCategoryPaginatedResponse';
  items: Array<OptionCategory>;
  pageInfo: PageInfo;
};

export type OptionCategoryUpdateInput = {
  iconUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  optionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  optionIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  optionIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OptionCategoryWhereInput = {
  AND?: InputMaybe<Array<OptionCategoryWhereInput>>;
  NOT?: InputMaybe<Array<OptionCategoryWhereInput>>;
  OR?: InputMaybe<Array<OptionCategoryWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  iconUrl?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  options?: InputMaybe<OptionListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type OptionCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type OptionCreateInput = {
  cancelable: Scalars['Boolean']['input'];
  categoryIdConnect: Scalars['ID']['input'];
  icon: Scalars['String']['input'];
  name: Scalars['String']['input'];
  remark: Scalars['String']['input'];
  serviceOptionsCreate?: InputMaybe<Array<OptionServiceOptionCreateInput>>;
};

export type OptionListRelationFilter = {
  every?: InputMaybe<OptionWhereInput>;
  none?: InputMaybe<OptionWhereInput>;
  some?: InputMaybe<OptionWhereInput>;
};

export type OptionOrderByInput = {
  cancelable?: InputMaybe<SortOrder>;
  category?: InputMaybe<OptionCategoryOrderByInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  icon?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  optionCategoryId?: InputMaybe<SortOrder>;
  remark?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OptionPaginatedResponse = {
  __typename?: 'OptionPaginatedResponse';
  items: Array<Option>;
  pageInfo: PageInfo;
};

export type OptionServiceOption = {
  __typename?: 'OptionServiceOption';
  billLineDescription?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isFree: Scalars['Boolean']['output'];
  payOnLocation: Scalars['Boolean']['output'];
  price: Scalars['Decimal']['output'];
  remark: Scalars['String']['output'];
  service?: Maybe<Service>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type OptionServiceOptionCreateInput = {
  billLineDescription?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isFree: Scalars['Boolean']['input'];
  payOnLocation: Scalars['Boolean']['input'];
  price: Scalars['Decimal']['input'];
  remark: Scalars['String']['input'];
  serviceId: Scalars['ID']['input'];
};

export type OptionServiceOptionDeleteInput = {
  serviceId: Scalars['ID']['input'];
};

export type OptionUpdateInput = {
  cancelable?: InputMaybe<Scalars['Boolean']['input']>;
  categoryIdConnect?: InputMaybe<Scalars['ID']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  serviceOptionsDelete?: InputMaybe<Array<OptionServiceOptionDeleteInput>>;
  serviceOptionsReplace?: InputMaybe<Array<OptionServiceOptionCreateInput>>;
  serviceOptionsUpsert?: InputMaybe<Array<OptionServiceOptionCreateInput>>;
};

export type OptionWhereInput = {
  AND?: InputMaybe<Array<OptionWhereInput>>;
  NOT?: InputMaybe<Array<OptionWhereInput>>;
  OR?: InputMaybe<Array<OptionWhereInput>>;
  cancelable?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<OptionCategoryWhereInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  icon?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  optionCategoryId?: InputMaybe<StringFilterInput>;
  remark?: InputMaybe<StringFilterInput>;
  serviceOptions?: InputMaybe<ServiceOptionListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type OptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  currentPage: Scalars['Int']['output'];
  hasMore: Scalars['Boolean']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type Parameter = {
  __typename?: 'Parameter';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  templates?: Maybe<Array<EmailTemplateParameter>>;
  type: ParameterType;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ParameterCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  templateIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  type: ParameterType;
};

export type ParameterListRelationFilter = {
  every?: InputMaybe<ParameterWhereInput>;
  none?: InputMaybe<ParameterWhereInput>;
  some?: InputMaybe<ParameterWhereInput>;
};

export type ParameterOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ParameterPaginatedResponse = {
  __typename?: 'ParameterPaginatedResponse';
  items: Array<Parameter>;
  pageInfo: PageInfo;
};

export enum ParameterType {
  Bigint = 'bigint',
  Boolean = 'boolean',
  Date = 'date',
  Json = 'json',
  Number = 'number',
  String = 'string',
}

export type ParameterUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  templateIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  templateIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  templateIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  type?: InputMaybe<ParameterType>;
};

export type ParameterWhereInput = {
  AND?: InputMaybe<Array<ParameterWhereInput>>;
  NOT?: InputMaybe<Array<ParameterWhereInput>>;
  OR?: InputMaybe<Array<ParameterWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  templates?: InputMaybe<EmailTemplateParameterListRelationFilter>;
  type?: InputMaybe<ParameterType>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ParameterWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Parking = {
  __typename?: 'Parking';
  additionalInformation: Scalars['String']['output'];
  addressInstructions: Array<Scalars['String']['output']>;
  businessEntityId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  locations?: Maybe<Array<Location>>;
  mainLocationId?: Maybe<Scalars['String']['output']>;
  photos: Array<Scalars['String']['output']>;
  procedure: Array<Scalars['String']['output']>;
  provider?: Maybe<Provider>;
  remarks: Array<Scalars['String']['output']>;
  sections?: Maybe<Array<Section>>;
  travelDistanceToAirport: Scalars['Int']['output'];
  travelTimeToAirport: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ParkingCreateInput = {
  additionalInformation: Scalars['String']['input'];
  addressInstructions: Array<Scalars['String']['input']>;
  businessEntityId: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  locationIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  mainLocationId?: InputMaybe<Scalars['String']['input']>;
  photos: Array<Scalars['String']['input']>;
  procedure: Array<Scalars['String']['input']>;
  providerIdConnect: Scalars['ID']['input'];
  remarks: Array<Scalars['String']['input']>;
  sectionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  travelDistanceToAirport: Scalars['Int']['input'];
  travelTimeToAirport: Scalars['Int']['input'];
};

export type ParkingListRelationFilter = {
  every?: InputMaybe<ParkingWhereInput>;
  none?: InputMaybe<ParkingWhereInput>;
  some?: InputMaybe<ParkingWhereInput>;
};

export type ParkingOrderByInput = {
  additionalInformation?: InputMaybe<SortOrder>;
  addressInstructions?: InputMaybe<SortOrder>;
  businessEntityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  enabled?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainLocationId?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  procedure?: InputMaybe<SortOrder>;
  provider?: InputMaybe<ProviderOrderByInput>;
  providerId?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
  travelDistanceToAirport?: InputMaybe<SortOrder>;
  travelTimeToAirport?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ParkingPage = {
  __typename?: 'ParkingPage';
  active: Scalars['Boolean']['output'];
  acuContentAirportPage?: Maybe<Scalars['String']['output']>;
  acuContentAirportParkingTypePage?: Maybe<Scalars['String']['output']>;
  acuContentAirportTerminalPage?: Maybe<Scalars['String']['output']>;
  acuContentPriceArticle?: Maybe<Scalars['String']['output']>;
  acuContentTop1?: Maybe<Scalars['String']['output']>;
  acuContentTop2?: Maybe<Scalars['String']['output']>;
  acuContentTop3?: Maybe<Scalars['String']['output']>;
  acuContentTop4?: Maybe<Scalars['String']['output']>;
  acuContentTop5?: Maybe<Scalars['String']['output']>;
  airportFee?: Maybe<Scalars['String']['output']>;
  billLineDescription?: Maybe<Scalars['String']['output']>;
  comparePageDescription?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description1?: Maybe<Scalars['String']['output']>;
  description2?: Maybe<Scalars['String']['output']>;
  description3?: Maybe<Scalars['String']['output']>;
  description4?: Maybe<Scalars['String']['output']>;
  extraPriceInformation?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parking?: Maybe<Parking>;
  parkingId: Scalars['String']['output'];
  seoMetaDescription: Scalars['String']['output'];
  seoMetaTitle: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ParkingPageCreateInput = {
  active: Scalars['Boolean']['input'];
  acuContentAirportPage?: InputMaybe<Scalars['String']['input']>;
  acuContentAirportParkingTypePage?: InputMaybe<Scalars['String']['input']>;
  acuContentAirportTerminalPage?: InputMaybe<Scalars['String']['input']>;
  acuContentPriceArticle?: InputMaybe<Scalars['String']['input']>;
  acuContentTop1?: InputMaybe<Scalars['String']['input']>;
  acuContentTop2?: InputMaybe<Scalars['String']['input']>;
  acuContentTop3?: InputMaybe<Scalars['String']['input']>;
  acuContentTop4?: InputMaybe<Scalars['String']['input']>;
  acuContentTop5?: InputMaybe<Scalars['String']['input']>;
  airportFee?: InputMaybe<Scalars['String']['input']>;
  billLineDescription?: InputMaybe<Scalars['String']['input']>;
  comparePageDescription?: InputMaybe<Scalars['String']['input']>;
  description1?: InputMaybe<Scalars['String']['input']>;
  description2?: InputMaybe<Scalars['String']['input']>;
  description3?: InputMaybe<Scalars['String']['input']>;
  description4?: InputMaybe<Scalars['String']['input']>;
  extraPriceInformation?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parkingId: Scalars['String']['input'];
  seoMetaDescription: Scalars['String']['input'];
  seoMetaTitle: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type ParkingPageListRelationFilter = {
  every?: InputMaybe<ParkingPageWhereInput>;
  none?: InputMaybe<ParkingPageWhereInput>;
  some?: InputMaybe<ParkingPageWhereInput>;
};

export type ParkingPageOrderByInput = {
  active?: InputMaybe<SortOrder>;
  acuContentAirportPage?: InputMaybe<SortOrder>;
  acuContentAirportParkingTypePage?: InputMaybe<SortOrder>;
  acuContentAirportTerminalPage?: InputMaybe<SortOrder>;
  acuContentPriceArticle?: InputMaybe<SortOrder>;
  acuContentTop1?: InputMaybe<SortOrder>;
  acuContentTop2?: InputMaybe<SortOrder>;
  acuContentTop3?: InputMaybe<SortOrder>;
  acuContentTop4?: InputMaybe<SortOrder>;
  acuContentTop5?: InputMaybe<SortOrder>;
  airportFee?: InputMaybe<SortOrder>;
  billLineDescription?: InputMaybe<SortOrder>;
  comparePageDescription?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description1?: InputMaybe<SortOrder>;
  description2?: InputMaybe<SortOrder>;
  description3?: InputMaybe<SortOrder>;
  description4?: InputMaybe<SortOrder>;
  extraPriceInformation?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parkingId?: InputMaybe<SortOrder>;
  seoMetaDescription?: InputMaybe<SortOrder>;
  seoMetaTitle?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ParkingPagePaginatedResponse = {
  __typename?: 'ParkingPagePaginatedResponse';
  items: Array<ParkingPage>;
  pageInfo: PageInfo;
};

export type ParkingPageUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  acuContentAirportPage?: InputMaybe<Scalars['String']['input']>;
  acuContentAirportParkingTypePage?: InputMaybe<Scalars['String']['input']>;
  acuContentAirportTerminalPage?: InputMaybe<Scalars['String']['input']>;
  acuContentPriceArticle?: InputMaybe<Scalars['String']['input']>;
  acuContentTop1?: InputMaybe<Scalars['String']['input']>;
  acuContentTop2?: InputMaybe<Scalars['String']['input']>;
  acuContentTop3?: InputMaybe<Scalars['String']['input']>;
  acuContentTop4?: InputMaybe<Scalars['String']['input']>;
  acuContentTop5?: InputMaybe<Scalars['String']['input']>;
  airportFee?: InputMaybe<Scalars['String']['input']>;
  billLineDescription?: InputMaybe<Scalars['String']['input']>;
  comparePageDescription?: InputMaybe<Scalars['String']['input']>;
  description1?: InputMaybe<Scalars['String']['input']>;
  description2?: InputMaybe<Scalars['String']['input']>;
  description3?: InputMaybe<Scalars['String']['input']>;
  description4?: InputMaybe<Scalars['String']['input']>;
  extraPriceInformation?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parkingId?: InputMaybe<Scalars['String']['input']>;
  seoMetaDescription?: InputMaybe<Scalars['String']['input']>;
  seoMetaTitle?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type ParkingPageWhereInput = {
  AND?: InputMaybe<Array<ParkingPageWhereInput>>;
  NOT?: InputMaybe<Array<ParkingPageWhereInput>>;
  OR?: InputMaybe<Array<ParkingPageWhereInput>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  acuContentAirportPage?: InputMaybe<StringFilterInput>;
  acuContentAirportParkingTypePage?: InputMaybe<StringFilterInput>;
  acuContentAirportTerminalPage?: InputMaybe<StringFilterInput>;
  acuContentPriceArticle?: InputMaybe<StringFilterInput>;
  acuContentTop1?: InputMaybe<StringFilterInput>;
  acuContentTop2?: InputMaybe<StringFilterInput>;
  acuContentTop3?: InputMaybe<StringFilterInput>;
  acuContentTop4?: InputMaybe<StringFilterInput>;
  acuContentTop5?: InputMaybe<StringFilterInput>;
  airportFee?: InputMaybe<StringFilterInput>;
  billLineDescription?: InputMaybe<StringFilterInput>;
  comparePageDescription?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  description1?: InputMaybe<StringFilterInput>;
  description2?: InputMaybe<StringFilterInput>;
  description3?: InputMaybe<StringFilterInput>;
  description4?: InputMaybe<StringFilterInput>;
  extraPriceInformation?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  parkingId?: InputMaybe<StringFilterInput>;
  seoMetaDescription?: InputMaybe<StringFilterInput>;
  seoMetaTitle?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ParkingPageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  parkingId?: InputMaybe<Scalars['String']['input']>;
};

export type ParkingPaginatedResponse = {
  __typename?: 'ParkingPaginatedResponse';
  items: Array<Parking>;
  pageInfo: PageInfo;
};

export type ParkingType = {
  __typename?: 'ParkingType';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  iconUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  services?: Maybe<Array<Service>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ParkingTypeCreateInput = {
  iconUrl: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  serviceIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ParkingTypeListRelationFilter = {
  every?: InputMaybe<ParkingTypeWhereInput>;
  none?: InputMaybe<ParkingTypeWhereInput>;
  some?: InputMaybe<ParkingTypeWhereInput>;
};

export type ParkingTypeOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  iconUrl?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ParkingTypePage = {
  __typename?: 'ParkingTypePage';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parkingType?: Maybe<ParkingType>;
  parkingTypeDescription?: Maybe<Scalars['String']['output']>;
  parkingTypeId: Scalars['String']['output'];
  reason1: Scalars['String']['output'];
  reason2: Scalars['String']['output'];
  reason3: Scalars['String']['output'];
  seoMetaDescription: Scalars['String']['output'];
  seoMetaTitle: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  step1Description: Scalars['String']['output'];
  step1IconUrl: Scalars['String']['output'];
  step1Name: Scalars['String']['output'];
  step2Description: Scalars['String']['output'];
  step2IconUrl: Scalars['String']['output'];
  step2Name: Scalars['String']['output'];
  step3Description: Scalars['String']['output'];
  step3IconUrl: Scalars['String']['output'];
  step3Name: Scalars['String']['output'];
  step4Description: Scalars['String']['output'];
  step4IconUrl: Scalars['String']['output'];
  step4Name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ParkingTypePageCreateInput = {
  active: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  parkingTypeDescription?: InputMaybe<Scalars['String']['input']>;
  parkingTypeId: Scalars['String']['input'];
  reason1: Scalars['String']['input'];
  reason2: Scalars['String']['input'];
  reason3: Scalars['String']['input'];
  seoMetaDescription: Scalars['String']['input'];
  seoMetaTitle: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  step1Description: Scalars['String']['input'];
  step1IconUrl: Scalars['String']['input'];
  step1Name: Scalars['String']['input'];
  step2Description: Scalars['String']['input'];
  step2IconUrl: Scalars['String']['input'];
  step2Name: Scalars['String']['input'];
  step3Description: Scalars['String']['input'];
  step3IconUrl: Scalars['String']['input'];
  step3Name: Scalars['String']['input'];
  step4Description: Scalars['String']['input'];
  step4IconUrl: Scalars['String']['input'];
  step4Name: Scalars['String']['input'];
};

export type ParkingTypePageListRelationFilter = {
  every?: InputMaybe<ParkingTypePageWhereInput>;
  none?: InputMaybe<ParkingTypePageWhereInput>;
  some?: InputMaybe<ParkingTypePageWhereInput>;
};

export type ParkingTypePageOrderByInput = {
  active?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parkingTypeDescription?: InputMaybe<SortOrder>;
  parkingTypeId?: InputMaybe<SortOrder>;
  reason1?: InputMaybe<SortOrder>;
  reason2?: InputMaybe<SortOrder>;
  reason3?: InputMaybe<SortOrder>;
  seoMetaDescription?: InputMaybe<SortOrder>;
  seoMetaTitle?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  step1Description?: InputMaybe<SortOrder>;
  step1IconUrl?: InputMaybe<SortOrder>;
  step1Name?: InputMaybe<SortOrder>;
  step2Description?: InputMaybe<SortOrder>;
  step2IconUrl?: InputMaybe<SortOrder>;
  step2Name?: InputMaybe<SortOrder>;
  step3Description?: InputMaybe<SortOrder>;
  step3IconUrl?: InputMaybe<SortOrder>;
  step3Name?: InputMaybe<SortOrder>;
  step4Description?: InputMaybe<SortOrder>;
  step4IconUrl?: InputMaybe<SortOrder>;
  step4Name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ParkingTypePagePaginatedResponse = {
  __typename?: 'ParkingTypePagePaginatedResponse';
  items: Array<ParkingTypePage>;
  pageInfo: PageInfo;
};

export type ParkingTypePageUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parkingTypeDescription?: InputMaybe<Scalars['String']['input']>;
  parkingTypeId?: InputMaybe<Scalars['String']['input']>;
  reason1?: InputMaybe<Scalars['String']['input']>;
  reason2?: InputMaybe<Scalars['String']['input']>;
  reason3?: InputMaybe<Scalars['String']['input']>;
  seoMetaDescription?: InputMaybe<Scalars['String']['input']>;
  seoMetaTitle?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  step1Description?: InputMaybe<Scalars['String']['input']>;
  step1IconUrl?: InputMaybe<Scalars['String']['input']>;
  step1Name?: InputMaybe<Scalars['String']['input']>;
  step2Description?: InputMaybe<Scalars['String']['input']>;
  step2IconUrl?: InputMaybe<Scalars['String']['input']>;
  step2Name?: InputMaybe<Scalars['String']['input']>;
  step3Description?: InputMaybe<Scalars['String']['input']>;
  step3IconUrl?: InputMaybe<Scalars['String']['input']>;
  step3Name?: InputMaybe<Scalars['String']['input']>;
  step4Description?: InputMaybe<Scalars['String']['input']>;
  step4IconUrl?: InputMaybe<Scalars['String']['input']>;
  step4Name?: InputMaybe<Scalars['String']['input']>;
};

export type ParkingTypePageWhereInput = {
  AND?: InputMaybe<Array<ParkingTypePageWhereInput>>;
  NOT?: InputMaybe<Array<ParkingTypePageWhereInput>>;
  OR?: InputMaybe<Array<ParkingTypePageWhereInput>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  parkingTypeDescription?: InputMaybe<StringFilterInput>;
  parkingTypeId?: InputMaybe<StringFilterInput>;
  reason1?: InputMaybe<StringFilterInput>;
  reason2?: InputMaybe<StringFilterInput>;
  reason3?: InputMaybe<StringFilterInput>;
  seoMetaDescription?: InputMaybe<StringFilterInput>;
  seoMetaTitle?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  step1Description?: InputMaybe<StringFilterInput>;
  step1IconUrl?: InputMaybe<StringFilterInput>;
  step1Name?: InputMaybe<StringFilterInput>;
  step2Description?: InputMaybe<StringFilterInput>;
  step2IconUrl?: InputMaybe<StringFilterInput>;
  step2Name?: InputMaybe<StringFilterInput>;
  step3Description?: InputMaybe<StringFilterInput>;
  step3IconUrl?: InputMaybe<StringFilterInput>;
  step3Name?: InputMaybe<StringFilterInput>;
  step4Description?: InputMaybe<StringFilterInput>;
  step4IconUrl?: InputMaybe<StringFilterInput>;
  step4Name?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ParkingTypePageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  parkingTypeId?: InputMaybe<Scalars['String']['input']>;
};

export type ParkingTypePaginatedResponse = {
  __typename?: 'ParkingTypePaginatedResponse';
  items: Array<ParkingType>;
  pageInfo: PageInfo;
};

export type ParkingTypeUpdateInput = {
  iconUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  serviceIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  serviceIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  serviceIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ParkingTypeWhereInput = {
  AND?: InputMaybe<Array<ParkingTypeWhereInput>>;
  NOT?: InputMaybe<Array<ParkingTypeWhereInput>>;
  OR?: InputMaybe<Array<ParkingTypeWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  iconUrl?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  services?: InputMaybe<ServiceListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ParkingTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ParkingUpdateInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  addressInstructions?: InputMaybe<Array<Scalars['String']['input']>>;
  businessEntityId?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  locationIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  mainLocationId?: InputMaybe<Scalars['String']['input']>;
  photos?: InputMaybe<Array<Scalars['String']['input']>>;
  procedure?: InputMaybe<Array<Scalars['String']['input']>>;
  providerIdConnect?: InputMaybe<Scalars['ID']['input']>;
  remarks?: InputMaybe<Array<Scalars['String']['input']>>;
  sectionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  sectionIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  sectionIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  travelDistanceToAirport?: InputMaybe<Scalars['Int']['input']>;
  travelTimeToAirport?: InputMaybe<Scalars['Int']['input']>;
};

export type ParkingWhereInput = {
  AND?: InputMaybe<Array<ParkingWhereInput>>;
  NOT?: InputMaybe<Array<ParkingWhereInput>>;
  OR?: InputMaybe<Array<ParkingWhereInput>>;
  additionalInformation?: InputMaybe<StringFilterInput>;
  addressInstructions?: InputMaybe<StringListFilterInput>;
  businessEntityId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<StringFilterInput>;
  locations?: InputMaybe<LocationListRelationFilter>;
  mainLocationId?: InputMaybe<StringFilterInput>;
  photos?: InputMaybe<StringListFilterInput>;
  procedure?: InputMaybe<StringListFilterInput>;
  provider?: InputMaybe<ProviderWhereInput>;
  providerId?: InputMaybe<StringFilterInput>;
  remarks?: InputMaybe<StringListFilterInput>;
  sections?: InputMaybe<SectionListRelationFilter>;
  travelDistanceToAirport?: InputMaybe<IntFilterInput>;
  travelTimeToAirport?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ParkingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sites?: Maybe<Array<Site>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PaymentMethodCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  siteIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PaymentMethodListRelationFilter = {
  every?: InputMaybe<PaymentMethodWhereInput>;
  none?: InputMaybe<PaymentMethodWhereInput>;
  some?: InputMaybe<PaymentMethodWhereInput>;
};

export type PaymentMethodOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PaymentMethodPaginatedResponse = {
  __typename?: 'PaymentMethodPaginatedResponse';
  items: Array<PaymentMethod>;
  pageInfo: PageInfo;
};

export type PaymentMethodUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  siteIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PaymentMethodWhereInput = {
  AND?: InputMaybe<Array<PaymentMethodWhereInput>>;
  NOT?: InputMaybe<Array<PaymentMethodWhereInput>>;
  OR?: InputMaybe<Array<PaymentMethodWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  sites?: InputMaybe<SiteListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type PaymentMethodWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum PaymentProvider {
  Hipay = 'HIPAY',
  Stripe = 'STRIPE',
}

export type PmsProvider = {
  __typename?: 'PmsProvider';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  productionApiKey?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Provider>;
  testApiKey?: Maybe<Scalars['String']['output']>;
  testProviderId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PmsProviderCreateInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  productionApiKey?: InputMaybe<Scalars['String']['input']>;
  providerIdConnect?: InputMaybe<Scalars['ID']['input']>;
  testApiKey?: InputMaybe<Scalars['String']['input']>;
  testProviderId?: InputMaybe<Scalars['String']['input']>;
};

export type PmsProviderListRelationFilter = {
  every?: InputMaybe<PmsProviderWhereInput>;
  none?: InputMaybe<PmsProviderWhereInput>;
  some?: InputMaybe<PmsProviderWhereInput>;
};

export type PmsProviderOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  productionApiKey?: InputMaybe<SortOrder>;
  provider?: InputMaybe<ProviderOrderByInput>;
  testApiKey?: InputMaybe<SortOrder>;
  testProviderId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PmsProviderPaginatedResponse = {
  __typename?: 'PmsProviderPaginatedResponse';
  items: Array<PmsProvider>;
  pageInfo: PageInfo;
};

export type PmsProviderUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  productionApiKey?: InputMaybe<Scalars['String']['input']>;
  providerIdConnect?: InputMaybe<Scalars['ID']['input']>;
  providerIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  testApiKey?: InputMaybe<Scalars['String']['input']>;
  testProviderId?: InputMaybe<Scalars['String']['input']>;
};

export type PmsProviderUser = {
  __typename?: 'PmsProviderUser';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  pmsProvider?: Maybe<PmsProvider>;
  pmsProviderId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<User>;
};

export type PmsProviderUserListRelationFilter = {
  every?: InputMaybe<PmsProviderUserWhereInput>;
  none?: InputMaybe<PmsProviderUserWhereInput>;
  some?: InputMaybe<PmsProviderUserWhereInput>;
};

export type PmsProviderUserWhereInput = {
  AND?: InputMaybe<Array<PmsProviderUserWhereInput>>;
  NOT?: InputMaybe<Array<PmsProviderUserWhereInput>>;
  OR?: InputMaybe<Array<PmsProviderUserWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  pmsProviderId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringFilterInput>;
};

export type PmsProviderUserWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PmsProviderWhereInput = {
  AND?: InputMaybe<Array<PmsProviderWhereInput>>;
  NOT?: InputMaybe<Array<PmsProviderWhereInput>>;
  OR?: InputMaybe<Array<PmsProviderWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  phone?: InputMaybe<StringFilterInput>;
  productionApiKey?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<ProviderWhereInput>;
  testApiKey?: InputMaybe<StringFilterInput>;
  testProviderId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type PmsProviderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PreUploadUrlInput = {
  entityName?: InputMaybe<Scalars['String']['input']>;
  fileName: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  objectTypeIdConnect: Scalars['ID']['input'];
  parkingName?: InputMaybe<Scalars['String']['input']>;
  providerName?: InputMaybe<Scalars['String']['input']>;
};

export type PreUploadUrlResponse = {
  __typename?: 'PreUploadUrlResponse';
  fileUrl: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type PriceList = {
  __typename?: 'PriceList';
  availableFrom: Scalars['Date']['output'];
  availableTo: Scalars['Date']['output'];
  createdAt: Scalars['Date']['output'];
  currency: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nonBookable: Scalars['Boolean']['output'];
  pricePerDurationMap?: Maybe<Scalars['JSON']['output']>;
  pricePerExtraDay: Scalars['Decimal']['output'];
  product?: Maybe<Product>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PriceListCreateInput = {
  availableFrom: Scalars['Date']['input'];
  availableTo: Scalars['Date']['input'];
  currency: Scalars['String']['input'];
  name: Scalars['String']['input'];
  nonBookable: Scalars['Boolean']['input'];
  pricePerDurationMap?: InputMaybe<Scalars['JSON']['input']>;
  pricePerExtraDay: Scalars['Decimal']['input'];
  productIdConnect: Scalars['ID']['input'];
};

export type PriceListListRelationFilter = {
  every?: InputMaybe<PriceListWhereInput>;
  none?: InputMaybe<PriceListWhereInput>;
  some?: InputMaybe<PriceListWhereInput>;
};

export type PriceListOrderByInput = {
  availableFrom?: InputMaybe<SortOrder>;
  availableTo?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  nonBookable?: InputMaybe<SortOrder>;
  pricePerDurationMap?: InputMaybe<SortOrder>;
  pricePerExtraDay?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByInput>;
  productId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PriceListPaginatedResponse = {
  __typename?: 'PriceListPaginatedResponse';
  items: Array<PriceList>;
  pageInfo: PageInfo;
};

export type PriceListUpdateInput = {
  availableFrom?: InputMaybe<Scalars['Date']['input']>;
  availableTo?: InputMaybe<Scalars['Date']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nonBookable?: InputMaybe<Scalars['Boolean']['input']>;
  pricePerDurationMap?: InputMaybe<Scalars['JSON']['input']>;
  pricePerExtraDay?: InputMaybe<Scalars['Decimal']['input']>;
  productIdConnect?: InputMaybe<Scalars['ID']['input']>;
};

export type PriceListWhereInput = {
  AND?: InputMaybe<Array<PriceListWhereInput>>;
  NOT?: InputMaybe<Array<PriceListWhereInput>>;
  OR?: InputMaybe<Array<PriceListWhereInput>>;
  availableFrom?: InputMaybe<DateFilterInput>;
  availableTo?: InputMaybe<DateFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  currency?: InputMaybe<StringFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  nonBookable?: InputMaybe<Scalars['Boolean']['input']>;
  pricePerDurationMap?: InputMaybe<JsonFilterInput>;
  pricePerExtraDay?: InputMaybe<DecimalFilterInput>;
  product?: InputMaybe<ProductWhereInput>;
  productId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type PriceListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum PriceModel {
  Fair = 'FAIR',
  StartDate = 'START_DATE',
  Static = 'STATIC',
}

export type PriceStrategy = {
  __typename?: 'PriceStrategy';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priceModel: PriceModel;
  priceType: PriceType;
  provider?: Maybe<Provider>;
  remark: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PriceStrategyCreateInput = {
  name: Scalars['String']['input'];
  priceModel: PriceModel;
  priceType: PriceType;
  providerIdConnect: Scalars['ID']['input'];
  remark: Scalars['String']['input'];
};

export type PriceStrategyListRelationFilter = {
  every?: InputMaybe<PriceStrategyWhereInput>;
  none?: InputMaybe<PriceStrategyWhereInput>;
  some?: InputMaybe<PriceStrategyWhereInput>;
};

export type PriceStrategyOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  provider?: InputMaybe<ProviderOrderByInput>;
  providerId?: InputMaybe<SortOrder>;
  remark?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PriceStrategyPaginatedResponse = {
  __typename?: 'PriceStrategyPaginatedResponse';
  items: Array<PriceStrategy>;
  pageInfo: PageInfo;
};

export type PriceStrategyUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  priceModel?: InputMaybe<PriceModel>;
  priceType?: InputMaybe<PriceType>;
  providerIdConnect?: InputMaybe<Scalars['ID']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
};

export type PriceStrategyWhereInput = {
  AND?: InputMaybe<Array<PriceStrategyWhereInput>>;
  NOT?: InputMaybe<Array<PriceStrategyWhereInput>>;
  OR?: InputMaybe<Array<PriceStrategyWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  priceModel?: InputMaybe<PriceModel>;
  priceType?: InputMaybe<PriceType>;
  provider?: InputMaybe<ProviderWhereInput>;
  providerId?: InputMaybe<StringFilterInput>;
  remark?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type PriceStrategyWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  providerId?: InputMaybe<Scalars['ID']['input']>;
};

export enum PriceType {
  CalendarDay = 'CALENDAR_DAY',
  CeilingDayCycle = 'CEILING_DAY_CYCLE',
  Hourly = 'HOURLY',
}

export type Product = {
  __typename?: 'Product';
  businessEntity?: Maybe<BusinessEntity>;
  businessEntityId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  parking?: Maybe<Parking>;
  parkingId: Scalars['String']['output'];
  priceLists?: Maybe<Array<PriceList>>;
  provider?: Maybe<Provider>;
  providerId: Scalars['String']['output'];
  service?: Maybe<Service>;
  serviceId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ProductCreateInput = {
  businessEntityId: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  parkingId: Scalars['String']['input'];
  priceListIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  providerId: Scalars['String']['input'];
  serviceId: Scalars['String']['input'];
};

export type ProductListRelationFilter = {
  every?: InputMaybe<ProductWhereInput>;
  none?: InputMaybe<ProductWhereInput>;
  some?: InputMaybe<ProductWhereInput>;
};

export type ProductOrderByInput = {
  businessEntityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  enabled?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  parkingId?: InputMaybe<SortOrder>;
  providerId?: InputMaybe<SortOrder>;
  serviceId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductPaginatedResponse = {
  __typename?: 'ProductPaginatedResponse';
  items: Array<Product>;
  pageInfo: PageInfo;
};

export type ProductUpdateInput = {
  businessEntityId?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  parkingId?: InputMaybe<Scalars['String']['input']>;
  priceListIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  priceListIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  priceListIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  serviceId?: InputMaybe<Scalars['String']['input']>;
};

export type ProductWhereInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  businessEntityId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<StringFilterInput>;
  parkingId?: InputMaybe<StringFilterInput>;
  priceLists?: InputMaybe<PriceListListRelationFilter>;
  providerId?: InputMaybe<StringFilterInput>;
  serviceId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Provider = {
  __typename?: 'Provider';
  airport?: Maybe<Airport>;
  airportId: Scalars['String']['output'];
  businessEntity?: Maybe<BusinessEntity>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  documents?: Maybe<Array<Document>>;
  enabled: Scalars['Boolean']['output'];
  hipayAccountId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoices?: Maybe<Array<Invoice>>;
  language?: Maybe<Language>;
  languageId: Scalars['String']['output'];
  location?: Maybe<Location>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  onboardingNotfications?: Maybe<Array<HipayOnboardingNotification>>;
  parkings?: Maybe<Array<Parking>>;
  paymentProvider?: Maybe<PaymentProvider>;
  paymentProviderId?: Maybe<Scalars['String']['output']>;
  paymentProviderSecretKey?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  pmsProvider?: Maybe<PmsProvider>;
  priceStrategy?: Maybe<PriceStrategy>;
  travelDistanceToAirport: Scalars['Int']['output'];
  travelTimeToAirport: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ProviderCreateInput = {
  airportId: Scalars['String']['input'];
  businessEntityIdConnect: Scalars['ID']['input'];
  documentIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  enabled: Scalars['Boolean']['input'];
  hipayAccountId?: InputMaybe<Scalars['String']['input']>;
  invoiceIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  languageId: Scalars['String']['input'];
  locationIdConnect?: InputMaybe<Scalars['ID']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  onboardingNotficationIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  parkingIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  paymentProvider?: InputMaybe<PaymentProvider>;
  paymentProviderId?: InputMaybe<Scalars['String']['input']>;
  paymentProviderSecretKey?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  pmsProviderIdConnect?: InputMaybe<Scalars['ID']['input']>;
  priceStrategyIdConnect?: InputMaybe<Scalars['ID']['input']>;
  travelDistanceToAirport: Scalars['Int']['input'];
  travelTimeToAirport: Scalars['Int']['input'];
};

export type ProviderListRelationFilter = {
  every?: InputMaybe<ProviderWhereInput>;
  none?: InputMaybe<ProviderWhereInput>;
  some?: InputMaybe<ProviderWhereInput>;
};

export type ProviderOrderByInput = {
  airportId?: InputMaybe<SortOrder>;
  businessEntity?: InputMaybe<BusinessEntityOrderByInput>;
  businessEntityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  enabled?: InputMaybe<SortOrder>;
  hipayAccountId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  languageId?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationOrderByInput>;
  logoUrl?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  paymentProviderId?: InputMaybe<SortOrder>;
  paymentProviderSecretKey?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  pmsProvider?: InputMaybe<PmsProviderOrderByInput>;
  pmsProviderId?: InputMaybe<SortOrder>;
  priceStrategy?: InputMaybe<PriceStrategyOrderByInput>;
  travelDistanceToAirport?: InputMaybe<SortOrder>;
  travelTimeToAirport?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProviderPaginatedResponse = {
  __typename?: 'ProviderPaginatedResponse';
  items: Array<Provider>;
  pageInfo: PageInfo;
};

export type ProviderUpdateInput = {
  airportId?: InputMaybe<Scalars['String']['input']>;
  businessEntityIdConnect?: InputMaybe<Scalars['ID']['input']>;
  documentIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  documentIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  documentIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  hipayAccountId?: InputMaybe<Scalars['String']['input']>;
  invoiceIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  languageId?: InputMaybe<Scalars['String']['input']>;
  locationIdConnect?: InputMaybe<Scalars['ID']['input']>;
  locationIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onboardingNotficationIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  onboardingNotficationIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  onboardingNotficationIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  parkingIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  parkingIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  parkingIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  paymentProvider?: InputMaybe<PaymentProvider>;
  paymentProviderId?: InputMaybe<Scalars['String']['input']>;
  paymentProviderSecretKey?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pmsProviderIdConnect?: InputMaybe<Scalars['ID']['input']>;
  pmsProviderIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  priceStrategyIdConnect?: InputMaybe<Scalars['ID']['input']>;
  priceStrategyIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  travelDistanceToAirport?: InputMaybe<Scalars['Int']['input']>;
  travelTimeToAirport?: InputMaybe<Scalars['Int']['input']>;
};

export type ProviderUser = {
  __typename?: 'ProviderUser';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  provider?: Maybe<Provider>;
  providerId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<User>;
};

export type ProviderUserListRelationFilter = {
  every?: InputMaybe<ProviderUserWhereInput>;
  none?: InputMaybe<ProviderUserWhereInput>;
  some?: InputMaybe<ProviderUserWhereInput>;
};

export type ProviderUserWhereInput = {
  AND?: InputMaybe<Array<ProviderUserWhereInput>>;
  NOT?: InputMaybe<Array<ProviderUserWhereInput>>;
  OR?: InputMaybe<Array<ProviderUserWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  providerId?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringFilterInput>;
};

export type ProviderUserWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ProviderWhereInput = {
  AND?: InputMaybe<Array<ProviderWhereInput>>;
  NOT?: InputMaybe<Array<ProviderWhereInput>>;
  OR?: InputMaybe<Array<ProviderWhereInput>>;
  airportId?: InputMaybe<StringFilterInput>;
  businessEntity?: InputMaybe<BusinessEntityWhereInput>;
  businessEntityId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  documents?: InputMaybe<DocumentListRelationFilter>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  hipayAccountId?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  invoices?: InputMaybe<InvoiceListRelationFilter>;
  languageId?: InputMaybe<StringFilterInput>;
  location?: InputMaybe<LocationWhereInput>;
  logoUrl?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  onboardingNotfications?: InputMaybe<HipayOnboardingNotificationListRelationFilter>;
  parkings?: InputMaybe<ParkingListRelationFilter>;
  paymentProvider?: InputMaybe<PaymentProvider>;
  paymentProviderId?: InputMaybe<StringFilterInput>;
  paymentProviderSecretKey?: InputMaybe<StringFilterInput>;
  phone?: InputMaybe<StringFilterInput>;
  pmsProvider?: InputMaybe<PmsProviderWhereInput>;
  pmsProviderId?: InputMaybe<StringFilterInput>;
  priceStrategy?: InputMaybe<PriceStrategyWhereInput>;
  travelDistanceToAirport?: InputMaybe<IntFilterInput>;
  travelTimeToAirport?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ProviderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  pmsProviderId?: InputMaybe<Scalars['ID']['input']>;
};

export type Query = {
  __typename?: 'Query';
  getAffiliateParkingBy: AffiliateParking;
  getAffiliateParkingById: AffiliateParking;
  getAffiliateParkingPageBy: AffiliateParkingPage;
  getAffiliateParkingPageById: AffiliateParkingPage;
  getAirportBy: Airport;
  getAirportById: Airport;
  getAirportPageBy: AirportPage;
  getAirportPageById: AirportPage;
  getAllAffiliateParkingPages: AffiliateParkingPagePaginatedResponse;
  getAllAffiliateParkings: AffiliateParkingPaginatedResponse;
  getAllAirportPages: AirportPagePaginatedResponse;
  getAllAirports: AirportPaginatedResponse;
  getAllBlogPages: BlogPagePaginatedResponse;
  getAllBookings: BookingPaginatedResponse;
  getAllBusinessEntities: BusinessEntityPaginatedResponse;
  getAllConfigurationLocations: ConfigurationLocationPaginatedResponse;
  getAllContactPoints: ContactPointPaginatedResponse;
  getAllCountries: CountryPaginatedResponse;
  getAllCurrencies: CurrencyPaginatedResponse;
  getAllDocuments: DocumentPaginatedResponse;
  getAllEmailTemplateAttachments: EmailTemplateAttachmentPaginatedResponse;
  getAllEmailTemplateParameters: EmailTemplateParameterPaginatedResponse;
  getAllEmailTemplates: EmailTemplatePaginatedResponse;
  getAllFaqPages: FaqPagePaginatedResponse;
  getAllFaqQuestionGroups: FaqQuestionGroupPaginatedResponse;
  getAllFaqQuestions: FaqQuestionPaginatedResponse;
  getAllFileObjectTypes: FileObjectTypePaginatedResponse;
  getAllFiles: FilePaginatedResponse;
  getAllGeneralPages: GeneralPagePaginatedResponse;
  getAllHipayOnboardingNotifications: HipayOnboardingNotificationPaginatedResponse;
  getAllInvoices: InvoicePaginatedResponse;
  getAllLanguages: LanguagePaginatedResponse;
  getAllLocations: LocationPaginatedResponse;
  getAllMobianPayments: MobianPaymentPaginatedResponse;
  getAllOptionCategories: OptionCategoryPaginatedResponse;
  getAllOptions: OptionPaginatedResponse;
  getAllParameters: ParameterPaginatedResponse;
  getAllParkingPages: ParkingPagePaginatedResponse;
  getAllParkingTypePages: ParkingTypePagePaginatedResponse;
  getAllParkingTypes: ParkingTypePaginatedResponse;
  getAllParkings: ParkingPaginatedResponse;
  getAllPaymentMethods: PaymentMethodPaginatedResponse;
  getAllPmsProviders: PmsProviderPaginatedResponse;
  getAllPriceLists: PriceListPaginatedResponse;
  getAllPriceStrategies: PriceStrategyPaginatedResponse;
  getAllProducts: ProductPaginatedResponse;
  getAllProviders: ProviderPaginatedResponse;
  getAllRedirects: RedirectPaginatedResponse;
  getAllRoles: RolePaginatedResponse;
  getAllSafeAndSecureLogos: SafeAndSecureLogoPaginatedResponse;
  getAllSectionTypes: SectionTypePaginatedResponse;
  getAllSections: SectionPaginatedResponse;
  getAllSentEmails: SentEmailPaginatedResponse;
  getAllServices: ServicePaginatedResponse;
  getAllSites: SitePaginatedResponse;
  getAllSocialMedias: SocialMediaPaginatedResponse;
  getAllSubdomains: SubdomainPaginatedResponse;
  getAllTerminals: TerminalPaginatedResponse;
  getAllTestLogs: TestLogPaginatedResponse;
  getAllTranslations: TranslationPaginatedResponse;
  getAllTrustMarkLogos: TrustMarkLogoPaginatedResponse;
  getAllUsers: UserPaginatedResponse;
  getBlogPageBy: BlogPage;
  getBlogPageById: BlogPage;
  getBookingBy: Booking;
  getBookingById: Booking;
  getBusinessEntityBy: BusinessEntity;
  getBusinessEntityById: BusinessEntity;
  getConfigurationLocationBy: ConfigurationLocation;
  getConfigurationLocationById: ConfigurationLocation;
  getContactPointBy: ContactPoint;
  getContactPointById: ContactPoint;
  getCountryBy: Country;
  getCountryById: Country;
  getCurrencyBy: Currency;
  getCurrencyById: Currency;
  getDocumentBy: Document;
  getDocumentById: Document;
  getEmailTemplateAttachmentBy: EmailTemplateAttachment;
  getEmailTemplateAttachmentById: EmailTemplateAttachment;
  getEmailTemplateBy: EmailTemplate;
  getEmailTemplateById: EmailTemplate;
  getEmailTemplateParameterBy: EmailTemplateParameter;
  getEmailTemplateParameterById: EmailTemplateParameter;
  getFaqPageBy: FaqPage;
  getFaqPageById: FaqPage;
  getFaqQuestionBy: FaqQuestion;
  getFaqQuestionById: FaqQuestion;
  getFaqQuestionGroupBy: FaqQuestionGroup;
  getFaqQuestionGroupById: FaqQuestionGroup;
  getFileBy: File;
  getFileById: File;
  getFileObjectTypeBy: FileObjectType;
  getFileObjectTypeById: FileObjectType;
  getGeneralPageBy: GeneralPage;
  getGeneralPageById: GeneralPage;
  getHipayOnboardingNotificationBy: HipayOnboardingNotification;
  getHipayOnboardingNotificationById: HipayOnboardingNotification;
  getInvoiceBy: Invoice;
  getInvoiceById: Invoice;
  getLanguageBy: Language;
  getLanguageById: Language;
  getLocationBy: Location;
  getLocationById: Location;
  getMobianPaymentBy: MobianPayment;
  getMobianPaymentById: MobianPayment;
  getOptionBy: Option;
  getOptionById: Option;
  getOptionCategoryBy: OptionCategory;
  getOptionCategoryById: OptionCategory;
  getParameterBy: Parameter;
  getParameterById: Parameter;
  getParkingBy: Parking;
  getParkingById: Parking;
  getParkingPageBy: ParkingPage;
  getParkingPageById: ParkingPage;
  getParkingTypeBy: ParkingType;
  getParkingTypeById: ParkingType;
  getParkingTypePageBy: ParkingTypePage;
  getParkingTypePageById: ParkingTypePage;
  getPaymentMethodBy: PaymentMethod;
  getPaymentMethodById: PaymentMethod;
  getPmsProviderBy: PmsProvider;
  getPmsProviderById: PmsProvider;
  getPriceListBy: PriceList;
  getPriceListById: PriceList;
  getPriceStrategyBy: PriceStrategy;
  getPriceStrategyById: PriceStrategy;
  getProductBy: Product;
  getProductById: Product;
  getProviderBy: Provider;
  getProviderById: Provider;
  getRedirectBy: Redirect;
  getRedirectById: Redirect;
  getRoleBy: Role;
  getRoleById: Role;
  getSafeAndSecureLogoBy: SafeAndSecureLogo;
  getSafeAndSecureLogoById: SafeAndSecureLogo;
  getSectionBy: Section;
  getSectionById: Section;
  getSectionTypeBy: SectionType;
  getSectionTypeById: SectionType;
  getSentEmailBy: SentEmail;
  getSentEmailById: SentEmail;
  getServiceBy: Service;
  getServiceById: Service;
  getSiteBy: Site;
  getSiteById: Site;
  getSitePopularAirportsBy: Array<Airport>;
  getSocialMediaBy: SocialMedia;
  getSocialMediaById: SocialMedia;
  getSubdomainBy: Subdomain;
  getSubdomainById: Subdomain;
  getTerminalBy: Terminal;
  getTerminalById: Terminal;
  getTestLogBy: TestLog;
  getTestLogById: TestLog;
  getTranslationBy: Translation;
  getTranslationById: Translation;
  getTrustMarkLogoBy: TrustMarkLogo;
  getTrustMarkLogoById: TrustMarkLogo;
  getUserBy: User;
  getUserById: User;
};

export type QueryGetAffiliateParkingByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: AffiliateParkingWhereUniqueInput;
};

export type QueryGetAffiliateParkingByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<AffiliateParkingWhereUniqueInput>;
};

export type QueryGetAffiliateParkingPageByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: AffiliateParkingPageWhereUniqueInput;
};

export type QueryGetAffiliateParkingPageByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<AffiliateParkingPageWhereUniqueInput>;
};

export type QueryGetAirportByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: AirportWhereUniqueInput;
};

export type QueryGetAirportByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<AirportWhereUniqueInput>;
};

export type QueryGetAirportPageByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: AirportPageWhereUniqueInput;
};

export type QueryGetAirportPageByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<AirportPageWhereUniqueInput>;
};

export type QueryGetAllAffiliateParkingPagesArgs = {
  orderBy?: InputMaybe<Array<AffiliateParkingPageOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AffiliateParkingPageWhereInput>;
};

export type QueryGetAllAffiliateParkingsArgs = {
  orderBy?: InputMaybe<Array<AffiliateParkingOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AffiliateParkingWhereInput>;
};

export type QueryGetAllAirportPagesArgs = {
  orderBy?: InputMaybe<Array<AirportPageOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AirportPageWhereInput>;
};

export type QueryGetAllAirportsArgs = {
  orderBy?: InputMaybe<Array<AirportOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AirportWhereInput>;
};

export type QueryGetAllBlogPagesArgs = {
  orderBy?: InputMaybe<Array<BlogPageOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlogPageWhereInput>;
};

export type QueryGetAllBookingsArgs = {
  orderBy?: InputMaybe<Array<BookingOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BookingWhereInput>;
};

export type QueryGetAllBusinessEntitiesArgs = {
  orderBy?: InputMaybe<Array<BusinessEntityOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BusinessEntityWhereInput>;
};

export type QueryGetAllConfigurationLocationsArgs = {
  orderBy?: InputMaybe<Array<ConfigurationLocationOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ConfigurationLocationWhereInput>;
};

export type QueryGetAllContactPointsArgs = {
  orderBy?: InputMaybe<Array<ContactPointOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactPointWhereInput>;
};

export type QueryGetAllCountriesArgs = {
  orderBy?: InputMaybe<Array<CountryOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryWhereInput>;
};

export type QueryGetAllCurrenciesArgs = {
  orderBy?: InputMaybe<Array<CurrencyOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CurrencyWhereInput>;
};

export type QueryGetAllDocumentsArgs = {
  orderBy?: InputMaybe<Array<DocumentOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DocumentWhereInput>;
};

export type QueryGetAllEmailTemplateAttachmentsArgs = {
  orderBy?: InputMaybe<Array<EmailTemplateAttachmentOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EmailTemplateAttachmentWhereInput>;
};

export type QueryGetAllEmailTemplateParametersArgs = {
  orderBy?: InputMaybe<Array<EmailTemplateParameterOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EmailTemplateParameterWhereInput>;
};

export type QueryGetAllEmailTemplatesArgs = {
  orderBy?: InputMaybe<Array<EmailTemplateOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EmailTemplateWhereInput>;
};

export type QueryGetAllFaqPagesArgs = {
  orderBy?: InputMaybe<Array<FaqPageOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqPageWhereInput>;
};

export type QueryGetAllFaqQuestionGroupsArgs = {
  orderBy?: InputMaybe<Array<FaqQuestionGroupOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqQuestionGroupWhereInput>;
};

export type QueryGetAllFaqQuestionsArgs = {
  orderBy?: InputMaybe<Array<FaqQuestionOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqQuestionWhereInput>;
};

export type QueryGetAllFileObjectTypesArgs = {
  orderBy?: InputMaybe<Array<FileObjectTypeOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileObjectTypeWhereInput>;
};

export type QueryGetAllFilesArgs = {
  orderBy?: InputMaybe<Array<FileOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileWhereInput>;
};

export type QueryGetAllGeneralPagesArgs = {
  orderBy?: InputMaybe<Array<GeneralPageOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GeneralPageWhereInput>;
};

export type QueryGetAllHipayOnboardingNotificationsArgs = {
  orderBy?: InputMaybe<Array<HipayOnboardingNotificationOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HipayOnboardingNotificationWhereInput>;
};

export type QueryGetAllInvoicesArgs = {
  orderBy?: InputMaybe<Array<InvoiceOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvoiceWhereInput>;
};

export type QueryGetAllLanguagesArgs = {
  orderBy?: InputMaybe<Array<LanguageOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LanguageWhereInput>;
};

export type QueryGetAllLocationsArgs = {
  orderBy?: InputMaybe<Array<LocationOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LocationWhereInput>;
};

export type QueryGetAllMobianPaymentsArgs = {
  orderBy?: InputMaybe<Array<MobianPaymentOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MobianPaymentWhereInput>;
};

export type QueryGetAllOptionCategoriesArgs = {
  orderBy?: InputMaybe<Array<OptionCategoryOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OptionCategoryWhereInput>;
};

export type QueryGetAllOptionsArgs = {
  orderBy?: InputMaybe<Array<OptionOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OptionWhereInput>;
};

export type QueryGetAllParametersArgs = {
  orderBy?: InputMaybe<Array<ParameterOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParameterWhereInput>;
};

export type QueryGetAllParkingPagesArgs = {
  orderBy?: InputMaybe<Array<ParkingPageOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParkingPageWhereInput>;
};

export type QueryGetAllParkingTypePagesArgs = {
  orderBy?: InputMaybe<Array<ParkingTypePageOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParkingTypePageWhereInput>;
};

export type QueryGetAllParkingTypesArgs = {
  orderBy?: InputMaybe<Array<ParkingTypeOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParkingTypeWhereInput>;
};

export type QueryGetAllParkingsArgs = {
  orderBy?: InputMaybe<Array<ParkingOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParkingWhereInput>;
};

export type QueryGetAllPaymentMethodsArgs = {
  orderBy?: InputMaybe<Array<PaymentMethodOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentMethodWhereInput>;
};

export type QueryGetAllPmsProvidersArgs = {
  orderBy?: InputMaybe<Array<PmsProviderOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PmsProviderWhereInput>;
};

export type QueryGetAllPriceListsArgs = {
  orderBy?: InputMaybe<Array<PriceListOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PriceListWhereInput>;
};

export type QueryGetAllPriceStrategiesArgs = {
  orderBy?: InputMaybe<Array<PriceStrategyOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PriceStrategyWhereInput>;
};

export type QueryGetAllProductsArgs = {
  orderBy?: InputMaybe<Array<ProductOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductWhereInput>;
};

export type QueryGetAllProvidersArgs = {
  orderBy?: InputMaybe<Array<ProviderOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProviderWhereInput>;
};

export type QueryGetAllRedirectsArgs = {
  orderBy?: InputMaybe<Array<RedirectOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RedirectWhereInput>;
};

export type QueryGetAllRolesArgs = {
  orderBy?: InputMaybe<Array<RoleOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RoleWhereInput>;
};

export type QueryGetAllSafeAndSecureLogosArgs = {
  orderBy?: InputMaybe<Array<SafeAndSecureLogoOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SafeAndSecureLogoWhereInput>;
};

export type QueryGetAllSectionTypesArgs = {
  orderBy?: InputMaybe<Array<SectionTypeOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SectionTypeWhereInput>;
};

export type QueryGetAllSectionsArgs = {
  orderBy?: InputMaybe<Array<SectionOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SectionWhereInput>;
};

export type QueryGetAllSentEmailsArgs = {
  orderBy?: InputMaybe<Array<SentEmailOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SentEmailWhereInput>;
};

export type QueryGetAllServicesArgs = {
  orderBy?: InputMaybe<Array<ServiceOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiceWhereInput>;
};

export type QueryGetAllSitesArgs = {
  orderBy?: InputMaybe<Array<SiteOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SiteWhereInput>;
};

export type QueryGetAllSocialMediasArgs = {
  orderBy?: InputMaybe<Array<SocialMediaOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SocialMediaWhereInput>;
};

export type QueryGetAllSubdomainsArgs = {
  orderBy?: InputMaybe<Array<SubdomainOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubdomainWhereInput>;
};

export type QueryGetAllTerminalsArgs = {
  orderBy?: InputMaybe<Array<TerminalOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TerminalWhereInput>;
};

export type QueryGetAllTestLogsArgs = {
  orderBy?: InputMaybe<Array<TestLogOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TestLogWhereInput>;
};

export type QueryGetAllTranslationsArgs = {
  orderBy?: InputMaybe<Array<TranslationOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TranslationWhereInput>;
};

export type QueryGetAllTrustMarkLogosArgs = {
  orderBy?: InputMaybe<Array<TrustMarkLogoOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TrustMarkLogoWhereInput>;
};

export type QueryGetAllUsersArgs = {
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryGetBlogPageByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: BlogPageWhereUniqueInput;
};

export type QueryGetBlogPageByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<BlogPageWhereUniqueInput>;
};

export type QueryGetBookingByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: BookingWhereUniqueInput;
};

export type QueryGetBookingByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<BookingWhereUniqueInput>;
};

export type QueryGetBusinessEntityByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: BusinessEntityWhereUniqueInput;
};

export type QueryGetBusinessEntityByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<BusinessEntityWhereUniqueInput>;
};

export type QueryGetConfigurationLocationByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: ConfigurationLocationWhereUniqueInput;
};

export type QueryGetConfigurationLocationByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<ConfigurationLocationWhereUniqueInput>;
};

export type QueryGetContactPointByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: ContactPointWhereUniqueInput;
};

export type QueryGetContactPointByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<ContactPointWhereUniqueInput>;
};

export type QueryGetCountryByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: CountryWhereUniqueInput;
};

export type QueryGetCountryByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<CountryWhereUniqueInput>;
};

export type QueryGetCurrencyByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: CurrencyWhereUniqueInput;
};

export type QueryGetCurrencyByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<CurrencyWhereUniqueInput>;
};

export type QueryGetDocumentByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: DocumentWhereUniqueInput;
};

export type QueryGetDocumentByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<DocumentWhereUniqueInput>;
};

export type QueryGetEmailTemplateAttachmentByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: EmailTemplateAttachmentWhereUniqueInput;
};

export type QueryGetEmailTemplateAttachmentByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<EmailTemplateAttachmentWhereUniqueInput>;
};

export type QueryGetEmailTemplateByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: EmailTemplateWhereUniqueInput;
};

export type QueryGetEmailTemplateByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<EmailTemplateWhereUniqueInput>;
};

export type QueryGetEmailTemplateParameterByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: EmailTemplateParameterWhereUniqueInput;
};

export type QueryGetEmailTemplateParameterByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<EmailTemplateParameterWhereUniqueInput>;
};

export type QueryGetFaqPageByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: FaqPageWhereUniqueInput;
};

export type QueryGetFaqPageByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<FaqPageWhereUniqueInput>;
};

export type QueryGetFaqQuestionByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: FaqQuestionWhereUniqueInput;
};

export type QueryGetFaqQuestionByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<FaqQuestionWhereUniqueInput>;
};

export type QueryGetFaqQuestionGroupByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: FaqQuestionGroupWhereUniqueInput;
};

export type QueryGetFaqQuestionGroupByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<FaqQuestionGroupWhereUniqueInput>;
};

export type QueryGetFileByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: FileWhereUniqueInput;
};

export type QueryGetFileByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<FileWhereUniqueInput>;
};

export type QueryGetFileObjectTypeByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: FileObjectTypeWhereUniqueInput;
};

export type QueryGetFileObjectTypeByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<FileObjectTypeWhereUniqueInput>;
};

export type QueryGetGeneralPageByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: GeneralPageWhereUniqueInput;
};

export type QueryGetGeneralPageByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<GeneralPageWhereUniqueInput>;
};

export type QueryGetHipayOnboardingNotificationByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: HipayOnboardingNotificationWhereUniqueInput;
};

export type QueryGetHipayOnboardingNotificationByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<HipayOnboardingNotificationWhereUniqueInput>;
};

export type QueryGetInvoiceByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: InvoiceWhereUniqueInput;
};

export type QueryGetInvoiceByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<InvoiceWhereUniqueInput>;
};

export type QueryGetLanguageByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: LanguageWhereUniqueInput;
};

export type QueryGetLanguageByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<LanguageWhereUniqueInput>;
};

export type QueryGetLocationByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: LocationWhereUniqueInput;
};

export type QueryGetLocationByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<LocationWhereUniqueInput>;
};

export type QueryGetMobianPaymentByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: MobianPaymentWhereUniqueInput;
};

export type QueryGetMobianPaymentByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<MobianPaymentWhereUniqueInput>;
};

export type QueryGetOptionByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: OptionWhereUniqueInput;
};

export type QueryGetOptionByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<OptionWhereUniqueInput>;
};

export type QueryGetOptionCategoryByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: OptionCategoryWhereUniqueInput;
};

export type QueryGetOptionCategoryByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<OptionCategoryWhereUniqueInput>;
};

export type QueryGetParameterByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: ParameterWhereUniqueInput;
};

export type QueryGetParameterByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<ParameterWhereUniqueInput>;
};

export type QueryGetParkingByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: ParkingWhereUniqueInput;
};

export type QueryGetParkingByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<ParkingWhereUniqueInput>;
};

export type QueryGetParkingPageByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: ParkingPageWhereUniqueInput;
};

export type QueryGetParkingPageByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<ParkingPageWhereUniqueInput>;
};

export type QueryGetParkingTypeByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: ParkingTypeWhereUniqueInput;
};

export type QueryGetParkingTypeByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<ParkingTypeWhereUniqueInput>;
};

export type QueryGetParkingTypePageByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: ParkingTypePageWhereUniqueInput;
};

export type QueryGetParkingTypePageByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<ParkingTypePageWhereUniqueInput>;
};

export type QueryGetPaymentMethodByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: PaymentMethodWhereUniqueInput;
};

export type QueryGetPaymentMethodByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<PaymentMethodWhereUniqueInput>;
};

export type QueryGetPmsProviderByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: PmsProviderWhereUniqueInput;
};

export type QueryGetPmsProviderByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<PmsProviderWhereUniqueInput>;
};

export type QueryGetPriceListByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: PriceListWhereUniqueInput;
};

export type QueryGetPriceListByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<PriceListWhereUniqueInput>;
};

export type QueryGetPriceStrategyByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: PriceStrategyWhereUniqueInput;
};

export type QueryGetPriceStrategyByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<PriceStrategyWhereUniqueInput>;
};

export type QueryGetProductByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: ProductWhereUniqueInput;
};

export type QueryGetProductByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<ProductWhereUniqueInput>;
};

export type QueryGetProviderByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: ProviderWhereUniqueInput;
};

export type QueryGetProviderByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<ProviderWhereUniqueInput>;
};

export type QueryGetRedirectByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: RedirectWhereUniqueInput;
};

export type QueryGetRedirectByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<RedirectWhereUniqueInput>;
};

export type QueryGetRoleByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: RoleWhereUniqueInput;
};

export type QueryGetRoleByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<RoleWhereUniqueInput>;
};

export type QueryGetSafeAndSecureLogoByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: SafeAndSecureLogoWhereUniqueInput;
};

export type QueryGetSafeAndSecureLogoByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<SafeAndSecureLogoWhereUniqueInput>;
};

export type QueryGetSectionByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: SectionWhereUniqueInput;
};

export type QueryGetSectionByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<SectionWhereUniqueInput>;
};

export type QueryGetSectionTypeByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: SectionTypeWhereUniqueInput;
};

export type QueryGetSectionTypeByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<SectionTypeWhereUniqueInput>;
};

export type QueryGetSentEmailByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: SentEmailWhereUniqueInput;
};

export type QueryGetSentEmailByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<SentEmailWhereUniqueInput>;
};

export type QueryGetServiceByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: ServiceWhereUniqueInput;
};

export type QueryGetServiceByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<ServiceWhereUniqueInput>;
};

export type QueryGetSiteByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: SiteWhereUniqueInput;
};

export type QueryGetSiteByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<SiteWhereUniqueInput>;
};

export type QueryGetSitePopularAirportsByArgs = {
  orderBy?: InputMaybe<Array<SiteOrderByInput>>;
  queryOptions?: InputMaybe<QueryOptions>;
  where: SiteWhereUniqueInput;
};

export type QueryGetSocialMediaByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: SocialMediaWhereUniqueInput;
};

export type QueryGetSocialMediaByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<SocialMediaWhereUniqueInput>;
};

export type QueryGetSubdomainByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: SubdomainWhereUniqueInput;
};

export type QueryGetSubdomainByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<SubdomainWhereUniqueInput>;
};

export type QueryGetTerminalByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: TerminalWhereUniqueInput;
};

export type QueryGetTerminalByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<TerminalWhereUniqueInput>;
};

export type QueryGetTestLogByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: TestLogWhereUniqueInput;
};

export type QueryGetTestLogByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<TestLogWhereUniqueInput>;
};

export type QueryGetTranslationByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: TranslationWhereUniqueInput;
};

export type QueryGetTranslationByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<TranslationWhereUniqueInput>;
};

export type QueryGetTrustMarkLogoByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: TrustMarkLogoWhereUniqueInput;
};

export type QueryGetTrustMarkLogoByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<TrustMarkLogoWhereUniqueInput>;
};

export type QueryGetUserByArgs = {
  queryOptions?: InputMaybe<QueryOptions>;
  where: UserWhereUniqueInput;
};

export type QueryGetUserByIdArgs = {
  id: Scalars['ID']['input'];
  queryOptions?: InputMaybe<QueryOptions>;
  where?: InputMaybe<UserWhereUniqueInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive',
}

export type QueryOptions = {
  includeExternalData?: InputMaybe<Scalars['Boolean']['input']>;
  includeSoftDeletedData?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Redirect = {
  __typename?: 'Redirect';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  fromSite?: Maybe<Site>;
  fromSitePath: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  redirectCode: Scalars['Int']['output'];
  toSite?: Maybe<Site>;
  toSitePath: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type RedirectCreateInput = {
  fromSiteIdConnect: Scalars['ID']['input'];
  fromSitePath: Scalars['String']['input'];
  redirectCode: Scalars['Int']['input'];
  toSiteIdConnect: Scalars['ID']['input'];
  toSitePath: Scalars['String']['input'];
};

export type RedirectListRelationFilter = {
  every?: InputMaybe<RedirectWhereInput>;
  none?: InputMaybe<RedirectWhereInput>;
  some?: InputMaybe<RedirectWhereInput>;
};

export type RedirectOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  fromSite?: InputMaybe<SiteOrderByInput>;
  fromSiteId?: InputMaybe<SortOrder>;
  fromSitePath?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  redirectCode?: InputMaybe<SortOrder>;
  toSite?: InputMaybe<SiteOrderByInput>;
  toSiteId?: InputMaybe<SortOrder>;
  toSitePath?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RedirectPaginatedResponse = {
  __typename?: 'RedirectPaginatedResponse';
  items: Array<Redirect>;
  pageInfo: PageInfo;
};

export type RedirectUpdateInput = {
  fromSiteIdConnect?: InputMaybe<Scalars['ID']['input']>;
  fromSitePath?: InputMaybe<Scalars['String']['input']>;
  redirectCode?: InputMaybe<Scalars['Int']['input']>;
  toSiteIdConnect?: InputMaybe<Scalars['ID']['input']>;
  toSitePath?: InputMaybe<Scalars['String']['input']>;
};

export type RedirectWhereInput = {
  AND?: InputMaybe<Array<RedirectWhereInput>>;
  NOT?: InputMaybe<Array<RedirectWhereInput>>;
  OR?: InputMaybe<Array<RedirectWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  fromSite?: InputMaybe<SiteWhereInput>;
  fromSiteId?: InputMaybe<StringFilterInput>;
  fromSitePath?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  redirectCode?: InputMaybe<IntFilterInput>;
  toSite?: InputMaybe<SiteWhereInput>;
  toSiteId?: InputMaybe<StringFilterInput>;
  toSitePath?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type RedirectWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  newAccessToken: Scalars['String']['output'];
  newRefreshToken: Scalars['String']['output'];
};

export type RejectConsentPayload = {
  __typename?: 'RejectConsentPayload';
  redirect_to: Scalars['String']['output'];
};

export type Role = {
  __typename?: 'Role';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  users?: Maybe<Array<User>>;
};

export type RoleCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  userIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type RoleListRelationFilter = {
  every?: InputMaybe<RoleWhereInput>;
  none?: InputMaybe<RoleWhereInput>;
  some?: InputMaybe<RoleWhereInput>;
};

export type RoleOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RolePaginatedResponse = {
  __typename?: 'RolePaginatedResponse';
  items: Array<Role>;
  pageInfo: PageInfo;
};

export type RoleUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  userIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  userIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  userIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type RoleWhereInput = {
  AND?: InputMaybe<Array<RoleWhereInput>>;
  NOT?: InputMaybe<Array<RoleWhereInput>>;
  OR?: InputMaybe<Array<RoleWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type RoleWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SafeAndSecureLogo = {
  __typename?: 'SafeAndSecureLogo';
  alt: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sites?: Maybe<Array<Site>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  url: Scalars['String']['output'];
};

export type SafeAndSecureLogoCreateInput = {
  alt: Scalars['String']['input'];
  name: Scalars['String']['input'];
  siteIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  url: Scalars['String']['input'];
};

export type SafeAndSecureLogoListRelationFilter = {
  every?: InputMaybe<SafeAndSecureLogoWhereInput>;
  none?: InputMaybe<SafeAndSecureLogoWhereInput>;
  some?: InputMaybe<SafeAndSecureLogoWhereInput>;
};

export type SafeAndSecureLogoOrderByInput = {
  alt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type SafeAndSecureLogoPaginatedResponse = {
  __typename?: 'SafeAndSecureLogoPaginatedResponse';
  items: Array<SafeAndSecureLogo>;
  pageInfo: PageInfo;
};

export type SafeAndSecureLogoUpdateInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  siteIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type SafeAndSecureLogoWhereInput = {
  AND?: InputMaybe<Array<SafeAndSecureLogoWhereInput>>;
  NOT?: InputMaybe<Array<SafeAndSecureLogoWhereInput>>;
  OR?: InputMaybe<Array<SafeAndSecureLogoWhereInput>>;
  alt?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  sites?: InputMaybe<SiteListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
  url?: InputMaybe<StringFilterInput>;
};

export type SafeAndSecureLogoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SafeUser = {
  __typename?: 'SafeUser';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  language?: Maybe<Language>;
  languageId: Scalars['String']['output'];
  roles?: Maybe<Array<Role>>;
};

export type Section = {
  __typename?: 'Section';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nrOfSpots: Scalars['Int']['output'];
  parking?: Maybe<Parking>;
  remarks: Scalars['String']['output'];
  serviceSections?: Maybe<Array<SectionServiceSection>>;
  type?: Maybe<SectionType>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SectionCreateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nrOfSpots: Scalars['Int']['input'];
  parkingIdConnect: Scalars['ID']['input'];
  remarks: Scalars['String']['input'];
  serviceSectionsCreate?: InputMaybe<Array<SectionServiceSectionCreateInput>>;
  typeIdConnect: Scalars['ID']['input'];
};

export type SectionListRelationFilter = {
  every?: InputMaybe<SectionWhereInput>;
  none?: InputMaybe<SectionWhereInput>;
  some?: InputMaybe<SectionWhereInput>;
};

export type SectionOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  nrOfSpots?: InputMaybe<SortOrder>;
  parking?: InputMaybe<ParkingOrderByInput>;
  parkingId?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
  type?: InputMaybe<SectionTypeOrderByInput>;
  typeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SectionPaginatedResponse = {
  __typename?: 'SectionPaginatedResponse';
  items: Array<Section>;
  pageInfo: PageInfo;
};

export type SectionServiceSection = {
  __typename?: 'SectionServiceSection';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  nrOfAvailableSpots: Scalars['Int']['output'];
  service?: Maybe<Service>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SectionServiceSectionCreateInput = {
  nrOfAvailableSpots: Scalars['Int']['input'];
  serviceId: Scalars['ID']['input'];
};

export type SectionServiceSectionDeleteInput = {
  serviceId: Scalars['ID']['input'];
};

export type SectionType = {
  __typename?: 'SectionType';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  iconUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sections?: Maybe<Array<Section>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SectionTypeCreateInput = {
  iconUrl: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  sectionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SectionTypeListRelationFilter = {
  every?: InputMaybe<SectionTypeWhereInput>;
  none?: InputMaybe<SectionTypeWhereInput>;
  some?: InputMaybe<SectionTypeWhereInput>;
};

export type SectionTypeOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  iconUrl?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SectionTypePaginatedResponse = {
  __typename?: 'SectionTypePaginatedResponse';
  items: Array<SectionType>;
  pageInfo: PageInfo;
};

export type SectionTypeUpdateInput = {
  iconUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sectionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  sectionIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  sectionIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SectionTypeWhereInput = {
  AND?: InputMaybe<Array<SectionTypeWhereInput>>;
  NOT?: InputMaybe<Array<SectionTypeWhereInput>>;
  OR?: InputMaybe<Array<SectionTypeWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  iconUrl?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  sections?: InputMaybe<SectionListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SectionTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SectionUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nrOfSpots?: InputMaybe<Scalars['Int']['input']>;
  parkingIdConnect?: InputMaybe<Scalars['ID']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  serviceSectionsDelete?: InputMaybe<Array<SectionServiceSectionDeleteInput>>;
  serviceSectionsReplace?: InputMaybe<Array<SectionServiceSectionCreateInput>>;
  serviceSectionsUpsert?: InputMaybe<Array<SectionServiceSectionCreateInput>>;
  typeIdConnect?: InputMaybe<Scalars['ID']['input']>;
};

export type SectionWhereInput = {
  AND?: InputMaybe<Array<SectionWhereInput>>;
  NOT?: InputMaybe<Array<SectionWhereInput>>;
  OR?: InputMaybe<Array<SectionWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  nrOfSpots?: InputMaybe<IntFilterInput>;
  parking?: InputMaybe<ParkingWhereInput>;
  parkingId?: InputMaybe<StringFilterInput>;
  remarks?: InputMaybe<StringFilterInput>;
  serviceSections?: InputMaybe<ServiceSectionListRelationFilter>;
  type?: InputMaybe<SectionTypeWhereInput>;
  typeId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SendEmailInput = {
  bcc: Scalars['String']['input'];
  dynamicData: Scalars['JSON']['input'];
  receiver: Scalars['String']['input'];
  templateId: Scalars['String']['input'];
};

export type SendEmailResponse = {
  __typename?: 'SendEmailResponse';
  message?: Maybe<Scalars['String']['output']>;
  sentEmail?: Maybe<SentEmail>;
  success: Scalars['Boolean']['output'];
};

export type SentEmail = {
  __typename?: 'SentEmail';
  attachments?: Maybe<Array<File>>;
  bcc?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  receiver: Scalars['String']['output'];
  sender: Scalars['String']['output'];
  sentAt?: Maybe<Scalars['Date']['output']>;
  status: SentEmailStatus;
  subject: Scalars['String']['output'];
  template?: Maybe<EmailTemplate>;
};

export type SentEmailCreateInput = {
  attachmentIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  bcc?: InputMaybe<Scalars['String']['input']>;
  receiver: Scalars['String']['input'];
  sender: Scalars['String']['input'];
  sentAt?: InputMaybe<Scalars['Date']['input']>;
  status: SentEmailStatus;
  subject: Scalars['String']['input'];
  templateIdConnect?: InputMaybe<Scalars['ID']['input']>;
};

export type SentEmailListRelationFilter = {
  every?: InputMaybe<SentEmailWhereInput>;
  none?: InputMaybe<SentEmailWhereInput>;
  some?: InputMaybe<SentEmailWhereInput>;
};

export type SentEmailOrderByInput = {
  bcc?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  receiver?: InputMaybe<SortOrder>;
  sender?: InputMaybe<SortOrder>;
  sentAt?: InputMaybe<SortOrder>;
  subject?: InputMaybe<SortOrder>;
  template?: InputMaybe<EmailTemplateOrderByInput>;
  templateId?: InputMaybe<SortOrder>;
};

export type SentEmailPaginatedResponse = {
  __typename?: 'SentEmailPaginatedResponse';
  items: Array<SentEmail>;
  pageInfo: PageInfo;
};

export enum SentEmailStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Success = 'SUCCESS',
}

export type SentEmailUpdateInput = {
  attachmentIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  attachmentIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  attachmentIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  bcc?: InputMaybe<Scalars['String']['input']>;
  receiver?: InputMaybe<Scalars['String']['input']>;
  sender?: InputMaybe<Scalars['String']['input']>;
  sentAt?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<SentEmailStatus>;
  subject?: InputMaybe<Scalars['String']['input']>;
  templateIdConnect?: InputMaybe<Scalars['ID']['input']>;
  templateIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
};

export type SentEmailWhereInput = {
  AND?: InputMaybe<Array<SentEmailWhereInput>>;
  NOT?: InputMaybe<Array<SentEmailWhereInput>>;
  OR?: InputMaybe<Array<SentEmailWhereInput>>;
  attachments?: InputMaybe<FileListRelationFilter>;
  bcc?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  receiver?: InputMaybe<StringFilterInput>;
  sender?: InputMaybe<StringFilterInput>;
  sentAt?: InputMaybe<DateFilterInput>;
  status?: InputMaybe<SentEmailStatus>;
  subject?: InputMaybe<StringFilterInput>;
  template?: InputMaybe<EmailTemplateWhereInput>;
  templateId?: InputMaybe<StringFilterInput>;
};

export type SentEmailWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Service = {
  __typename?: 'Service';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isExternal: Scalars['Boolean']['output'];
  openingHours: Scalars['String']['output'];
  parkingId: Scalars['String']['output'];
  parkingType?: Maybe<ParkingType>;
  providerId: Scalars['String']['output'];
  remarks: Scalars['String']['output'];
  serviceOptions?: Maybe<Array<ServiceServiceOption>>;
  serviceSections?: Maybe<Array<ServiceServiceSection>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  vendorId: Scalars['String']['output'];
};

export type ServiceCreateInput = {
  isExternal: Scalars['Boolean']['input'];
  openingHours: Scalars['String']['input'];
  parkingId: Scalars['String']['input'];
  parkingTypeIdConnect: Scalars['ID']['input'];
  providerId: Scalars['String']['input'];
  remarks: Scalars['String']['input'];
  serviceOptionsCreate?: InputMaybe<Array<ServiceServiceOptionCreateInput>>;
  serviceSectionsCreate?: InputMaybe<Array<ServiceServiceSectionCreateInput>>;
  vendorId: Scalars['String']['input'];
};

export type ServiceListRelationFilter = {
  every?: InputMaybe<ServiceWhereInput>;
  none?: InputMaybe<ServiceWhereInput>;
  some?: InputMaybe<ServiceWhereInput>;
};

export type ServiceOption = {
  __typename?: 'ServiceOption';
  billLineDescription?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isFree: Scalars['Boolean']['output'];
  option?: Maybe<Option>;
  payOnLocation: Scalars['Boolean']['output'];
  price: Scalars['Decimal']['output'];
  remark: Scalars['String']['output'];
  service?: Maybe<Service>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ServiceOptionListRelationFilter = {
  every?: InputMaybe<ServiceOptionWhereInput>;
  none?: InputMaybe<ServiceOptionWhereInput>;
  some?: InputMaybe<ServiceOptionWhereInput>;
};

export type ServiceOptionWhereInput = {
  AND?: InputMaybe<Array<ServiceOptionWhereInput>>;
  NOT?: InputMaybe<Array<ServiceOptionWhereInput>>;
  OR?: InputMaybe<Array<ServiceOptionWhereInput>>;
  billLineDescription?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  option?: InputMaybe<OptionWhereInput>;
  optionId?: InputMaybe<StringFilterInput>;
  payOnLocation?: InputMaybe<Scalars['Boolean']['input']>;
  price?: InputMaybe<DecimalFilterInput>;
  remark?: InputMaybe<StringFilterInput>;
  service?: InputMaybe<ServiceWhereInput>;
  serviceId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ServiceOptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ServiceOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isExternal?: InputMaybe<SortOrder>;
  openingHours?: InputMaybe<SortOrder>;
  parkingId?: InputMaybe<SortOrder>;
  parkingType?: InputMaybe<ParkingTypeOrderByInput>;
  parkingTypeId?: InputMaybe<SortOrder>;
  providerId?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  vendorId?: InputMaybe<SortOrder>;
};

export type ServicePaginatedResponse = {
  __typename?: 'ServicePaginatedResponse';
  items: Array<Service>;
  pageInfo: PageInfo;
};

export type ServiceSection = {
  __typename?: 'ServiceSection';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  nrOfAvailableSpots: Scalars['Int']['output'];
  section?: Maybe<Section>;
  service?: Maybe<Service>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ServiceSectionListRelationFilter = {
  every?: InputMaybe<ServiceSectionWhereInput>;
  none?: InputMaybe<ServiceSectionWhereInput>;
  some?: InputMaybe<ServiceSectionWhereInput>;
};

export type ServiceSectionWhereInput = {
  AND?: InputMaybe<Array<ServiceSectionWhereInput>>;
  NOT?: InputMaybe<Array<ServiceSectionWhereInput>>;
  OR?: InputMaybe<Array<ServiceSectionWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  nrOfAvailableSpots?: InputMaybe<IntFilterInput>;
  section?: InputMaybe<SectionWhereInput>;
  sectionId?: InputMaybe<StringFilterInput>;
  service?: InputMaybe<ServiceWhereInput>;
  serviceId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ServiceSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ServiceServiceOption = {
  __typename?: 'ServiceServiceOption';
  billLineDescription?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isFree: Scalars['Boolean']['output'];
  option?: Maybe<Option>;
  payOnLocation: Scalars['Boolean']['output'];
  price: Scalars['Decimal']['output'];
  remark: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ServiceServiceOptionCreateInput = {
  billLineDescription?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isFree: Scalars['Boolean']['input'];
  optionId: Scalars['ID']['input'];
  payOnLocation: Scalars['Boolean']['input'];
  price: Scalars['Decimal']['input'];
  remark: Scalars['String']['input'];
};

export type ServiceServiceOptionDeleteInput = {
  optionId: Scalars['ID']['input'];
};

export type ServiceServiceSection = {
  __typename?: 'ServiceServiceSection';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  nrOfAvailableSpots: Scalars['Int']['output'];
  section?: Maybe<Section>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ServiceServiceSectionCreateInput = {
  nrOfAvailableSpots: Scalars['Int']['input'];
  sectionId: Scalars['ID']['input'];
};

export type ServiceServiceSectionDeleteInput = {
  sectionId: Scalars['ID']['input'];
};

export type ServiceUpdateInput = {
  isExternal?: InputMaybe<Scalars['Boolean']['input']>;
  openingHours?: InputMaybe<Scalars['String']['input']>;
  parkingId?: InputMaybe<Scalars['String']['input']>;
  parkingTypeIdConnect?: InputMaybe<Scalars['ID']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  serviceOptionsDelete?: InputMaybe<Array<ServiceServiceOptionDeleteInput>>;
  serviceOptionsReplace?: InputMaybe<Array<ServiceServiceOptionCreateInput>>;
  serviceOptionsUpsert?: InputMaybe<Array<ServiceServiceOptionCreateInput>>;
  serviceSectionsDelete?: InputMaybe<Array<ServiceServiceSectionDeleteInput>>;
  serviceSectionsReplace?: InputMaybe<Array<ServiceServiceSectionCreateInput>>;
  serviceSectionsUpsert?: InputMaybe<Array<ServiceServiceSectionCreateInput>>;
  vendorId?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceWhereInput = {
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  isExternal?: InputMaybe<Scalars['Boolean']['input']>;
  openingHours?: InputMaybe<StringFilterInput>;
  parkingId?: InputMaybe<StringFilterInput>;
  parkingType?: InputMaybe<ParkingTypeWhereInput>;
  parkingTypeId?: InputMaybe<StringFilterInput>;
  providerId?: InputMaybe<StringFilterInput>;
  remarks?: InputMaybe<StringFilterInput>;
  serviceOptions?: InputMaybe<ServiceOptionListRelationFilter>;
  serviceSections?: InputMaybe<ServiceSectionListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
  vendorId?: InputMaybe<StringFilterInput>;
};

export type ServiceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SignInInput = {
  challenge: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SignInPayload = {
  __typename?: 'SignInPayload';
  redirect_to?: Maybe<Scalars['String']['output']>;
};

export type SignOutInput = {
  logoutChallenge: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};

export type SignOutPayload = {
  __typename?: 'SignOutPayload';
  message: Scalars['String']['output'];
  redirect_to: Scalars['String']['output'];
};

export type Site = {
  __typename?: 'Site';
  contactPoint?: Maybe<ContactPoint>;
  createdAt: Scalars['Date']['output'];
  decimalDelimiter?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  domain: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  feedbackCompanyClientId?: Maybe<Scalars['String']['output']>;
  feedbackCompanyClientSecret?: Maybe<Scalars['String']['output']>;
  feedbackCompanyForm?: Maybe<Scalars['String']['output']>;
  feedbackCompanyUrl?: Maybe<Scalars['String']['output']>;
  freshSalesUrl?: Maybe<Scalars['String']['output']>;
  googleMeasurementId?: Maybe<Scalars['String']['output']>;
  googleSiteVerification?: Maybe<Scalars['String']['output']>;
  googleTagManagerContainerId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  incomingRedirects?: Maybe<Array<Redirect>>;
  mascotName?: Maybe<Scalars['String']['output']>;
  mobianFunnel?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  outgoingRedirects?: Maybe<Array<Redirect>>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneHref?: Maybe<Scalars['String']['output']>;
  safeAndSecureLogos?: Maybe<Array<SafeAndSecureLogo>>;
  siteAirports?: Maybe<Array<SiteSiteAirport>>;
  siteCountries?: Maybe<Array<SiteSiteCountry>>;
  siteCurrencies?: Maybe<Array<SiteSiteCurrency>>;
  siteLanguages?: Maybe<Array<SiteSiteLanguage>>;
  siteSocialMedias?: Maybe<Array<SiteSiteSocialMedia>>;
  siteSubdomains?: Maybe<Array<SiteSiteSubdomain>>;
  slug: Scalars['String']['output'];
  symbolBeforePrice?: Maybe<Scalars['Boolean']['output']>;
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  termsAndConditionsMobian?: Maybe<Scalars['String']['output']>;
  thousandSeparator?: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
  trustMarkLogos?: Maybe<Array<TrustMarkLogo>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  whatsAppHref?: Maybe<Scalars['String']['output']>;
  whatsAppNumber?: Maybe<Scalars['String']['output']>;
};

export type SiteAirport = {
  __typename?: 'SiteAirport';
  airport?: Maybe<Airport>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  rank?: Maybe<Scalars['Int']['output']>;
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteAirportListRelationFilter = {
  every?: InputMaybe<SiteAirportWhereInput>;
  none?: InputMaybe<SiteAirportWhereInput>;
  some?: InputMaybe<SiteAirportWhereInput>;
};

export type SiteAirportWhereInput = {
  AND?: InputMaybe<Array<SiteAirportWhereInput>>;
  NOT?: InputMaybe<Array<SiteAirportWhereInput>>;
  OR?: InputMaybe<Array<SiteAirportWhereInput>>;
  airport?: InputMaybe<AirportWhereInput>;
  airportId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  rank?: InputMaybe<IntFilterInput>;
  site?: InputMaybe<SiteWhereInput>;
  siteId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SiteAirportWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SiteCountry = {
  __typename?: 'SiteCountry';
  country?: Maybe<Country>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteCountryListRelationFilter = {
  every?: InputMaybe<SiteCountryWhereInput>;
  none?: InputMaybe<SiteCountryWhereInput>;
  some?: InputMaybe<SiteCountryWhereInput>;
};

export type SiteCountryWhereInput = {
  AND?: InputMaybe<Array<SiteCountryWhereInput>>;
  NOT?: InputMaybe<Array<SiteCountryWhereInput>>;
  OR?: InputMaybe<Array<SiteCountryWhereInput>>;
  country?: InputMaybe<CountryWhereInput>;
  countryId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  site?: InputMaybe<SiteWhereInput>;
  siteId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SiteCountryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SiteCreateInput = {
  contactPointIdConnect?: InputMaybe<Scalars['ID']['input']>;
  decimalDelimiter?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  feedbackCompanyClientId?: InputMaybe<Scalars['String']['input']>;
  feedbackCompanyClientSecret?: InputMaybe<Scalars['String']['input']>;
  feedbackCompanyForm?: InputMaybe<Scalars['String']['input']>;
  feedbackCompanyUrl?: InputMaybe<Scalars['String']['input']>;
  freshSalesUrl?: InputMaybe<Scalars['String']['input']>;
  googleMeasurementId?: InputMaybe<Scalars['String']['input']>;
  googleSiteVerification?: InputMaybe<Scalars['String']['input']>;
  googleTagManagerContainerId?: InputMaybe<Scalars['String']['input']>;
  incomingRedirectIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  mascotName?: InputMaybe<Scalars['String']['input']>;
  mobianFunnel?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  outgoingRedirectIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  paymentMethodIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneHref?: InputMaybe<Scalars['String']['input']>;
  safeAndSecureLogoIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteAirportsCreate?: InputMaybe<Array<SiteSiteAirportCreateInput>>;
  siteCountriesCreate?: InputMaybe<Array<SiteSiteCountryCreateInput>>;
  siteCurrenciesCreate?: InputMaybe<Array<SiteSiteCurrencyCreateInput>>;
  siteLanguagesCreate?: InputMaybe<Array<SiteSiteLanguageCreateInput>>;
  siteSocialMediasCreate?: InputMaybe<Array<SiteSiteSocialMediaCreateInput>>;
  siteSubdomainsCreate?: InputMaybe<Array<SiteSiteSubdomainCreateInput>>;
  slug: Scalars['String']['input'];
  symbolBeforePrice?: InputMaybe<Scalars['Boolean']['input']>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  termsAndConditionsMobian?: InputMaybe<Scalars['String']['input']>;
  thousandSeparator?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
  trustMarkLogoIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  whatsAppHref?: InputMaybe<Scalars['String']['input']>;
  whatsAppNumber?: InputMaybe<Scalars['String']['input']>;
};

export type SiteCurrency = {
  __typename?: 'SiteCurrency';
  createdAt: Scalars['Date']['output'];
  currency?: Maybe<Currency>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteCurrencyListRelationFilter = {
  every?: InputMaybe<SiteCurrencyWhereInput>;
  none?: InputMaybe<SiteCurrencyWhereInput>;
  some?: InputMaybe<SiteCurrencyWhereInput>;
};

export type SiteCurrencyWhereInput = {
  AND?: InputMaybe<Array<SiteCurrencyWhereInput>>;
  NOT?: InputMaybe<Array<SiteCurrencyWhereInput>>;
  OR?: InputMaybe<Array<SiteCurrencyWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  currency?: InputMaybe<CurrencyWhereInput>;
  currencyId?: InputMaybe<StringFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  site?: InputMaybe<SiteWhereInput>;
  siteId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SiteCurrencyWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SiteLanguage = {
  __typename?: 'SiteLanguage';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  language?: Maybe<Language>;
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteLanguageListRelationFilter = {
  every?: InputMaybe<SiteLanguageWhereInput>;
  none?: InputMaybe<SiteLanguageWhereInput>;
  some?: InputMaybe<SiteLanguageWhereInput>;
};

export type SiteLanguageWhereInput = {
  AND?: InputMaybe<Array<SiteLanguageWhereInput>>;
  NOT?: InputMaybe<Array<SiteLanguageWhereInput>>;
  OR?: InputMaybe<Array<SiteLanguageWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<LanguageWhereInput>;
  languageId?: InputMaybe<StringFilterInput>;
  site?: InputMaybe<SiteWhereInput>;
  siteId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SiteLanguageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SiteListRelationFilter = {
  every?: InputMaybe<SiteWhereInput>;
  none?: InputMaybe<SiteWhereInput>;
  some?: InputMaybe<SiteWhereInput>;
};

export type SiteOrderByInput = {
  contactPoint?: InputMaybe<ContactPointOrderByInput>;
  createdAt?: InputMaybe<SortOrder>;
  decimalDelimiter?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  feedbackCompanyClientId?: InputMaybe<SortOrder>;
  feedbackCompanyClientSecret?: InputMaybe<SortOrder>;
  feedbackCompanyForm?: InputMaybe<SortOrder>;
  feedbackCompanyUrl?: InputMaybe<SortOrder>;
  freshSalesUrl?: InputMaybe<SortOrder>;
  googleMeasurementId?: InputMaybe<SortOrder>;
  googleSiteVerification?: InputMaybe<SortOrder>;
  googleTagManagerContainerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mascotName?: InputMaybe<SortOrder>;
  mobianFunnel?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  phoneHref?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  symbolBeforePrice?: InputMaybe<SortOrder>;
  termsAndConditions?: InputMaybe<SortOrder>;
  termsAndConditionsMobian?: InputMaybe<SortOrder>;
  thousandSeparator?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  whatsAppHref?: InputMaybe<SortOrder>;
  whatsAppNumber?: InputMaybe<SortOrder>;
};

export type SitePaginatedResponse = {
  __typename?: 'SitePaginatedResponse';
  items: Array<Site>;
  pageInfo: PageInfo;
};

export type SiteSiteAirport = {
  __typename?: 'SiteSiteAirport';
  airport?: Maybe<Airport>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  rank?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteSiteAirportCreateInput = {
  airportId: Scalars['ID']['input'];
  rank?: InputMaybe<Scalars['Int']['input']>;
};

export type SiteSiteAirportDeleteInput = {
  airportId: Scalars['ID']['input'];
};

export type SiteSiteCountry = {
  __typename?: 'SiteSiteCountry';
  country?: Maybe<Country>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteSiteCountryCreateInput = {
  countryId: Scalars['ID']['input'];
  isDefault: Scalars['Boolean']['input'];
};

export type SiteSiteCountryDeleteInput = {
  countryId: Scalars['ID']['input'];
};

export type SiteSiteCurrency = {
  __typename?: 'SiteSiteCurrency';
  createdAt: Scalars['Date']['output'];
  currency?: Maybe<Currency>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteSiteCurrencyCreateInput = {
  currencyId: Scalars['ID']['input'];
  isDefault: Scalars['Boolean']['input'];
};

export type SiteSiteCurrencyDeleteInput = {
  currencyId: Scalars['ID']['input'];
};

export type SiteSiteLanguage = {
  __typename?: 'SiteSiteLanguage';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  language?: Maybe<Language>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteSiteLanguageCreateInput = {
  isDefault: Scalars['Boolean']['input'];
  languageId: Scalars['ID']['input'];
};

export type SiteSiteLanguageDeleteInput = {
  languageId: Scalars['ID']['input'];
};

export type SiteSiteSocialMedia = {
  __typename?: 'SiteSiteSocialMedia';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  socialMedia?: Maybe<SocialMedia>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  url: Scalars['String']['output'];
};

export type SiteSiteSocialMediaCreateInput = {
  socialMediaId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

export type SiteSiteSocialMediaDeleteInput = {
  socialMediaId: Scalars['ID']['input'];
};

export type SiteSiteSubdomain = {
  __typename?: 'SiteSiteSubdomain';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  logoUrl: Scalars['String']['output'];
  subdomain?: Maybe<Subdomain>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteSiteSubdomainCreateInput = {
  logoUrl: Scalars['String']['input'];
  subdomainId: Scalars['ID']['input'];
};

export type SiteSiteSubdomainDeleteInput = {
  subdomainId: Scalars['ID']['input'];
};

export type SiteSocialMedia = {
  __typename?: 'SiteSocialMedia';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  site?: Maybe<Site>;
  socialMedia?: Maybe<SocialMedia>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  url: Scalars['String']['output'];
};

export type SiteSocialMediaListRelationFilter = {
  every?: InputMaybe<SiteSocialMediaWhereInput>;
  none?: InputMaybe<SiteSocialMediaWhereInput>;
  some?: InputMaybe<SiteSocialMediaWhereInput>;
};

export type SiteSocialMediaWhereInput = {
  AND?: InputMaybe<Array<SiteSocialMediaWhereInput>>;
  NOT?: InputMaybe<Array<SiteSocialMediaWhereInput>>;
  OR?: InputMaybe<Array<SiteSocialMediaWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  site?: InputMaybe<SiteWhereInput>;
  siteId?: InputMaybe<StringFilterInput>;
  socialMedia?: InputMaybe<SocialMediaWhereInput>;
  socialMediaId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
  url?: InputMaybe<StringFilterInput>;
};

export type SiteSocialMediaWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SiteSubdomain = {
  __typename?: 'SiteSubdomain';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  logoUrl: Scalars['String']['output'];
  site?: Maybe<Site>;
  subdomain?: Maybe<Subdomain>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteSubdomainListRelationFilter = {
  every?: InputMaybe<SiteSubdomainWhereInput>;
  none?: InputMaybe<SiteSubdomainWhereInput>;
  some?: InputMaybe<SiteSubdomainWhereInput>;
};

export type SiteSubdomainWhereInput = {
  AND?: InputMaybe<Array<SiteSubdomainWhereInput>>;
  NOT?: InputMaybe<Array<SiteSubdomainWhereInput>>;
  OR?: InputMaybe<Array<SiteSubdomainWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  logoUrl?: InputMaybe<StringFilterInput>;
  site?: InputMaybe<SiteWhereInput>;
  siteId?: InputMaybe<StringFilterInput>;
  subdomain?: InputMaybe<SubdomainWhereInput>;
  subdomainId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SiteSubdomainWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SiteUpdateInput = {
  contactPointIdConnect?: InputMaybe<Scalars['ID']['input']>;
  contactPointIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  decimalDelimiter?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  feedbackCompanyClientId?: InputMaybe<Scalars['String']['input']>;
  feedbackCompanyClientSecret?: InputMaybe<Scalars['String']['input']>;
  feedbackCompanyForm?: InputMaybe<Scalars['String']['input']>;
  feedbackCompanyUrl?: InputMaybe<Scalars['String']['input']>;
  freshSalesUrl?: InputMaybe<Scalars['String']['input']>;
  googleMeasurementId?: InputMaybe<Scalars['String']['input']>;
  googleSiteVerification?: InputMaybe<Scalars['String']['input']>;
  googleTagManagerContainerId?: InputMaybe<Scalars['String']['input']>;
  incomingRedirectIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  incomingRedirectIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  incomingRedirectIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  mascotName?: InputMaybe<Scalars['String']['input']>;
  mobianFunnel?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  outgoingRedirectIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  outgoingRedirectIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  outgoingRedirectIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  paymentMethodIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  paymentMethodIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  paymentMethodIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneHref?: InputMaybe<Scalars['String']['input']>;
  safeAndSecureLogoIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  safeAndSecureLogoIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  safeAndSecureLogoIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteAirportsDelete?: InputMaybe<Array<SiteSiteAirportDeleteInput>>;
  siteAirportsReplace?: InputMaybe<Array<SiteSiteAirportCreateInput>>;
  siteAirportsUpsert?: InputMaybe<Array<SiteSiteAirportCreateInput>>;
  siteCountriesDelete?: InputMaybe<Array<SiteSiteCountryDeleteInput>>;
  siteCountriesReplace?: InputMaybe<Array<SiteSiteCountryCreateInput>>;
  siteCountriesUpsert?: InputMaybe<Array<SiteSiteCountryCreateInput>>;
  siteCurrenciesDelete?: InputMaybe<Array<SiteSiteCurrencyDeleteInput>>;
  siteCurrenciesReplace?: InputMaybe<Array<SiteSiteCurrencyCreateInput>>;
  siteCurrenciesUpsert?: InputMaybe<Array<SiteSiteCurrencyCreateInput>>;
  siteLanguagesDelete?: InputMaybe<Array<SiteSiteLanguageDeleteInput>>;
  siteLanguagesReplace?: InputMaybe<Array<SiteSiteLanguageCreateInput>>;
  siteLanguagesUpsert?: InputMaybe<Array<SiteSiteLanguageCreateInput>>;
  siteSocialMediasDelete?: InputMaybe<Array<SiteSiteSocialMediaDeleteInput>>;
  siteSocialMediasReplace?: InputMaybe<Array<SiteSiteSocialMediaCreateInput>>;
  siteSocialMediasUpsert?: InputMaybe<Array<SiteSiteSocialMediaCreateInput>>;
  siteSubdomainsDelete?: InputMaybe<Array<SiteSiteSubdomainDeleteInput>>;
  siteSubdomainsReplace?: InputMaybe<Array<SiteSiteSubdomainCreateInput>>;
  siteSubdomainsUpsert?: InputMaybe<Array<SiteSiteSubdomainCreateInput>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  symbolBeforePrice?: InputMaybe<Scalars['Boolean']['input']>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  termsAndConditionsMobian?: InputMaybe<Scalars['String']['input']>;
  thousandSeparator?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  trustMarkLogoIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  trustMarkLogoIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  trustMarkLogoIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  whatsAppHref?: InputMaybe<Scalars['String']['input']>;
  whatsAppNumber?: InputMaybe<Scalars['String']['input']>;
};

export type SiteWhereInput = {
  AND?: InputMaybe<Array<SiteWhereInput>>;
  NOT?: InputMaybe<Array<SiteWhereInput>>;
  OR?: InputMaybe<Array<SiteWhereInput>>;
  contactPoint?: InputMaybe<ContactPointWhereInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  decimalDelimiter?: InputMaybe<StringFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  domain?: InputMaybe<StringFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  feedbackCompanyClientId?: InputMaybe<StringFilterInput>;
  feedbackCompanyClientSecret?: InputMaybe<StringFilterInput>;
  feedbackCompanyForm?: InputMaybe<StringFilterInput>;
  feedbackCompanyUrl?: InputMaybe<StringFilterInput>;
  freshSalesUrl?: InputMaybe<StringFilterInput>;
  googleMeasurementId?: InputMaybe<StringFilterInput>;
  googleSiteVerification?: InputMaybe<StringFilterInput>;
  googleTagManagerContainerId?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  incomingRedirects?: InputMaybe<RedirectListRelationFilter>;
  mascotName?: InputMaybe<StringFilterInput>;
  mobianFunnel?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  outgoingRedirects?: InputMaybe<RedirectListRelationFilter>;
  paymentMethods?: InputMaybe<PaymentMethodListRelationFilter>;
  phone?: InputMaybe<StringFilterInput>;
  phoneHref?: InputMaybe<StringFilterInput>;
  safeAndSecureLogos?: InputMaybe<SafeAndSecureLogoListRelationFilter>;
  siteAirports?: InputMaybe<SiteAirportListRelationFilter>;
  siteCountries?: InputMaybe<SiteCountryListRelationFilter>;
  siteCurrencies?: InputMaybe<SiteCurrencyListRelationFilter>;
  siteLanguages?: InputMaybe<SiteLanguageListRelationFilter>;
  siteSocialMedias?: InputMaybe<SiteSocialMediaListRelationFilter>;
  siteSubdomains?: InputMaybe<SiteSubdomainListRelationFilter>;
  slug?: InputMaybe<StringFilterInput>;
  symbolBeforePrice?: InputMaybe<Scalars['Boolean']['input']>;
  termsAndConditions?: InputMaybe<StringFilterInput>;
  termsAndConditionsMobian?: InputMaybe<StringFilterInput>;
  thousandSeparator?: InputMaybe<StringFilterInput>;
  timezone?: InputMaybe<StringFilterInput>;
  trustMarkLogos?: InputMaybe<TrustMarkLogoListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
  whatsAppHref?: InputMaybe<StringFilterInput>;
  whatsAppNumber?: InputMaybe<StringFilterInput>;
};

export type SiteWhereUniqueInput = {
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type SocialMedia = {
  __typename?: 'SocialMedia';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  siteSocialMedias?: Maybe<Array<SocialMediaSiteSocialMedia>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SocialMediaCreateInput = {
  name: Scalars['String']['input'];
  siteSocialMediasCreate?: InputMaybe<Array<SocialMediaSiteSocialMediaCreateInput>>;
};

export type SocialMediaListRelationFilter = {
  every?: InputMaybe<SocialMediaWhereInput>;
  none?: InputMaybe<SocialMediaWhereInput>;
  some?: InputMaybe<SocialMediaWhereInput>;
};

export type SocialMediaOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SocialMediaPaginatedResponse = {
  __typename?: 'SocialMediaPaginatedResponse';
  items: Array<SocialMedia>;
  pageInfo: PageInfo;
};

export type SocialMediaSiteSocialMedia = {
  __typename?: 'SocialMediaSiteSocialMedia';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  url: Scalars['String']['output'];
};

export type SocialMediaSiteSocialMediaCreateInput = {
  siteId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

export type SocialMediaSiteSocialMediaDeleteInput = {
  siteId: Scalars['ID']['input'];
};

export type SocialMediaUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  siteSocialMediasDelete?: InputMaybe<Array<SocialMediaSiteSocialMediaDeleteInput>>;
  siteSocialMediasReplace?: InputMaybe<Array<SocialMediaSiteSocialMediaCreateInput>>;
  siteSocialMediasUpsert?: InputMaybe<Array<SocialMediaSiteSocialMediaCreateInput>>;
};

export type SocialMediaWhereInput = {
  AND?: InputMaybe<Array<SocialMediaWhereInput>>;
  NOT?: InputMaybe<Array<SocialMediaWhereInput>>;
  OR?: InputMaybe<Array<SocialMediaWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  siteSocialMedias?: InputMaybe<SiteSocialMediaListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SocialMediaWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type StringFilterInput = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<StringFilterInput>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringListFilterInput = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  has?: InputMaybe<Scalars['String']['input']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Subdomain = {
  __typename?: 'Subdomain';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  siteSubdomains?: Maybe<Array<SubdomainSiteSubdomain>>;
  slug: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SubdomainCreateInput = {
  name: Scalars['String']['input'];
  siteSubdomainsCreate?: InputMaybe<Array<SubdomainSiteSubdomainCreateInput>>;
  slug: Scalars['String']['input'];
};

export type SubdomainListRelationFilter = {
  every?: InputMaybe<SubdomainWhereInput>;
  none?: InputMaybe<SubdomainWhereInput>;
  some?: InputMaybe<SubdomainWhereInput>;
};

export type SubdomainOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubdomainPaginatedResponse = {
  __typename?: 'SubdomainPaginatedResponse';
  items: Array<Subdomain>;
  pageInfo: PageInfo;
};

export type SubdomainSiteSubdomain = {
  __typename?: 'SubdomainSiteSubdomain';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  logoUrl: Scalars['String']['output'];
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SubdomainSiteSubdomainCreateInput = {
  logoUrl: Scalars['String']['input'];
  siteId: Scalars['ID']['input'];
};

export type SubdomainSiteSubdomainDeleteInput = {
  siteId: Scalars['ID']['input'];
};

export type SubdomainUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  siteSubdomainsDelete?: InputMaybe<Array<SubdomainSiteSubdomainDeleteInput>>;
  siteSubdomainsReplace?: InputMaybe<Array<SubdomainSiteSubdomainCreateInput>>;
  siteSubdomainsUpsert?: InputMaybe<Array<SubdomainSiteSubdomainCreateInput>>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type SubdomainUser = {
  __typename?: 'SubdomainUser';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  subdomain?: Maybe<Subdomain>;
  subdomainId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<User>;
};

export type SubdomainUserListRelationFilter = {
  every?: InputMaybe<SubdomainUserWhereInput>;
  none?: InputMaybe<SubdomainUserWhereInput>;
  some?: InputMaybe<SubdomainUserWhereInput>;
};

export type SubdomainUserWhereInput = {
  AND?: InputMaybe<Array<SubdomainUserWhereInput>>;
  NOT?: InputMaybe<Array<SubdomainUserWhereInput>>;
  OR?: InputMaybe<Array<SubdomainUserWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  subdomainId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringFilterInput>;
};

export type SubdomainUserWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SubdomainWhereInput = {
  AND?: InputMaybe<Array<SubdomainWhereInput>>;
  NOT?: InputMaybe<Array<SubdomainWhereInput>>;
  OR?: InputMaybe<Array<SubdomainWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  siteSubdomains?: InputMaybe<SiteSubdomainListRelationFilter>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SubdomainWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Terminal = {
  __typename?: 'Terminal';
  airport?: Maybe<Airport>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<ConfigurationLocation>;
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type TerminalCreateInput = {
  airportIdConnect: Scalars['ID']['input'];
  locationIdConnect?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type TerminalListRelationFilter = {
  every?: InputMaybe<TerminalWhereInput>;
  none?: InputMaybe<TerminalWhereInput>;
  some?: InputMaybe<TerminalWhereInput>;
};

export type TerminalOrderByInput = {
  airport?: InputMaybe<AirportOrderByInput>;
  airportId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<ConfigurationLocationOrderByInput>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TerminalPaginatedResponse = {
  __typename?: 'TerminalPaginatedResponse';
  items: Array<Terminal>;
  pageInfo: PageInfo;
};

export type TerminalUpdateInput = {
  airportIdConnect?: InputMaybe<Scalars['ID']['input']>;
  locationIdConnect?: InputMaybe<Scalars['ID']['input']>;
  locationIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TerminalWhereInput = {
  AND?: InputMaybe<Array<TerminalWhereInput>>;
  NOT?: InputMaybe<Array<TerminalWhereInput>>;
  OR?: InputMaybe<Array<TerminalWhereInput>>;
  airport?: InputMaybe<AirportWhereInput>;
  airportId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  location?: InputMaybe<ConfigurationLocationWhereInput>;
  name?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type TerminalWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type TestLog = {
  __typename?: 'TestLog';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  duration: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  output?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  updatedAt: Scalars['Date']['output'];
  userId: Scalars['String']['output'];
};

export type TestLogCreateInput = {
  duration: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  output?: InputMaybe<Scalars['String']['input']>;
  success: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};

export type TestLogListRelationFilter = {
  every?: InputMaybe<TestLogWhereInput>;
  none?: InputMaybe<TestLogWhereInput>;
  some?: InputMaybe<TestLogWhereInput>;
};

export type TestLogOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  output?: InputMaybe<SortOrder>;
  success?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TestLogPaginatedResponse = {
  __typename?: 'TestLogPaginatedResponse';
  items: Array<TestLog>;
  pageInfo: PageInfo;
};

export type TestLogUpdateInput = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  output?: InputMaybe<Scalars['String']['input']>;
  success?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type TestLogWhereInput = {
  AND?: InputMaybe<Array<TestLogWhereInput>>;
  NOT?: InputMaybe<Array<TestLogWhereInput>>;
  OR?: InputMaybe<Array<TestLogWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  duration?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  output?: InputMaybe<StringFilterInput>;
  success?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<DateFilterInput>;
  userId?: InputMaybe<StringFilterInput>;
};

export type TestLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Translation = {
  __typename?: 'Translation';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  entityField: Scalars['String']['output'];
  entityId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  languageTag: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  value: Scalars['String']['output'];
};

export type TranslationCreateInput = {
  entityField: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  languageTag: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type TranslationListRelationFilter = {
  every?: InputMaybe<TranslationWhereInput>;
  none?: InputMaybe<TranslationWhereInput>;
  some?: InputMaybe<TranslationWhereInput>;
};

export type TranslationOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  entityField?: InputMaybe<SortOrder>;
  entityId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  languageTag?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type TranslationPaginatedResponse = {
  __typename?: 'TranslationPaginatedResponse';
  items: Array<Translation>;
  pageInfo: PageInfo;
};

export type TranslationUpdateInput = {
  entityField?: InputMaybe<Scalars['String']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  languageTag?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type TranslationWhereInput = {
  AND?: InputMaybe<Array<TranslationWhereInput>>;
  NOT?: InputMaybe<Array<TranslationWhereInput>>;
  OR?: InputMaybe<Array<TranslationWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  entityField?: InputMaybe<StringFilterInput>;
  entityId?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  languageTag?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
  value?: InputMaybe<StringFilterInput>;
};

export type TranslationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type TrustMarkLogo = {
  __typename?: 'TrustMarkLogo';
  alt: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sites?: Maybe<Array<Site>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  url: Scalars['String']['output'];
};

export type TrustMarkLogoCreateInput = {
  alt: Scalars['String']['input'];
  name: Scalars['String']['input'];
  siteIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  url: Scalars['String']['input'];
};

export type TrustMarkLogoListRelationFilter = {
  every?: InputMaybe<TrustMarkLogoWhereInput>;
  none?: InputMaybe<TrustMarkLogoWhereInput>;
  some?: InputMaybe<TrustMarkLogoWhereInput>;
};

export type TrustMarkLogoOrderByInput = {
  alt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type TrustMarkLogoPaginatedResponse = {
  __typename?: 'TrustMarkLogoPaginatedResponse';
  items: Array<TrustMarkLogo>;
  pageInfo: PageInfo;
};

export type TrustMarkLogoUpdateInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  siteIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type TrustMarkLogoWhereInput = {
  AND?: InputMaybe<Array<TrustMarkLogoWhereInput>>;
  NOT?: InputMaybe<Array<TrustMarkLogoWhereInput>>;
  OR?: InputMaybe<Array<TrustMarkLogoWhereInput>>;
  alt?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  sites?: InputMaybe<SiteListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
  url?: InputMaybe<StringFilterInput>;
};

export type TrustMarkLogoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type User = {
  __typename?: 'User';
  businessEntityUsers?: Maybe<Array<UserBusinessEntityUser>>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  language?: Maybe<Language>;
  languageId: Scalars['String']['output'];
  password: Scalars['String']['output'];
  pmsUsers?: Maybe<Array<UserPmsProviderUser>>;
  providerUsers?: Maybe<Array<UserProviderUser>>;
  resets: Array<Scalars['String']['output']>;
  roles?: Maybe<Array<Role>>;
  socials: Array<Scalars['String']['output']>;
  subdomainUsers?: Maybe<Array<UserSubdomainUser>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type UserBusinessEntityUser = {
  __typename?: 'UserBusinessEntityUser';
  businessEntity?: Maybe<BusinessEntity>;
  businessEntityId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type UserBusinessEntityUserCreateInput = {
  businessEntityId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type UserBusinessEntityUserDeleteInput = {
  businessEntityId: Scalars['String']['input'];
};

export type UserCreateInput = {
  businessEntityUsersCreate?: InputMaybe<Array<UserBusinessEntityUserCreateInput>>;
  email: Scalars['String']['input'];
  languageId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  pmsUsersCreate?: InputMaybe<Array<UserPmsProviderUserCreateInput>>;
  providerUsersCreate?: InputMaybe<Array<UserProviderUserCreateInput>>;
  resets: Array<Scalars['String']['input']>;
  roleIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  socials: Array<Scalars['String']['input']>;
  subdomainUsersCreate?: InputMaybe<Array<UserSubdomainUserCreateInput>>;
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  languageId?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  resets?: InputMaybe<SortOrder>;
  socials?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserPaginatedResponse = {
  __typename?: 'UserPaginatedResponse';
  items: Array<User>;
  pageInfo: PageInfo;
};

export type UserPmsProviderUser = {
  __typename?: 'UserPmsProviderUser';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  pmsProvider?: Maybe<PmsProvider>;
  pmsProviderId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type UserPmsProviderUserCreateInput = {
  pmsProviderId: Scalars['String']['input'];
};

export type UserPmsProviderUserDeleteInput = {
  pmsProviderId: Scalars['String']['input'];
};

export type UserProviderUser = {
  __typename?: 'UserProviderUser';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  provider?: Maybe<Provider>;
  providerId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type UserProviderUserCreateInput = {
  providerId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type UserProviderUserDeleteInput = {
  providerId: Scalars['String']['input'];
};

export type UserSubdomainUser = {
  __typename?: 'UserSubdomainUser';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  subdomain?: Maybe<Subdomain>;
  subdomainId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type UserSubdomainUserCreateInput = {
  subdomainId: Scalars['String']['input'];
};

export type UserSubdomainUserDeleteInput = {
  subdomainId: Scalars['String']['input'];
};

export type UserUpdateInput = {
  businessEntityUsersDelete?: InputMaybe<Array<UserBusinessEntityUserDeleteInput>>;
  businessEntityUsersReplace?: InputMaybe<Array<UserBusinessEntityUserCreateInput>>;
  businessEntityUsersUpsert?: InputMaybe<Array<UserBusinessEntityUserCreateInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  languageId?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  pmsUsersDelete?: InputMaybe<Array<UserPmsProviderUserDeleteInput>>;
  pmsUsersReplace?: InputMaybe<Array<UserPmsProviderUserCreateInput>>;
  pmsUsersUpsert?: InputMaybe<Array<UserPmsProviderUserCreateInput>>;
  providerUsersDelete?: InputMaybe<Array<UserProviderUserDeleteInput>>;
  providerUsersReplace?: InputMaybe<Array<UserProviderUserCreateInput>>;
  providerUsersUpsert?: InputMaybe<Array<UserProviderUserCreateInput>>;
  resets?: InputMaybe<Array<Scalars['String']['input']>>;
  roleIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  roleIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  roleIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  socials?: InputMaybe<Array<Scalars['String']['input']>>;
  subdomainUsersDelete?: InputMaybe<Array<UserSubdomainUserDeleteInput>>;
  subdomainUsersReplace?: InputMaybe<Array<UserSubdomainUserCreateInput>>;
  subdomainUsersUpsert?: InputMaybe<Array<UserSubdomainUserCreateInput>>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  businessEntityUsers?: InputMaybe<BusinessEntityUserListRelationFilter>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  languageId?: InputMaybe<StringFilterInput>;
  password?: InputMaybe<StringFilterInput>;
  pmsUsers?: InputMaybe<PmsProviderUserListRelationFilter>;
  providerUsers?: InputMaybe<ProviderUserListRelationFilter>;
  resets?: InputMaybe<StringListFilterInput>;
  roles?: InputMaybe<RoleListRelationFilter>;
  socials?: InputMaybe<StringListFilterInput>;
  subdomainUsers?: InputMaybe<SubdomainUserListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SearchAirportFieldsFragment = {
  __typename?: 'SiteSiteAirport';
  id: string;
  airport?: {
    __typename?: 'Airport';
    id: string;
    name: string;
    terminals?: Array<{ __typename?: 'Terminal'; id: string; name: string }> | null;
    location?: {
      __typename?: 'ConfigurationLocation';
      id: string;
      lat: number;
      long: number;
      country?: {
        __typename?: 'Country';
        id: string;
        name: string;
        flagIconUrl?: string | null;
      } | null;
    } | null;
  } | null;
};

export type SearchAirportQueryVariables = Exact<{
  where: SiteWhereUniqueInput;
}>;

export type SearchAirportQuery = {
  __typename?: 'Query';
  getSiteBy: {
    __typename?: 'Site';
    id: string;
    siteAirports?: Array<{
      __typename?: 'SiteSiteAirport';
      id: string;
      airport?: {
        __typename?: 'Airport';
        id: string;
        name: string;
        terminals?: Array<{
          __typename?: 'Terminal';
          id: string;
          name: string;
        }> | null;
        location?: {
          __typename?: 'ConfigurationLocation';
          id: string;
          lat: number;
          long: number;
          country?: {
            __typename?: 'Country';
            id: string;
            name: string;
            flagIconUrl?: string | null;
          } | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type AirportLocationsFieldsFragment = {
  __typename?: 'SiteSiteAirport';
  id: string;
  airport?: {
    __typename?: 'Airport';
    id: string;
    name: string;
    iata: string;
    location?: {
      __typename?: 'ConfigurationLocation';
      id: string;
      lat: number;
      long: number;
      country?: {
        __typename?: 'Country';
        id: string;
        name: string;
        flagIconUrl?: string | null;
      } | null;
    } | null;
  } | null;
};

export type AirportsLocationQueryVariables = Exact<{
  where: SiteWhereUniqueInput;
}>;

export type AirportsLocationQuery = {
  __typename?: 'Query';
  getSiteBy: {
    __typename?: 'Site';
    id: string;
    siteAirports?: Array<{
      __typename?: 'SiteSiteAirport';
      id: string;
      airport?: {
        __typename?: 'Airport';
        id: string;
        name: string;
        iata: string;
        location?: {
          __typename?: 'ConfigurationLocation';
          id: string;
          lat: number;
          long: number;
          country?: {
            __typename?: 'Country';
            id: string;
            name: string;
            flagIconUrl?: string | null;
          } | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type PopularAirportFieldsFragment = {
  __typename?: 'Airport';
  id: string;
  name: string;
  location?: {
    __typename?: 'ConfigurationLocation';
    id: string;
    country?: { __typename?: 'Country'; id: string; name: string } | null;
  } | null;
};

export type GetSitePopularAirportsByQueryVariables = Exact<{
  where: SiteWhereUniqueInput;
}>;

export type GetSitePopularAirportsByQuery = {
  __typename?: 'Query';
  getSitePopularAirportsBy: Array<{
    __typename?: 'Airport';
    id: string;
    name: string;
    location?: {
      __typename?: 'ConfigurationLocation';
      id: string;
      country?: { __typename?: 'Country'; id: string; name: string } | null;
    } | null;
  }>;
};

export type ExchangeTokenMutationVariables = Exact<{
  tokenToExchange: ExchangeTokenInput;
}>;

export type ExchangeTokenMutation = {
  __typename?: 'Mutation';
  exchangeToken: {
    __typename?: 'ExchangeTokenPayload';
    refreshToken?: string | null;
    accessToken?: string | null;
    user: {
      __typename?: 'SafeUser';
      id: string;
      email: string;
      languageId: string;
      roles?: Array<{
        __typename?: 'Role';
        name: string;
        id: string;
        description?: string | null;
      }> | null;
    };
  };
};

export type FetchConsentMutationVariables = Exact<{
  consentToFetch: ConsentInput;
}>;

export type FetchConsentMutation = {
  __typename?: 'Mutation';
  fetchConsent: {
    __typename?: 'FetchConsentPayload';
    requestedScopes: Array<string>;
  };
};

export type RejectConsentMutationVariables = Exact<{
  consentToReject: ConsentInput;
}>;

export type RejectConsentMutation = {
  __typename?: 'Mutation';
  rejectConsent: { __typename?: 'RejectConsentPayload'; redirect_to: string };
};

export type AcceptConsentMutationVariables = Exact<{
  consentToAccept: ConsentInput;
}>;

export type AcceptConsentMutation = {
  __typename?: 'Mutation';
  acceptConsent: { __typename?: 'AcceptConsentPayload'; redirect_to: string };
};

export type SignInMutationVariables = Exact<{
  userToSign: SignInInput;
}>;

export type SignInMutation = {
  __typename?: 'Mutation';
  signIn: { __typename?: 'SignInPayload'; redirect_to?: string | null };
};

export type GetAllSitesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetAllSitesQuery = {
  __typename?: 'Query';
  getAllSites: {
    __typename?: 'SitePaginatedResponse';
    items: Array<{
      __typename?: 'Site';
      id: string;
      name: string;
      domain: string;
      siteCountries?: Array<{
        __typename?: 'SiteSiteCountry';
        id: string;
        isDefault: boolean;
        country?: {
          __typename?: 'Country';
          id: string;
          flagIconUrl?: string | null;
        } | null;
      }> | null;
    }>;
  };
};

export type AirportFieldsFragment = {
  __typename?: 'SiteSiteAirport';
  id: string;
  airport?: {
    __typename?: 'Airport';
    id: string;
    name: string;
    location?: {
      __typename?: 'ConfigurationLocation';
      id: string;
      country?: {
        __typename?: 'Country';
        id: string;
        name: string;
        flagIconUrl?: string | null;
      } | null;
    } | null;
  } | null;
};

export type HeaderAirportsQueryVariables = Exact<{
  where: SiteWhereUniqueInput;
}>;

export type HeaderAirportsQuery = {
  __typename?: 'Query';
  getSiteBy: {
    __typename?: 'Site';
    id: string;
    siteAirports?: Array<{
      __typename?: 'SiteSiteAirport';
      id: string;
      airport?: {
        __typename?: 'Airport';
        id: string;
        name: string;
        location?: {
          __typename?: 'ConfigurationLocation';
          id: string;
          country?: {
            __typename?: 'Country';
            id: string;
            name: string;
            flagIconUrl?: string | null;
          } | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type ModalCountryQueryVariables = Exact<{
  where: SiteWhereUniqueInput;
}>;

export type ModalCountryQuery = {
  __typename?: 'Query';
  getSiteBy: {
    __typename?: 'Site';
    id: string;
    name: string;
    domain: string;
    siteCurrencies?: Array<{
      __typename?: 'SiteSiteCurrency';
      id: string;
      isDefault: boolean;
      currency?: {
        __typename?: 'Currency';
        id: string;
        name: string;
        iso: string;
        symbol: string;
      } | null;
    }> | null;
    siteLanguages?: Array<{
      __typename?: 'SiteSiteLanguage';
      id: string;
      isDefault: boolean;
      language?: {
        __typename?: 'Language';
        id: string;
        iso: string;
        tag: string;
        name: string;
      } | null;
    }> | null;
  };
};

export type RefreshTokenMutationVariables = Exact<{
  currentRefreshToken: Scalars['String']['input'];
}>;

export type RefreshTokenMutation = {
  __typename?: 'Mutation';
  refreshToken: {
    __typename?: 'RefreshTokenPayload';
    newAccessToken: string;
    newRefreshToken: string;
  };
};

export const SearchAirportFieldsFragmentDoc = gql`
  fragment SearchAirportFields on SiteSiteAirport {
    id
    airport {
      id
      name
      terminals {
        id
        name
      }
      location {
        id
        lat
        long
        country {
          id
          name
          flagIconUrl
        }
      }
    }
  }
`;
export const AirportLocationsFieldsFragmentDoc = gql`
  fragment AirportLocationsFields on SiteSiteAirport {
    id
    airport {
      id
      name
      iata
      location {
        id
        lat
        long
        country {
          id
          name
          flagIconUrl
        }
      }
    }
  }
`;
export const PopularAirportFieldsFragmentDoc = gql`
  fragment PopularAirportFields on Airport {
    id
    name
    location {
      id
      country {
        id
        name
      }
    }
  }
`;
export const AirportFieldsFragmentDoc = gql`
  fragment AirportFields on SiteSiteAirport {
    id
    airport {
      id
      name
      location {
        id
        country {
          id
          name
          flagIconUrl
        }
      }
    }
  }
`;
export const SearchAirportDocument = gql`
  query SearchAirport($where: SiteWhereUniqueInput!) {
    getSiteBy(where: $where) {
      id
      siteAirports {
        ...SearchAirportFields
      }
    }
  }
  ${SearchAirportFieldsFragmentDoc}
`;

export function useSearchAirportQuery(
  options: Omit<Urql.UseQueryArgs<SearchAirportQueryVariables>, 'query'>
) {
  return Urql.useQuery<SearchAirportQuery, SearchAirportQueryVariables>({
    query: SearchAirportDocument,
    ...options,
  });
}
export const AirportsLocationDocument = gql`
  query AirportsLocation($where: SiteWhereUniqueInput!) {
    getSiteBy(where: $where) {
      id
      siteAirports {
        ...AirportLocationsFields
      }
    }
  }
  ${AirportLocationsFieldsFragmentDoc}
`;

export function useAirportsLocationQuery(
  options: Omit<Urql.UseQueryArgs<AirportsLocationQueryVariables>, 'query'>
) {
  return Urql.useQuery<AirportsLocationQuery, AirportsLocationQueryVariables>({
    query: AirportsLocationDocument,
    ...options,
  });
}
export const GetSitePopularAirportsByDocument = gql`
  query GetSitePopularAirportsBy($where: SiteWhereUniqueInput!) {
    getSitePopularAirportsBy(where: $where) {
      ...PopularAirportFields
    }
  }
  ${PopularAirportFieldsFragmentDoc}
`;

export function useGetSitePopularAirportsByQuery(
  options: Omit<Urql.UseQueryArgs<GetSitePopularAirportsByQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GetSitePopularAirportsByQuery,
    GetSitePopularAirportsByQueryVariables
  >({ query: GetSitePopularAirportsByDocument, ...options });
}
export const ExchangeTokenDocument = gql`
  mutation ExchangeToken($tokenToExchange: ExchangeTokenInput!) {
    exchangeToken(tokenToExchange: $tokenToExchange) {
      refreshToken
      accessToken
      user {
        id
        email
        languageId
        roles {
          name
          id
          description
        }
      }
    }
  }
`;

export function useExchangeTokenMutation() {
  return Urql.useMutation<ExchangeTokenMutation, ExchangeTokenMutationVariables>(
    ExchangeTokenDocument
  );
}
export const FetchConsentDocument = gql`
  mutation FetchConsent($consentToFetch: ConsentInput!) {
    fetchConsent(consentToFetch: $consentToFetch) {
      requestedScopes
    }
  }
`;

export function useFetchConsentMutation() {
  return Urql.useMutation<FetchConsentMutation, FetchConsentMutationVariables>(
    FetchConsentDocument
  );
}
export const RejectConsentDocument = gql`
  mutation RejectConsent($consentToReject: ConsentInput!) {
    rejectConsent(consentToReject: $consentToReject) {
      redirect_to
    }
  }
`;

export function useRejectConsentMutation() {
  return Urql.useMutation<RejectConsentMutation, RejectConsentMutationVariables>(
    RejectConsentDocument
  );
}
export const AcceptConsentDocument = gql`
  mutation AcceptConsent($consentToAccept: ConsentInput!) {
    acceptConsent(consentToAccept: $consentToAccept) {
      redirect_to
    }
  }
`;

export function useAcceptConsentMutation() {
  return Urql.useMutation<AcceptConsentMutation, AcceptConsentMutationVariables>(
    AcceptConsentDocument
  );
}
export const SignInDocument = gql`
  mutation SignIn($userToSign: SignInInput!) {
    signIn(userToSign: $userToSign) {
      redirect_to
    }
  }
`;

export function useSignInMutation() {
  return Urql.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument);
}
export const GetAllSitesDocument = gql`
  query GetAllSites($skip: Int, $take: Int) {
    getAllSites(skip: $skip, take: $take) {
      items {
        id
        name
        domain
        siteCountries {
          id
          isDefault
          country {
            id
            flagIconUrl
          }
        }
      }
    }
  }
`;

export function useGetAllSitesQuery(
  options?: Omit<Urql.UseQueryArgs<GetAllSitesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetAllSitesQuery, GetAllSitesQueryVariables>({
    query: GetAllSitesDocument,
    ...options,
  });
}
export const HeaderAirportsDocument = gql`
  query HeaderAirports($where: SiteWhereUniqueInput!) {
    getSiteBy(where: $where) {
      id
      siteAirports {
        ...AirportFields
      }
    }
  }
  ${AirportFieldsFragmentDoc}
`;

export function useHeaderAirportsQuery(
  options: Omit<Urql.UseQueryArgs<HeaderAirportsQueryVariables>, 'query'>
) {
  return Urql.useQuery<HeaderAirportsQuery, HeaderAirportsQueryVariables>({
    query: HeaderAirportsDocument,
    ...options,
  });
}
export const ModalCountryDocument = gql`
  query ModalCountry($where: SiteWhereUniqueInput!) {
    getSiteBy(where: $where) {
      id
      name
      domain
      siteCurrencies {
        id
        isDefault
        currency {
          id
          name
          iso
          symbol
        }
      }
      siteLanguages {
        id
        isDefault
        language {
          id
          iso
          tag
          name
        }
      }
    }
  }
`;

export function useModalCountryQuery(
  options: Omit<Urql.UseQueryArgs<ModalCountryQueryVariables>, 'query'>
) {
  return Urql.useQuery<ModalCountryQuery, ModalCountryQueryVariables>({
    query: ModalCountryDocument,
    ...options,
  });
}
export const RefreshTokenDocument = gql`
  mutation RefreshToken($currentRefreshToken: String!) {
    refreshToken(currentRefreshToken: $currentRefreshToken) {
      newAccessToken
      newRefreshToken
    }
  }
`;

export function useRefreshTokenMutation() {
  return Urql.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(
    RefreshTokenDocument
  );
}
