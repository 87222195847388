'use client';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetClose,
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
  Alert,
} from '@ui/components';
import { Icon } from '@icons/icons';
import Button from '@ui/components/ui/button/Button';
import { useState } from 'react';
import {
  ParkingItem,
  NavigationItem,
  HelpItem,
} from './navigation-header/navigation-header';
import {
  DropdownItem,
  ModalDropdownCountry,
} from '../../components/modal-country/modal-dropdown-country';
import { AirportLocationsFieldsFragment } from '../../graphql/generated/graphql';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { SiteSelector } from '../../hooks/site-selector/site-selector';
import { GroupAirportsByCountry } from '../../helpers/group-airport-by-country/group-airport-by-country';
import Image from 'next/image';

interface HeaderMobileProps {
  navigationLinks: Array<NavigationItem>;
  sites: Array<DropdownItem>;
  host: string;
  error?: Error | null;
}

export function HeaderMobile({
  navigationLinks,
  sites,
  host,
  error,
}: HeaderMobileProps) {
  const t = useTranslations('header');
  // State to manage views
  const [currentView, setCurrentView] = useState<
    'main' | 'airport' | 'parking' | 'country'
  >('main');
  const {
    selectedSite,
    setSelectedSite,
    selectedCurrency,
    setSelectedCurrency,
    selectedLanguage,
    setSelectedLanguage,
    filteredCurrencies,
    filteredLanguages,
    isLanguageCurrencyAllowed,
    handleContinue,
  } = SiteSelector({ sites, host, isOpen: currentView === 'country' });
  const [openDropdown, setOpenDropdown] = useState<string>('');

  const airports = navigationLinks
    .filter(
      (
        nav
      ): nav is {
        label: 'Airports';
        items: Array<AirportLocationsFieldsFragment>;
      } => nav.label === t('airports') && 'items' in nav
    )
    .flatMap(nav => nav.items);

  const groupedAirports = GroupAirportsByCountry(airports);

  return (
    <Sheet>
      <SheetTrigger asChild>
        <div className="flex items-center gap-6 lg:hidden cursor-pointer">
          <Icon name="MenuOutlinedIcon" iconColor="light" />
        </div>
      </SheetTrigger>

      <SheetContent>
        <SheetHeader>
          <div className="flex items-center justify-between py-4">
            <SheetTitle>
              <Link href="/" passHref>
                <div className="cursor-pointer">
                  <Icon name="VnpLogoBlack" size={14} />
                </div>
              </Link>
            </SheetTitle>
            <SheetClose>
              <Icon name="CloseIcon" size={8} iconColor="blueGray" />
            </SheetClose>
          </div>
        </SheetHeader>

        {/* Render content based on the current view */}
        {currentView === 'main' && (
          <div>
            {/* Main View */}
            <div className="mt-6 space-y-3 text-primaryText">
              {navigationLinks.map(nav => (
                <div
                  key={nav.label}
                  className={`flex flex-col py-2 ${
                    nav.label === t('help') ? 'space-y-3' : 'cursor-pointer'
                  }`}
                  onClick={() => {
                    if ('items' in nav) {
                      if (nav.label === t('airports')) setCurrentView('airport');
                      else if (nav.label === t('type_of_parking'))
                        setCurrentView('parking');
                    }
                  }}
                >
                  {/* Check if it's the help section */}
                  {nav.label === t('help') && 'items' in nav ? (
                    <div className="space-y-8">
                      {(nav.items as Array<HelpItem>).map(helpItem => (
                        <a
                          key={helpItem.label}
                          href={helpItem.link}
                          className="font-normal text-lg text-primaryText block"
                        >
                          {helpItem.label}
                        </a>
                      ))}
                    </div>
                  ) : (
                    <div className="flex justify-between items-center">
                      <span className="font-normal text-lg">{nav.label}</span>
                      {'items' in nav && (
                        <Icon
                          name="KeyboardArrowRightRoundedIcon"
                          iconColor="default"
                          size={8}
                        />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="mt-6 border-t py-4">
              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={() => setCurrentView('country')}
              >
                <div className="flex items-center gap-2">
                  <Icon name="LanguageOutlinedIcon" size={8} iconColor="default" />
                  <span className="font-normal text-lg">
                    {t('choose_your_preferences')}
                  </span>
                </div>
                <Icon
                  name="KeyboardArrowRightRoundedIcon"
                  iconColor="default"
                  size={8}
                />
              </div>
            </div>

            <div className="mt-6">
              <Button
                leftIcon="AccountIcon"
                iconSize={6}
                fullWidth
                isCenter
                variant="primary"
                iconColor="inherit"
                size="input"
              >
                {t('my_booking')}
              </Button>
            </div>
          </div>
        )}

        {currentView === 'airport' && (
          <>
            <div
              className="flex rounded-xl my-5 items-center gap-2 p-3 cursor-pointer text-primaryText bg-gray-100"
              onClick={() => setCurrentView('main')}
            >
              <Icon
                name="KeyboardArrowLeftRoundedIcon"
                size={7}
                iconColor="default"
              />
              <span className="text-lg font-medium">Return</span>
            </div>
            <Accordion type="single" collapsible>
              {Object.entries(groupedAirports).map(
                ([countryName, airports], index) => (
                  <AccordionItem key={countryName} value={countryName}>
                    <AccordionTrigger>
                      <div className="flex items-center gap-2 text-lg">
                        {/* Add the flag image */}
                        {groupedAirports[countryName]?.countryFlag && (
                          <Image
                            src={groupedAirports[countryName]?.countryFlag}
                            alt={countryName}
                            width={24}
                            height={16}
                          />
                        )}
                        {/* Country name */}
                        {countryName}
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      {airports.airports.map(airport => (
                        <a
                          key={airport.id}
                          href={`/airport/${airport.airport?.name.replaceAll(/\s+/g, '-').toLowerCase()}`}
                          className="text-base py-2 block hover:underline text-primaryText"
                          rel="noopener noreferrer"
                        >
                          {airport.airport?.name}
                        </a>
                      ))}
                    </AccordionContent>
                  </AccordionItem>
                )
              )}
            </Accordion>
          </>
        )}

        {/* Parking Types View */}
        {currentView === 'parking' && (
          <>
            <div
              className="flex rounded-xl my-5 items-center gap-2 p-3 cursor-pointer text-primaryText bg-gray-100"
              onClick={() => setCurrentView('main')}
            >
              <Icon
                name="KeyboardArrowLeftRoundedIcon"
                size={7}
                iconColor="default"
              />
              <span className="text-lg font-medium">Return</span>
            </div>
            <div className="mt-4">
              {navigationLinks
                .filter(
                  (
                    nav
                  ): nav is {
                    label: 'Type of Parking';
                    items: Array<ParkingItem>;
                  } => nav.label === t('type_of_parking') && 'items' in nav
                )
                .flatMap(nav => nav.items)
                .map((type, index) => (
                  <a
                    key={index}
                    href={type.link} // Use the link from the ParkingItem
                    className="flex items-center gap-3 py-3 text-primaryText hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name={type.icon} size={8} iconColor="default" />
                    <span className="text-lg">{type.label}</span>
                  </a>
                ))}
            </div>
          </>
        )}

        {/* Parking Types View */}
        {currentView === 'country' && (
          <>
            <div
              className="flex rounded-xl my-5 items-center gap-2 p-3 cursor-pointer text-primaryText bg-gray-100"
              onClick={() => setCurrentView('main')}
            >
              <Icon
                name="KeyboardArrowLeftRoundedIcon"
                size={7}
                iconColor="default"
              />
              <span className="text-lg font-medium">
                {t('modal_dropdown.return')}
              </span>
            </div>

            <div className="mt-4 space-y-6">
              <div className="grid flex-1 gap-2">
                <div className="bg-subtleWarning text-primaryWarning text-sm p-5 rounded-lg flex items-center">
                  <span className="mr-2">
                    <Icon name="WarningIcon" />
                  </span>
                  <p className="text-sm font-regular">
                    {t('modal_dropdown.warning')}
                  </p>
                </div>
              </div>
              {error && (
                <Alert variant="error" title="Error">
                  <p>{error.message}</p>
                </Alert>
              )}
              <div className="space-y-4">
                <ModalDropdownCountry
                  id="site"
                  title="Sites"
                  selectedItem={selectedSite}
                  items={sites}
                  onItemSelect={setSelectedSite}
                  openDropdown={openDropdown}
                  setOpenDropdown={setOpenDropdown}
                />

                <ModalDropdownCountry
                  id="currency"
                  title={t('modal_dropdown.currency')}
                  selectedItem={selectedCurrency}
                  items={filteredCurrencies}
                  onItemSelect={setSelectedCurrency}
                  isDisabled={
                    !isLanguageCurrencyAllowed || filteredCurrencies.length === 0
                  }
                  openDropdown={openDropdown}
                  setOpenDropdown={setOpenDropdown}
                />

                <ModalDropdownCountry
                  id="language"
                  title={t('modal_dropdown.language')}
                  selectedItem={selectedLanguage}
                  items={filteredLanguages}
                  onItemSelect={setSelectedLanguage}
                  isDisabled={
                    !isLanguageCurrencyAllowed || filteredLanguages.length === 0
                  }
                  openDropdown={openDropdown}
                  setOpenDropdown={setOpenDropdown}
                />
              </div>
            </div>

            <div className="mt-6 flex justify-center">
              <Button
                size="input"
                fullWidth
                radius="fullRounded"
                variant="primary"
                onClick={handleContinue}
              >
                Save Preferences
              </Button>
            </div>
          </>
        )}
      </SheetContent>
    </Sheet>
  );
}
