'use client';
import React, { useState, useMemo, Suspense, useEffect } from 'react';
import { Icon } from '@icons/icons';
import Button from '@ui/components/ui/button/Button';
import { useRouter } from 'next/navigation';
import {
  NavigationHeader,
  NavigationItem,
} from './navigation-header/navigation-header';
import { Container } from '@ui/components';
import { ModalCountry } from '../modal-country/modal-country';
import {
  useGetAllSitesQuery,
  useHeaderAirportsQuery,
} from '../../graphql/generated/graphql';
import { HeaderMobile } from './header-mobile';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useAuth } from '../../urql-client/auth-provider';

interface SiteHeaderProps {
  variant?: 'overlay' | 'default';
  host: string;
}

export const SiteHeader: React.FC<SiteHeaderProps> = ({
  variant = 'default',
  host,
}) => {
  const t = useTranslations('header');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const router = useRouter();
  const [scrollProgress, setScrollProgress] = useState(0);
  const { user, accessToken } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const docHeight = document.documentElement.scrollHeight - window.innerHeight;
      const progress = docHeight > 0 ? (scrollTop / docHeight) * 100 : 0;
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  //variant header for aiport page
  const headerBg =
    variant === 'overlay' && scrollProgress < 8
      ? 'bg-transparent' // Less than 10% it hhas a transparent bg
      : 'bg-primaryColour transition-all duration-900';
  const handleGlobeClick = () => {
    setIsModalOpen(!isModalOpen);
  };
  // Fetch data for Header
  const whereFilter = { domain: host };
  const [{ data: navigationData }] = useHeaderAirportsQuery({
    variables: { where: whereFilter },
  });

  const [{ data: allSitesData, error: allSitesError }] = useGetAllSitesQuery({
    variables: { skip: 0, take: 15 },
  });

  // console.log('Data:', allSitesData);

  // Transform data to fit the navigationLinks structure
  const navigationLinks: Array<NavigationItem> = useMemo(() => {
    // Updated to work with siteAirports
    if (!navigationData?.getSiteBy?.siteAirports) return [];

    const countryLinks = navigationData.getSiteBy.siteAirports;

    return [
      {
        label: t('airports'),
        items: countryLinks,
      },
      {
        label: t('type_of_parking'),
        items: [
          {
            label: 'Shuttle',
            icon: 'AirportShuttleOutlinedIcon',
            link: '/parking-types/shuttle',
          },
          { label: 'Valet', icon: 'ValetIcon', link: '/parking-types/valet' },
          { label: 'Within walking distance', icon: 'WalkingIcon', link: '#' },
          { label: 'Park, sleep & fly', icon: 'HotelOutlinedIcon', link: '#' },
        ],
      },
      { label: t('blog'), link: '#' },
      {
        label: t('help'),
        items: [
          { label: t('frequently_asked_questions'), link: '/faq' },
          { label: t('contact'), link: '#' },
        ],
      },
    ];
  }, [navigationData, t]);

  const sites = useMemo(() => {
    if (!allSitesData?.getAllSites?.items) return [];

    return allSitesData.getAllSites.items.map(site => ({
      label: site.name,
      img: site.siteCountries?.find(c => c.isDefault)?.country?.flagIconUrl || '',
      tag: site.domain,
      isDefault: site.domain === 'vliegenenparkeren.nl',
    }));
  }, [allSitesData]);

  return (
    <header
      className={`sticky top-0 z-50 transition-colors duration-300 ${headerBg}`}
    >
      <div className="sticky top-0 w-full flex flex-col">
        <Container marginY={5}>
          <div className="flex justify-between items-center">
            <Link href="/">
              <div className="cursor-pointer">
                <Icon name="VnpLogo" size={14} />
              </div>
            </Link>
            <div className="hidden lg:flex space-x-1">
              <Suspense>
                <NavigationHeader navigationLinks={navigationLinks} />
              </Suspense>
              <div className="pl-12 flex items-center gap-4 text-base">
                <Button
                  leftIcon="AccountIcon"
                  iconSize={6}
                  variant="outline"
                  iconColor="inherit"
                  onClick={() => {
                    if (user && accessToken) {
                      router.push('/customer-dashboard'); // Navigate only if authenticated
                    } else {
                      router.push('/login?redirect=/customer-dashboard');
                    }
                  }}
                >
                  {t('my_booking')}
                </Button>
                <div onClick={handleGlobeClick} className="cursor-pointer">
                  <Icon name="LanguageOutlinedIcon" size={8} iconColor="light" />
                </div>
              </div>
            </div>
            <div className="flex items-center gap-6 lg:hidden">
              <HeaderMobile
                navigationLinks={navigationLinks}
                sites={sites}
                host={host}
                error={allSitesError}
              />
            </div>
          </div>
        </Container>
      </div>
      <div className="relative z-50">
        {isModalOpen && (
          <ModalCountry
            sites={sites}
            isOpen={isModalOpen}
            onClose={handleGlobeClick}
            host={host}
            error={allSitesError}
          />
        )}
      </div>
    </header>
  );
};
