'use client';

import React, { useState } from 'react';
import { Alert, Modal } from '@ui/components';
import Button from '@ui/components/ui/button/Button';
import { ModalDropdownCountry, type DropdownItem } from './modal-dropdown-country';
import { Icon } from '@icons/icons';
import { useTranslations } from 'next-intl';
import { SiteSelector } from '../../hooks/site-selector/site-selector';

type ModalCountryProps = {
  isOpen: boolean;
  onClose: () => void;
  sites: Array<DropdownItem>;
  error?: Error | null;
  host: string;
};

export const ModalCountry = ({
  isOpen,
  onClose,
  sites,
  error,
  host,
}: ModalCountryProps) => {
  const t = useTranslations('header');

  const {
    selectedSite,
    setSelectedSite,
    selectedCurrency,
    setSelectedCurrency,
    selectedLanguage,
    setSelectedLanguage,
    filteredCurrencies,
    filteredLanguages,
    isLanguageCurrencyAllowed,
    handleContinue,
  } = SiteSelector({ sites, host, isOpen });
  const [openDropdown, setOpenDropdown] = useState<string>('');

  return (
    <Modal
      title={t('modal_dropdown.title')}
      isOpen={isOpen}
      onClose={onClose}
      rounded="xl3"
      zIndexVariant="high"
    >
      <div className="grid gap-2">
        <div className="bg-subtleWarning text-primaryWarning text-sm p-5 rounded-lg flex items-center">
          <span className="mr-2">
            <Icon name="WarningIcon" />
          </span>
          <p className="text-sm font-regular">{t('modal_dropdown.warning')}</p>
        </div>

        {error && (
          <Alert variant="error" title="Error">
            <p>{error.message}</p>
          </Alert>
        )}

        <div className="space-y-4">
          <ModalDropdownCountry
            id="site"
            title="Sites"
            selectedItem={selectedSite}
            items={sites}
            onItemSelect={setSelectedSite}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />

          <ModalDropdownCountry
            id="currency"
            title={t('modal_dropdown.currency')}
            selectedItem={selectedCurrency}
            items={filteredCurrencies}
            onItemSelect={setSelectedCurrency}
            isDisabled={
              !isLanguageCurrencyAllowed || filteredCurrencies.length === 0
            }
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />

          <ModalDropdownCountry
            id="language"
            title={t('modal_dropdown.language')}
            selectedItem={selectedLanguage}
            items={filteredLanguages}
            onItemSelect={setSelectedLanguage}
            isDisabled={!isLanguageCurrencyAllowed || filteredLanguages.length === 0}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />

          <div className="flex justify-center">
            <Button
              size="large"
              radius="fullRounded"
              type="submit"
              onClick={handleContinue}
            >
              {t('modal_dropdown.continue_button')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
